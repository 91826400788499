import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { SessionProperties } from '../session/session.properties';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class OrderBacklogDashboardService {
    public dashboardChartData;
    public dashboardChartData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public backlogDashboardData;
    public backlogDashboardData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public systemBacklogStatusData;
    public systemBacklogStatusData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

    constructor(public appService: AppService, public http: HttpClient) {
    }

    getFilter (view: string) {
        let filter = '';
        if (view === 'Qtr To Date' || view == null) {
            filter = '(ORDER_DATE>='
                    + SessionProperties.quaterStartDate
                    + ')AND(ORDER_DATE<='
                    + SessionProperties.quaterEndDate
                    + ')AND(ORDER_CATEGORY=\'DOF\')';
        } else if (view === 'Month To Date') {
            filter =  '(ORDER_DATE>='
                    + SessionProperties.monthStartDate
                    + ')AND(ORDER_DATE<='
                    + SessionProperties.monthEndDate
                    + ')AND(ORDER_CATEGORY=\'DOF\')';
        } else if (view === 'Previous Qtr') {
            filter = '(ORDER_DATE>='
                    + SessionProperties.prvQtrStartDate
                    + ')AND(ORDER_DATE<='
                    + SessionProperties.prvQtrEndDate
                    + ')AND(ORDER_CATEGORY=\'DOF\')';
        } else if (view === 'Complete View') {
            filter = '(ORDER_CATEGORY=\'DOF\')';
        }
        return filter;
    }

    async getBacklogChartDashboardData() {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('filter', 'FIN_ORDER_NO=\'BACKLOG_STATUS\'');
        const data: any = await this.appService.checkGetMethodWithParams
        (AppUtil.getAppUrl(AppConstant.NEW_BACKLOGSUMMARY_VIEWNAME), params);
        this.setBacklogChartDashboardData(data.body.resource);
        this.setDataLoader(false);
    }
    setBacklogChartDashboardData(data: any) {
        this.dashboardChartData = data;
        this.dashboardChartData$.next(this.dashboardChartData);
    }
    onBacklogChartDashboardData() {
        return this.dashboardChartData$.asObservable();
    }
    async getsystemBacklogStatusData() {
        if (AppUtil.isNullOrUndefined(this.systemBacklogStatusData)) {
          this.setDataLoader(true);
          const data = await this.appService.checkGetMethod(AppUtil.getUrl(AppConstant.DASHBOARDSALES_VIEWNAME));
          this.setsystemBacklogStatusData(data.body.resource);
          this.setDataLoader(false);
        }
      }
      setsystemBacklogStatusData(data: any) {
        this.systemBacklogStatusData = data;
        this.systemBacklogStatusData$.next(this.systemBacklogStatusData);
      }
      onsystemBacklogStatusData() {
        return this.systemBacklogStatusData$.asObservable();
      }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
