import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { IbRegionsReportModal } from '../modal/ibRegionsReport.modal';
import { AppUtil } from '../util/app.util';
import { AudiReportModal } from './../modal/audiReport.modal';
import { AppService } from './app.service';

@Injectable()
export class IbRegionsReportService {
    public ibRegionsData;
    public ibRegionsData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public excelAudiData;
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

    currentDate;
    constructor(public appService: AppService, public http: HttpClient,
        public ngxXml2jsonService: NgxXml2jsonService) {
    }
    async getIbRegionsData() {
        this.setDataLoader(true);
        const param: HttpParams = AppUtil.getHttpParams();
        const data = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.IB_REGIONS_TABLE_NAME), param);
        this.setIbRegionsData(data.body.resource);
        this.setDataLoader(false);
    }
    setIbRegionsData(data: any) {
        this.ibRegionsData = data;
        this.ibRegionsData$.next(this.ibRegionsData);
    }
    onIbRegionsData() {
        return this.ibRegionsData$.asObservable();
    }
    async updateIbregionsTable(ibRegionsReportModal: IbRegionsReportModal) {
        const updateColumns = {
            resource: [
                ibRegionsReportModal.dynamicColumnValues
            ]
        };
        let param: HttpParams = AppUtil.getHttpParams();

        console.log('yes', updateColumns)
        param = param.set('filter', '(CO_2=' + ibRegionsReportModal.countryCode + ')');
        const data = await this.appService.checkPatchMethodWithParams(AppUtil
            .getAppUrl(AppConstant.IB_REGIONS_TABLE_NAME), param, updateColumns);
        // ibRegionsReportModal.codeStatus = data.status;
        return data;
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
