import { HttpParams } from '@angular/common/http';
import { AppConstant } from '../constants/app.constants';
import { AppUtil } from './app.util';

export class OktaUtils {
    public static redirectForAuthorization() {
        window.location.href = AppConstant.ENVIROMENT === 'PROD' ? AppConstant.PROD_OKTA_URL : AppConstant.UAT_OKTA_URL;
        // if (AppConstant.ENVIROMENT === 'PROD') {
        //     window.location.href = AppConstant.PROD_OKTA_URL;
        // } else if (AppConstant.ENVIROMENT === 'UAT') {
        //     window.location.href = AppConstant.UAT_OKTA_URL;
        // } else {
        //     let params = new HttpParams();
        //     params = params.set('api_key', AppUtil.getAPIKey());
        //     window.location.href = AppConstant.OKTA_AUTHORIZE_DEV_URL + '?' + params.toString();
        // }
    }
}
