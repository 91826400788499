import { Comment } from './comment.modal';
import { CompleteReportFilter } from './completeShipReport.modal';
import { Header } from './header.modal';

export class ShipReportModal {
    readonly shipReportHeader: Header[] = [
        {
            columnName: 'SFDC_ORDER_NO',
            displayName: 'SFDC ORDER NO',
            columnType: 'number'
        },
        {
            columnName: 'FIN_ORDER_NO',
            displayName: 'FIN ORDER NO',
            columnType: 'string'
        },
        {
            columnName: 'QUOTE_NUMBER',
            displayName: 'QUOTE NUMBER',
            columnType: 'string'
        },
        {
            columnName: 'PO_NUMBER',
            displayName: 'PO NUMBER',
            columnType: 'string'
        },
        {
            columnName: 'REGION',
            displayName: 'REGION',
            columnType: 'string'
        },
        {
            columnName: 'SALES_REP',
            displayName: 'SALES REP',
            columnType: 'number'
        },
        {
            columnName: 'SHIP_TO_CUSTOMER',
            displayName: 'SHIP TO CUSTOMER',
            columnType: 'string'
        },
        {
            columnName: 'OD',
            displayName: 'ORDER DATE',
            columnType: 'number'
        },
        {
            columnName: 'CD',
            displayName: 'CONFIRM DATE',
            columnType: 'number'
        },
        {
            columnName: 'ORDER_STATUS',
            displayName: 'ORDER STATUS',
            columnType: 'string'
        }
    ];
    public shipData: any;
    public totalRecords: number;
    public currentUser: any;
    public shipDashboardBarData: any;
    public remainShipData: any;
    public order;
    public line;
    public reverse: boolean;
    public collapsed: boolean;
    public collapsedRowData: any;
    public selectedName: any;
    public show: boolean;
    public dataLoader: boolean;
    public OL_EMAIL: string;
    public OL_ID: number;
    public OL_GROUPNAME: string;
    public SALES_REP: string;
    public userRoleList: any;
    public userAdminValue: any;
    public userRoleArray: any;
    public firstName: any;
    public orderType: any;
    public completeReportFilter: CompleteReportFilter;
    public cmStatus;
    public shipToCustomer;
    public add;
    public trackingNo;
    public instn;
    public notesData = {
        resource: [
            {
                USR_NOTES: ''
            }
        ]
    };
    public boxData: any = [
        {
            data: []
        }
    ];
    public widthoftr: boolean;
    public sum = 0;
    public createdOnDisplay;
    public commentData: Comment[];
    public OL_FIRSTNAME: string;
    public view: any;
    public filter: any;
    public offset: any;
    public shipmentBoxData: any[];
    public TOTAL_SALESORDERS: any;
    public LIC_REQUIRED: any;
    public TOTAL_SYSTEMS: any;
    public OVER_SLA: any;
    public currQtr: any;
    public onTimePer: any;
    public licReqPer: any;
    public totalSystemsPer: any;
    public overSlaPer: any;
    public sysCountPer: any;
    public year: any;
    public serialNumber: string;
    public monthArray: any;
    public monthName: any;
    public shipDataCommentRow: any;
    public orderStartDate: any;
    public orderEndDate: any;
    public confirmStartDate: any;
    public confirmEndDate: any;
    public orderDateRangeFilter: any;
    public confirmDateRangeFilter: any;
    public displayStartDate: any;
    public displayEndDate: any;
    public orderDateRangeBool: boolean;
    public confirmDateRangeBool: boolean;
    public minDate: any;
    public maxDate: any;
    public dateType: string;
    constructor() {
        this.order = 'CONFIRM_DATE DESC';
        this.line = 'LINE_NO';
        this.reverse = true;
        this.shipData = [];
        this.userRoleArray = [];
        this.shipDashboardBarData = [];
        this.shipmentBoxData = [];
        this.totalRecords = 0;
        this.view = 'Qtr To Date';
        this.filter = '';
        this.offset = 0;
        this.TOTAL_SALESORDERS = 0;
        this.LIC_REQUIRED = 0;
        this.TOTAL_SYSTEMS = 0;
        this.OVER_SLA = 0;
        this.currQtr = '';
        this.year = '';
        this.completeReportFilter = {
            partNumber: null,
            orderStartDate: null,
            orderEndDate: null
        };
        this.commentData = [{
            userName: '',
            message: '',
            date: '',
            orderNo: ''
        }];
        this.serialNumber = '';
        this.monthArray = [];
        this.monthName = [];
        this.shipDataCommentRow = {};
        this.orderStartDate = '';
        this.orderEndDate = '';
        this.confirmStartDate = '';
        this.confirmEndDate = '';
        this.orderDateRangeFilter = '';
        this.confirmDateRangeFilter = '';
        this.displayStartDate = '';
        this.displayEndDate = '';
        this.minDate = '';
        this.maxDate = '';
        this.dateType = '';
    }
    public setTotalRecords(totalRecords: number) {
        this.totalRecords = totalRecords;
    }
    public setShipCommentRow(row) {
        this.shipDataCommentRow = row;
    }
}
