import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { AppConstant } from '../constants/app.constants';
import { SessionProperties } from '../session/session.properties';
import { AppUtil } from '../util/app.util';
import { BmwReportModal } from './../modal/bmwReport.modal';
import { AppService } from './app.service';

@Injectable()
export class BmwReportService {
    public bmwData;
    public bmwData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public excelBmwData;
    public excelBmwData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public imdbFilesList;
    public imdbFilesList$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public imdbFilesData;
    public imdbFilesData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: Subject<any> = new Subject<any>();

    currentDate;
    constructor(public appService: AppService, public http: HttpClient) {
    }

    getFilter(view) {
        let filter;
        if (view === 'Qtr To Date' || view == null) {
            filter = '(TEST_DATE>='
                + SessionProperties.quaterStartDate
                + ')AND(TEST_DATE<='
                + SessionProperties.quaterEndDate
                + ')';
        } else if (view === 'Month To Date') {
            filter = '(TEST_DATE>='
                + SessionProperties.monthStartDate
                + ')AND(TEST_DATE<='
                + SessionProperties.monthEndDate
                + ')';
        } else if (view === 'Previous Qtr') {
            filter = '(TEST_DATE>='
                + SessionProperties.prvQtrStartDate
                + ')AND(TEST_DATE<='
                + SessionProperties.prvQtrEndDate
                + ')';
        } else if (view === 'Complete View') {
            filter = '';
        }
        return filter;
    }
    getBmwData(bmwModalData: BmwReportModal): Observable<any> {
        this.setDataLoader(true);
        let filter = bmwModalData.dateRangeBool ? '' : this.getFilter(bmwModalData.view);
        filter += bmwModalData.filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', bmwModalData.order);
        params = params.set('offset', bmwModalData.offset);
        if (bmwModalData.dateRangeBool) {
            params = params.set('filter', bmwModalData.filter);
        } else {
            params = params.set('filter', filter);
        }
        bmwModalData.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.BMW_REPORT_VIEWNAME),
            { params: params, headers: AppUtil.getHttpHeaders() });
    }
    setBmwData(data: any) {
        this.bmwData = data;
        this.bmwData$.next(this.bmwData);
    }
    onBmwData() {
        return this.bmwData$.asObservable();
    }
    async getBmwExcelData(bmwModalData: BmwReportModal) {
        let filter = this.getFilter(bmwModalData.view);
        filter += bmwModalData.filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = bmwModalData.dateRangeBool ? params.set('filter', bmwModalData.filter) :
        params.set('filter', filter);
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.BMW_REPORT_VIEWNAME), params);
        this.setBmwExcelData(data.body.resource);
    }
    setBmwExcelData(data: any) {
        this.excelBmwData = data;
        this.excelBmwData$.next(this.excelBmwData);
    }
    onBmwExcelData() {
        return this.excelBmwData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
