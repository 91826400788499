import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { BaxterModal } from '../modal/baxterReport.modal';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class BaxterService {
    public baxterData;
    public baxterData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(public appService: AppService) { }
    async getBaxterData(baxterModalData: BaxterModal) {
        this.setDataLoader(true);
        const param: HttpParams = AppUtil.getHttpParams();
        const data = await this.appService.checkGetMethodWithParams(AppUtil
            .getBaxterUrl(AppConstant.BAXTERREPORT_PROCEDURENAME), param);
        baxterModalData.createdOnTime = data.body[0].CREATED_ON.split(' ')[1];
        baxterModalData.createdOnDisplay = new DatePipe('en-US').transform(data.body[0].CREATED_ON, 'yyyy/MM/dd');
        baxterModalData.displayDate = baxterModalData.createdOnDisplay.replace(/\//g, '');
        baxterModalData.displayTime = baxterModalData.createdOnTime.replace(/\:/g, '');
        this.setBaxterData(data.body);
        this.setDataLoader(false);
    }
    setBaxterData(data: any) {
        this.baxterData = data;
        this.baxterData$.next(this.baxterData);
    }
    onBaxterData() {
        return this.baxterData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
