import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { ComponentConstants } from '../constants/component.constants';
import { LegacyReportModal } from '../modal/legacyReport.modal';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class LegacyService {
    public legacyData;
    public legacyData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public legacyExcelData;
    public legacyExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public legacyExpandData;
    public legacyExpandData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public legacyDashboardData;
    public legacyDashboardData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public legacyfullDashboardData;
    public legacyfullDashboardData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

    constructor(public appService: AppService,  public http: HttpClient) { }

    getLegacyData(legacyModalData: LegacyReportModal): Observable<any> {
        this.setDataLoader(true);
        let filter  = legacyModalData.filter;
        filter = '(FYQTR LIKE \'%' + legacyModalData.year + '%\')AND(ORDER_CATEGORY=\'DOF\')' + filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', legacyModalData.order);
        params = params.set('offset', legacyModalData.offset);
        params = params.set('filter', filter);
        if (legacyModalData.selectedTabLabel === ComponentConstants.SUMMARY_VIEW
            || legacyModalData.selectedTabLabel === '' ) {
            params = params.set('group', 'ORDER_NO');
        }
        legacyModalData.offset += 300;
        this.setDataLoader(true);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.LEGACY_VIEWNAME), { params : params,
            headers: AppUtil.getHttpHeaders()});
    }
    setLegacyData(data: any) {
        this.legacyData = data;
        this.legacyData$.next(this.legacyData);
    }
    onLegacyData() {
        return this.legacyData$.asObservable();
    }
    async getLegacyExcelData(legacyModalData: LegacyReportModal) {
        let filter  = legacyModalData.filter;
        filter = '(FYQTR LIKE \'%' + legacyModalData.year + '%\')AND(ORDER_CATEGORY=\'DOF\')' + filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('filter', filter);
        if (legacyModalData.selectedTabLabel === ComponentConstants.SUMMARY_VIEW
            || legacyModalData.selectedTabLabel === '' ) {
            params = params.set('group', 'ORDER_NO');
        }
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.LEGACY_VIEWNAME), params);
        this.setLegacyExcelData(data.body.resource);
    }
    setLegacyExcelData(data: any) {
        this.legacyExcelData = data;
        this.legacyExcelData$.next(this.legacyExcelData);
    }
    onLegacyExcelData() {
        return this.legacyExcelData$.asObservable();
    }
    async getRemainLegacyExpandData(legacyModalData: LegacyReportModal) {
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('fields', 'ORDER_NO,PART_NO,CYCLE_COUNT,SERIAL_NOS');
        params = params.set('filter', '(FYQTR LIKE \'%' + legacyModalData.year + '%\')AND(ORDER_CATEGORY=\'DOF\')');
        params = params.set('order', legacyModalData.order);
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.LEGACY_VIEWNAME), params);
        this.setRemainLegacyExpandData(data.body.resource);
    }
    setRemainLegacyExpandData(data: any) {
        this.legacyExpandData = data;
        this.legacyExpandData$.next(this.legacyExpandData);
    }
    onRemainLegacyExpandData() {
        return this.legacyExpandData$.asObservable();
    }
    async getLegacyDashboard(year) {
        this.setDataLoader(true);
        const filter = '(O_YEAR LIKE %' + year + '%)';
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('filter', filter);
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.LEGACYDASHBOARD_VIEWNAME), params);
        this.setLegacyDashboard(data.body.resource);
        this.setDataLoader(false);
    }
    setLegacyDashboard(data: any) {
        this.legacyDashboardData = data;
        this.legacyDashboardData$.next(this.legacyDashboardData);
    }
    onLegacyDashboard() {
        return this.legacyDashboardData$.asObservable();
    }
    async getfullLegacyDashboard() {
        this.setDataLoader(true);
        const params: HttpParams = AppUtil.getHttpParams();
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.LEGACYDASHBOARD_VIEWNAME), params);
        this.setfullLegacyDashboard(data.body.resource);
        this.setDataLoader(false);
    }
    setfullLegacyDashboard(data: any) {
        this.legacyfullDashboardData = data;
        this.legacyfullDashboardData$.next(this.legacyfullDashboardData);
    }
    onfullLegacyDashboard() {
        return this.legacyfullDashboardData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
