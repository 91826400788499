import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { FlashANErrorReportModal } from '../modal/flashASNErrorReport.modal';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class FlashANErrorReportService {
    public flashASNErrorData;
    public flashASNErrorData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public flashASNErrorExcelData;
    public flashASNErrorExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

    constructor(public appService: AppService, public http: HttpClient) { }

    getFlashASNErrorData(flashASNErrorReportModal: FlashANErrorReportModal): Observable<any> {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        let filter = '(PROCESS_FLAG != \'I\')';
        filter += flashASNErrorReportModal.filter;
        params = params.set('limit', '300');
        params = params.set('order', flashASNErrorReportModal.order);
        params = params.set('offset', flashASNErrorReportModal.offset);
        params = params.set('filter', filter);
        flashASNErrorReportModal.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.FLASH_ASN_ERROR_REPORT_TABLENAME),
            { params: params, headers: AppUtil.getHttpHeaders() });
    }
    setFlashASNErrorData(data: any) {
        this.flashASNErrorData = data;
        this.flashASNErrorData$.next(this.flashASNErrorData);
    }
    onFlashASNErrorData() {
        return this.flashASNErrorData$.asObservable();
    }
    async getFlashASNErrorDataExcel(filter) {
        let params: HttpParams = AppUtil.getHttpParams();
        if (AppUtil.isNullOrUndefined(this.flashASNErrorExcelData)) {
            params = params.set('filter', filter);
            const data = await this.appService
                .checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.FLASH_ASN_ERROR_REPORT_TABLENAME)
                    , params);
            this.setFlashASNErrorDataExcel(data.body.resource);
        }
    }
    setFlashASNErrorDataExcel(data: any) {
        this.flashASNErrorExcelData = data;
        this.flashASNErrorExcelData$.next(this.flashASNErrorExcelData);
    }
    onFlashASNErrorDataExcel() {
        return this.flashASNErrorExcelData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
