import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { Subscription } from 'rxjs/Subscription';
import { AppConstant } from 'src/app/@core/constants/app.constants';
import { AppUtil } from 'src/app/@core/util/app.util';
import { AppService } from './@core/data/app.service';
import { LoginService } from './@core/data/login.service';
import { SessionProperties } from './@core/session/session.properties';
import { OktaUtils } from './@core/util/okta.util';
@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    public _router: Subscription;
    isAuthenticated = false;
    public userData: any;
    public dateData = false;
    public currentURL = 'testblox/audiReport';
    public userDataJson: any = {};
    constructor(public router: Router, public loginService: LoginService, public appService: AppService) {
        if (this.isLogFileWindow()) {
            const logFileName = unescape(window.location.href.split('fileName=')[1]);
            this.userDataJson.Email = localStorage.getItem('Email');
            this.isAuthenticated = true;
            this.getUserDetails();
            this.getUSCurrentDate();
            localStorage.setItem('fileName', logFileName);
            this.router.navigateByUrl('testblox/showLogFileData?fileName=' + logFileName);
            // this.router.navigate(['testblox/showLogFileData']);
        } else {
            switch (AppConstant.ENVIROMENT) {
                case 'PROD' : this.validateLogin(); break;
                case 'UAT' : this.validateLogin(); break;
                case 'DEV': this.validateLogin(); break;
                // this.isAuthenticated = true;
                // localStorage.setItem('Lastname', 'Vahinipati');
                // localStorage.setItem('Firstname', 'Shilpa');
                // localStorage.setItem('Email', 'svahinipati@infoblox.com');
                // this.userDataJson.Email = localStorage.getItem('Email');
                // this.getUserDetails();
                // this.getUSCurrentDate(); break;
                case 'LOCAL':
                this.isAuthenticated = true;
                this.userDataJson.Email = localStorage.getItem('Email');
                this.getUserDetails();
                this.getUSCurrentDate();
            }
        }
    }
    getDecodedJWTtoken(token) {
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }
    isLogFileWindow() {
        return window.location.href.includes('testblox/showLogFileData');
    }
    isLocalStorageVarriablesSet() {
        return (!AppUtil.isNullOrUndefined(localStorage.getItem('Lastname'))
            && !AppUtil.isNullOrUndefined(localStorage.getItem('Firstname'))
            && !AppUtil.isNullOrUndefined(localStorage.getItem('Email')));
    }
    async validateLogin() {
        const windowLocation = window.location.href;
        if (!windowLocation.includes('jwt=')) {
            OktaUtils.redirectForAuthorization();
        } else {
            SessionProperties.JWT = window.location.href.split('jwt=')[1];
            let userEmailIDData = null;
            try {
                const jwtUserID = this.getDecodedJWTtoken(SessionProperties.JWT).sub;
                userEmailIDData = jwtUserID;
                SessionProperties.USER_EMAIL_ID = jwtUserID;
                this.userDataJson.Email = jwtUserID;
                AppConstant.EMAIL_ID = userEmailIDData;
            } catch (e) {
                OktaUtils.redirectForAuthorization();
            }
            this.getUSCurrentDate();
            this.getUserDetails();
            this.isAuthenticated = true;
        }
    }

    // async validateLogin() {
    //     const windowLocation = window.location.href;
    //     if (!windowLocation.includes('jwt=')) {
    //         OktaUtils.redirectForAuthorization();
    //     } else {
    //         AppConstant.JWT = window.location.href.split('jwt=')[1];
    //         let userEmailIDData = null;
    //         if (AppConstant.ENVIROMENT === 'UAT') {
    //             try {
    //                 const jwtUserID = this.getDecodedJWTtoken(AppConstant.JWT).sub;
    //                 userEmailIDData = jwtUserID;
    //                 this.userDataJson.Email = jwtUserID;
    //                 AppConstant.EMAIL_ID = userEmailIDData;
    //             } catch (e) {
    //                 OktaUtils.redirectForAuthorization();
    //             }
    //         } else {
    //             const jwtUserID = this.getDecodedJWTtoken(AppConstant.JWT).user_id;
    //             try {
    //                 userEmailIDData = await this.appService.getOktaUserEmail(jwtUserID).toPromise() as any;
    //             } catch (e) {
    //                 OktaUtils.redirectForAuthorization();
    //             }
    //             const emailSplit = userEmailIDData.resource[0].email.split('+okta_sso');
    //             this.userDataJson.Email = emailSplit[0] + emailSplit[1];
    //         }
    //         this.getUSCurrentDate();
    //         this.getUserDetails();
    //         this.isAuthenticated = true;
    //     }
    // }

    logout() {
        localStorage.clear();
    }
    getUserDetails() {
        if (!AppUtil.isNullOrUndefined(this.userDataJson.Email)) {
            this.appService.onUserData().subscribe(userInfo => {
                console.log(userInfo)
                if (AppUtil.isNullOrUndefined(userInfo)) {
                    this.appService.getUserData(this.userDataJson.Email).subscribe(userData => {
                        this.appService.setUserData(userData.resource);
                    });
                } else {
                    this.userData = userInfo[0];
                    localStorage.setItem('firstName', this.userData.OL_FIRSTNAME);
                    localStorage.setItem('lastName', this.userData.OL_LASTNAME);
                    if (this.userData.OL_LOCK === 1) {
                        this.router.navigate(['404notFound']);
                    } else if (!this.isLogFileWindow()) {
                        switch (this.userData.OL_GROUPNAME) {
                            case 'Executive': this.router.navigate(['mainDashboard']); break;
                            case 'IMDB': this.router.navigate(['testblox']); break;
                            default: this.router.navigate(['dashboard']);
                        }
                    }
                }
            });
        }
    }
    getUSCurrentDate() {
        this.appService.onUSCurrentDate().subscribe(data => {
            if (!AppUtil.isNullOrUndefined(data)) {
                SessionProperties.currentTimeStamp = data.filter(x => x.TAG === 'CURRENT DATETIME')[0].FY_QTR;
                SessionProperties.currentDate = data.filter(x => x.TAG === 'CURRENT DATETIME')[0].FY_QTR.split(' ')[0];
                SessionProperties.currentTime = data.filter(x => x.TAG === 'CURRENT DATETIME')[0].FY_QTR.split(' ')[1];
                SessionProperties.CURRENT_QTR = data.filter(x => x.TAG === 'CURRENT QTR')[0].FY_QTR;
                const stackView = AppUtil.getStackBarQuarterView(SessionProperties.currentDate);
                const quaterDate = AppUtil.getQuarterDate(SessionProperties.currentDate);
                const nextQuaterDate = AppUtil.getNextQuarterDate(SessionProperties.currentDate);
                const prvQuaterDate = AppUtil.getPrvQuarterDate(SessionProperties.currentDate);
                const monthDate = AppUtil.getMonthlyDate(SessionProperties.currentDate);
                SessionProperties.currentQuarterStart = stackView.split(',')[0];
                SessionProperties.currentQuarterEnd = stackView.split(',')[1];
                SessionProperties.currentYear = SessionProperties.currentDate.split('-')[0];
                SessionProperties.currentMonth = SessionProperties.currentDate.split('-')[1];
                SessionProperties.quaterStartDate = quaterDate.split('|')[0];
                SessionProperties.quaterEndDate = quaterDate.split('|')[1];
                SessionProperties.nextQuaterStartDate = nextQuaterDate.split('|')[0];
                SessionProperties.nextQuaterEndDate = nextQuaterDate.split('|')[1];
                SessionProperties.prvQtrStartDate = prvQuaterDate.split('|')[0];
                SessionProperties.prvQtrEndDate = prvQuaterDate.split('|')[1];
                SessionProperties.monthStartDate = monthDate.split('|')[0];
                SessionProperties.monthEndDate = monthDate.split('|')[1];
                SessionProperties.PrevStartMonth = SessionProperties.prvQtrStartDate.split('-')[1];
                SessionProperties.PrevEndMonth = SessionProperties.prvQtrEndDate.split('-')[1];
                this.dateData = true;
                console.log('quaterDate', SessionProperties.currentDate)
            } else {
                this.appService.getUSCurrentDate();
            }
        });
    }
    ngOnInit() {
        this._router = this.router.events
            .filter(event => event instanceof NavigationEnd)
            .subscribe((event: NavigationEnd) => {
                const body = document.getElementsByTagName('body')[0];
                const modalBackdrop = document.getElementsByClassName(
                    'modal-backdrop'
                )[0];
                if (body.classList.contains('modal-open')) {
                    body.classList.remove('modal-open');
                    modalBackdrop.remove();
                }
            });
    }
}
