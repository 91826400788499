import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { AppConstant } from '../constants/app.constants';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class ExceptionService {
    public exceptionDetails;
    public exceptionDetails$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public duplicateASNData;
    public duplicateASNData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public orderDetails;
    public orderDetails$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    public dataLoader: boolean;
    public dataLoader$: Subject<any> = new Subject<any>();

    constructor(public appService: AppService, public http: HttpClient) { }
    async getExceptionData() {
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('related', 'OL_BACKLOG_REPORT_by_FIN_ORDER_NO');
        params = params.set('OL_BACKLOG_REPORT_by_FIN_ORDER_NO.fields', 'EXCEP_CHECK');
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.EXCEPTION_TABLENAME), params);
        this.setExceptionData(data.body.resource);
    }
    setExceptionData(data: any) {
        this.exceptionDetails = data;
        this.exceptionDetails$.next(this.exceptionDetails);
    }
    onExceptionData() {
        return this.exceptionDetails$.asObservable();
    }
    async getDuplicateASNExceptionData() {
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('group', 'FIN_ORDER_NO');
        params = params.set('filter', 'EXCEPTION_CATEGORY=\'Duplicate ASN\'');
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.EXCEPTION_TABLENAME), params);
        this.setDuplicateASNExceptionData(data.body.resource);
    }
    setDuplicateASNExceptionData(data: any) {
        this.duplicateASNData = data;
        this.duplicateASNData$.next(this.duplicateASNData);
    }
    onDuplicateASNExceptionData() {
        return this.duplicateASNData$.asObservable();
    }
    getOrderDetails(): Observable<any>  {
        const url = AppUtil.getUrl(AppConstant.ORDERMASTER_TABLENAME);
        return this.http.get<{ data: Object }>(url, {headers: AppUtil.getHttpHeaders()});
    }
    setOrderDetails(data: any) {
        this.orderDetails = data;
        this.orderDetails$.next(this.orderDetails);
    }
    onOrderDetails() {
        return this.orderDetails$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
    updateNotes(note, FIN_ORDER_NO, issue, userId): Observable<any> {
        const updateNotes = {
            resource: [
                {
                    USR_NOTES: note,
                    USR_ID: userId
                }
            ]
        };
        const url = AppUtil.getUrlFilter(
            AppConstant.EXCEPTION_TABLENAME,
            '(FIN_ORDER_NO=' +
            FIN_ORDER_NO +
            ')' +
            'AND(EXCEPTION_CATEGORY=' +
            issue +
            ')'
        );

        return this.http.patch(url, updateNotes);
    }
    updateExcepCheckBacklogData(FIN_ORDER_NO): Observable<any>  {
        const updateExcepCheck = {
            resource: [
                {
                    EXCEP_CHECK: 2
                }
            ]
        };
        const url = AppUtil.getUrlFilter(
            AppConstant.BACKLOG_TABLENAME,
            // tslint:disable-next-line:quotemark
            'FIN_ORDER_NO=' + FIN_ORDER_NO
        );

        return this.http.patch(url, updateExcepCheck);
    }
    updateExcepCheckOrderMasterData(FIN_ORDER_NO): Observable<any>  {
        const updateExcepCheck = {
            resource: [
                {
                    EXCEP_CHECK: 2
                }
            ]
        };
        const url = AppUtil.getUrlFilter(
            AppConstant.ORDERMASTER_TABLENAME,
            // tslint:disable-next-line:quotemark
            'FIN_ORDER_NO=' + FIN_ORDER_NO
        );

        return this.http.patch(url, updateExcepCheck);
    }
    updateOrderMasterData(admin_data, FIN_ORDER_NO): Observable<any> {
        const url = AppUtil.getUrlFilter(
            AppConstant.ORDERMASTER_TABLENAME,
            // tslint:disable-next-line:quotemark
            'FIN_ORDER_NO=' + FIN_ORDER_NO
        );

        return this.http.patch(url, admin_data);
    }
    updateOrdersDuplicateASN(FIN_ORDER_NO): Observable<any> {
        const updateExcepCheck = {
            resource: [
                {
                    DUPLICATE_DATA: 'D'
                }
            ]
        };
        let param: HttpParams = AppUtil.getHttpParams();
        param = param.set('filter', '(B2B_ORDER_NO=' + FIN_ORDER_NO + ') AND (PROCESS_TYPE=\'ASN\')');
        return this.http.patch(AppUtil.getAppUrl(AppConstant.DUPLICATED_ASN_TABLENAME), updateExcepCheck, { params: param });
    }
    updateDuplicateASNinException(FIN_ORDER_NO): Observable<any> {
        const updateExcepCheck = {
            resource: [
                {
                    DUPLICATE_FLAG: 'D'
                }
            ]
        };
        let param: HttpParams = AppUtil.getHttpParams();
        param = param.set('filter', '(FIN_ORDER_NO=' + FIN_ORDER_NO + ') AND (EXCEPTION_CATEGORY=\'Duplicate ASN\')');
        return this.http.patch(AppUtil.getAppUrl(AppConstant.EXCEPTION_TABLENAME), updateExcepCheck, { params: param });
    }
}
