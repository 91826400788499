import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class ShippingDashboardService {
    public shppingDashData;
    public shppingDashData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(public appService: AppService) { }

    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }

    async getShppingDashDataData() {
        this.setDataLoader(true);
        const param: HttpParams = AppUtil.getHttpParams();
        const data = await this.appService
            .checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.SHIPPING_DASHBOARD_VIEWNAME), param);
        this.setShppingDashDataData(data.body.resource);
        this.setDataLoader(false);
    }
    setShppingDashDataData(data: any) {
        this.shppingDashData = data;
        this.shppingDashData$.next(this.shppingDashData);
    }
    onShppingDashDataData() {
        return this.shppingDashData$.asObservable();
    }
}
