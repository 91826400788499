import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger, NGXLogInterface } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { AppUtil } from '.././util/app.util';
import { AppConstant } from '../constants/app.constants';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public usCurrentDate;
    public usCurrentDate$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(public http: HttpClient, public logger: NGXLogger) {
     }
    public loginData;
    public loginData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    logOnServer(url: string, log: NGXLogInterface, options: object): Observable<any> {
        return this.http.post(url, log, options || {});
    }
    getHTTPClient() {
        return this.http;
    }
    async checkGetMethod(url: string) {
        const data = await this.http.get<{ data: Object }>(url,
            { observe: 'response', headers: AppUtil.getHttpHeaders()} ).toPromise() as any;
        this.checkError(data.status);
        return data;
    }
    async checkGetMethodWithParams(url: string, param: HttpParams) {
        const data = await this.http.get<{ data: Object }>(url,
            { observe: 'response', params: param, headers: AppUtil.getHttpHeaders()}).toPromise() as any;
        this.checkError(data.status);
        return data;
    }
    async checkPatchMethodWithParams(url: string, param: HttpParams, body) {
        const data = await this.http.patch<{ data: Object }>(url, body, { observe: 'response',
        params: param, headers: AppUtil.getHttpHeaders() }).toPromise() as any;
        this.checkError(data.status);
        return data;
    }
    async checkPostMethod(url: string, body) {
        return await this.http.post(url, body, { observe: 'response' }).toPromise() as any;
    }
    async checkPostMethodWithParams(url: string, param, body) {
        const data = await this.http.post<{ data: Object }>(url, body, { observe: 'response',
        params: param, headers: AppUtil.getHttpHeaders() }).toPromise() as any;
        this.checkError(data.status);
        return data;
    }
    // getUSCurrentDate(): Observable<any> {
    //     return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.CURENTDATE_TIME_VIEWNAME),
    //     { observe: 'response', headers: AppUtil.getHttpHeaders() });
    // }
    async getUSCurrentDate() {
        const data = await this.checkGetMethod(AppUtil.getAppUrl(AppConstant.CURENTDATE_TIME_VIEWNAME));
        this.setUSCurrentDate(data.body.resource);
    }
    setUSCurrentDate(data: any) {
        this.usCurrentDate = data;
        this.usCurrentDate$.next(this.usCurrentDate);
    }
    onUSCurrentDate() {
        return this.usCurrentDate$.asObservable();
    }
    checkError(status: number) {
        if (status === 202) {
            alert('The request has been accepted for processing, but the processing has not been completed');
        }
        if (status === 203) {
            alert('Partial Information');
        }
        if (status === 204) {
            alert('Server has received the request but there is no information to send back');
        }
        if (status === 400) {
            alert('bad request');
        }
        if (status === 401) {
            alert('UNAUTHIRIZED: The client should retry the request with a suitable Authorization header.');
        }
        if (status === 402) {
            alert('PaymentRequired: The client may retry the request with a suitable ChargeTo header.');
        }
        if (status === 403) {
            alert('Forbidden: The request is for something forbidden. Authorization will not help.');
        }
        if (status === 404) {
            alert('Not Found: The server has not found anything matching the URI given');
        }
        if (status === 500) {
            alert('server not found');
        }
        if (status === 501) {
            alert('Not implemented: The server does not support the facility required.');
        }
        if (status === 502) {
            alert('Service temporarily overloaded');
        }
        if (status === 503) {
            alert('Gateway timeout');
        }
    }
    // Registration API Calls

    postRegisterData(registrationDetails): Observable<any> {
        const url = AppUtil.getUrl(AppConstant.REGISTRATION_TABLENAME);
        registrationDetails.resource.OL_PASSWORD = AppConstant.DEFAULT_PASSWORD;
        registrationDetails.resource.OL_STATUS = AppConstant.DEFAULT_STATUS;
        registrationDetails.resource.OL_ADMIN = AppConstant.DEFAULT_ADMIN_VALUE;
        registrationDetails.resource.OL_ROLE = AppConstant.DEFAULT_ROLE;
        registrationDetails.resource.OL_LOCK = AppConstant.DEFAULT_LOCK;
        registrationDetails.resource.OL_ACTION_FLAG = AppConstant.OL_ACTION_FLAG;
        return this.http.post(url, registrationDetails, {headers: AppUtil.getHttpHeaders()});
    }
    getUserData(userName): Observable<any> {
        let param: HttpParams = AppUtil.getHttpParams();
        param = param.set('filter', AppConstant.USER_DATA_API_URL_FILTER.replace('<<replaceUserName>>', userName));
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.LOGIN_TABLENAME), { params: param,
        headers: AppUtil.getHttpHeaders()});
    }
    setUserData(data: any) {
        this.loginData = data;
        this.loginData$.next(this.loginData);
    }
    onUserData() {
        return this.loginData$.asObservable();
    }
    getOktaUserEmail(jwtUserID): Observable<any> {
        let param: HttpParams = AppUtil.getHttpParams();
        param = param.set('filter', 'id=' + jwtUserID);
        return this.http.get<{ data: Object }>((AppConstant.OKTA_USER_URL),
        { params: param , headers: AppUtil.getHttpHeaders() });
    }
}
