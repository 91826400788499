// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  PROD_API_KEY: 'e661c1263d20849224d6556a13bd5c710919776f81b0470ecbfffef791fe6c3c',
  DEV_API_KEY: 'a2db7e335c5df5506db9ebe645caa6ce65bfade0e47b6085b1b72b96f4d4fd93',
  DEV_EMAIL_API_KEY: '9f211bd8be913cea20e664e4196708e3abfe1b5dd03b87046acd54af59ab5f46',
  PROD_EMAIL_API_KEY: 'e661c1263d20849224d6556a13bd5c710919776f81b0470ecbfffef791fe6c3c'
};
