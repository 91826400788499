import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminService } from '../@core/data/admin.service';
import { RegisterService } from '../@core/data/register.service';
import { UserProfileModal } from '../@core/modal/userProfile.modal';
import { AppUtil } from './../@core/util/app.util';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
    static searchTerm: any = {};
    userProfileData: UserProfileModal = new UserProfileModal();

    constructor(
        public registerService: RegisterService,
        public adminService: AdminService
    ) {
        this.getRegistrationDetails();
        this.userProfileData.view = 'Select';
    }
    getSearchTerm() {
        return UserProfileComponent.searchTerm;
    }

    ngOnInit() { }
    getRegistrationDetails() {
        this.registerService.setDataLoader(true);
        this.registerService.onRegistrationDetails().subscribe(registerData => {
            if (!AppUtil.isNullOrUndefined(registerData)) {
                this.registerService.setDataLoader(false);
                this.userProfileData.userDetails = registerData;
                this.userProfileData.userDetails.forEach(element => {
                    element.OL_ACTION_FLAG = AppUtil.isNullOrUndefined(element.OL_ACTION_FLAG) ? 'INACTIVE' : element.OL_ACTION_FLAG;
                    element.OL_ROLE_Array = AppUtil.isNullOrUndefined(element.OL_ROLE)
                        ? []
                        : element.OL_ROLE.split('|').map(Number);
                    element.selectedItems = [];
                    element.view = element.OL_ADMIN;
                    this.setViewforUser(element.view);
                });
                this.getRoleData();
            } else {
                this.registerService.getRegistartionDetails().subscribe(data => {
                    this.registerService.setRegistartionDetails(data.body.resource);
                });
            }
        });
    }
    setViewforUser(view) {
        if (view === null) {
            return this.userProfileData.view = 'Select';
        } else if (view === 0) {
            return this.userProfileData.view = 'Custom';
        } else if (view === 1) {
            return this.userProfileData.view = 'Admin';
        } else if (view === 2) {
            return this.userProfileData.view = 'View All';
        }
    }
    getRoleData() {
        this.adminService.getRoleData().subscribe(roleData => {
            this.adminService.setRoleData(roleData.resource);
        });
        this.adminService.onRoleData().subscribe(roleData => {
            if (!AppUtil.isNullOrUndefined(roleData)) {
                this.userProfileData.roleData = roleData;
                this.userProfileData.dropdownList = [];
                this.userProfileData.roleData.forEach(element => {
                    this.userProfileData.dropdownList.push({
                        item_id: element.ROLE_ID,
                        item_text: element.ROLE_DESCRIPTION
                    });
                });
                this.userProfileData.viewList = this.userProfileData.dropdownList
                    .filter(x => x.item_text.includes('View'));
                this.userProfileData.userDetails.forEach(element => {
                    element.selectedItems = this.userProfileData.dropdownList.filter(
                        item => element.OL_ROLE_Array.includes(item.item_id)
                    );
                });
            }
        });
        this.userProfileData.dropdownSettings = {
            disabled: true,
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: false
        };
    }
    getRegisterData(columnName: string, emailID: string): any {
        return this.userProfileData.userDetails.filter(
            item => item.OL_EMAIL === emailID
        )[0][columnName];
    }
    getRolesForDropDown(row): any {
        return this.userProfileData.dropdownList;
    }
    getSelectedItems(row) {
        return row.selectedItems;
    }
    setRegisterData(columnName: string, value: any, row: any) {
        this.userProfileData.userDetails.filter(
            item => item.OL_EMAIL === row.OL_EMAIL
        )[0][columnName] = value;
    }
    adminViewClick(row, view, value) {
        row.OL_ADMIN = value;
        switch (row.OL_ADMIN) {
            case 0:  break;
            case 1:  row.selectedItems = this.userProfileData.dropdownList; break;
            case 2:  row.selectedItems = this.userProfileData.viewList; break;
            case null: row.selectedItems =  []; break;
        }
        row.OL_ROLE_Array = [];
        row.selectedItems.forEach(x => this.onItemSelect(x, row));
        this.setRegisterData('view', view, row);
        const custom = value === 1;
        this.setRegisterData('custom_dropdown', custom, row);
        this.setRegisterData('OL_ADMIN', value, row);
    }
    changeRole(email) {
        const adminVal = !AppUtil.isNullOrUndefined(this.getRegisterData('OL_ROLE', email)) ? null : 0;
        this.adminService
            .updateStatus(email, this.getRegisterData('OL_ADMIN', email), this.getRegisterData('OL_ROLE', email), adminVal, 'ACTIVE')
            .subscribe(() => {
                AppUtil.showSwal('OpsLink Message:', 3, 'Role is Successfully changed.' );
            });
    }
    lockUser(email) {
        this.adminService
            .updateStatus(email, this.getRegisterData('OL_ADMIN', email), this.getRegisterData('OL_ROLE', email), 1, 'LOCKED')
            .subscribe(() => {
                AppUtil.showSwal('OpsLink Message:', 3, 'User is Locked.');
            });
    }
    onItemSelect(item: any, row) {
        const OL_ROLE_Array = this.getRegisterData('OL_ROLE_Array', row.OL_EMAIL);
        if (item.item_text.includes('Edit')) {
            const id = this.userProfileData.dropdownList
            .filter(x => x.item_text === String(item.item_text)
            .replace('Edit', 'View'))[0].item_id;
            if (!OL_ROLE_Array.includes(id)) {
                OL_ROLE_Array.push(id);
            }
        }
        if (!OL_ROLE_Array.includes(item.item_id)) {
            OL_ROLE_Array.push(item.item_id);
        }
        this.setRegisterData('OL_ROLE_Array', OL_ROLE_Array, row);
        this.setRegisterData('OL_ROLE', OL_ROLE_Array.join('|'), row);
    }
    onItemDeSelect(item: any, row) {
        const OL_ROLE_Array = this.getRegisterData('OL_ROLE_Array', row.OL_EMAIL);
        OL_ROLE_Array.splice(OL_ROLE_Array.indexOf(item.item_id), 1);
        this.setRegisterData('OL_ROLE_Array', OL_ROLE_Array, row);
        this.setRegisterData('OL_ROLE', OL_ROLE_Array.join('|'), row);
    }
    onSelectAll(items: any, row) {
        const OL_ROLE_Array = [];
        if (items.length !== 0) {
            items.forEach(item => {
                OL_ROLE_Array.push(item.item_id);
            });
        }
        this.setRegisterData('OL_ROLE_Array', OL_ROLE_Array, row);
        this.setRegisterData('OL_ROLE', OL_ROLE_Array.join('|'), row);
    }
    ngOnDestroy () {
        UserProfileComponent.searchTerm = {};
    }
}
