import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { AppConstant } from 'src/app/@core/constants/app.constants';
import { AppUtil } from 'src/app/@core/util/app.util';
import { TestbloxExceptionReportModal } from '../modal/testbloxExceptionReport.modal';
import { AppService } from './app.service';

@Injectable()
export class TestbloxExceptionReportService {
    public exceptionData;
    public exceptionData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public exceptionExcelData;
    public exceptionExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: Subject<any> = new Subject<any>();
    currentDate;
    constructor(public appService: AppService, public http: HttpClient) {
    }
    getExceptionData (exceptionReportModalData: TestbloxExceptionReportModal): Observable<any> {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', exceptionReportModalData.order);
        params = params.set('offset', exceptionReportModalData.offset);
        params = params.set('filter', exceptionReportModalData.filter);
        exceptionReportModalData.offset += 300;
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.TEXTBLOX_EXCEPTION_REPORT_VIEWNAME),
        { params : params, headers: AppUtil.getHttpHeaders()});
    }
    setExceptionData(data: any) {
        this.exceptionData = data;
        this.exceptionData$.next(this.exceptionData);
    }
    onExceptionData() {
        return this.exceptionData$.asObservable();
    }
    async getExceptionExcelData(exceptionReportModalData: TestbloxExceptionReportModal) {
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('filter', exceptionReportModalData.filter);
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.TEXTBLOX_EXCEPTION_REPORT_VIEWNAME), params);
        this.setExceptionaExcelData(data.body.resource);
    }
    setExceptionaExcelData(data: any) {
        this.exceptionExcelData = data;
        this.exceptionExcelData$.next(this.exceptionExcelData);
    }
    onExceptionxcelData() {
        return this.exceptionExcelData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
