import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from 'src/app/@core/constants/app.constants';
import { isNullOrUndefined } from 'util';
import { SessionProperties } from '../session/session.properties';
import { OrderBacklogReportModal } from './../modal/orderBacklog.modal';
import { AppUtil } from './../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class BacklogService {
    public backlogData;
    public backlogData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public rbacklogData;
    public rbacklogData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public backlogDashboardData;
    public backlogDashboardData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public orderStatusList;
    public orderStatusList$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public backlogExcelData;
    public backlogExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public exceptionDetails;
    public exceptionDetails$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public maxDate;
    public maxDate$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);
    currentDate;
    constructor(public appService: AppService, public http: HttpClient) {
    }
    getDates(view) {
        let filter;
        if (view === 'Qtr To Date' || view == null) {
            filter = SessionProperties.quaterStartDate + ',' + SessionProperties.quaterEndDate;
        } else if (view === 'Month To Date') {
            filter = SessionProperties.monthStartDate + ',' + SessionProperties.monthEndDate;
        } else if (view === 'Previous Qtr') {
            filter = SessionProperties.prvQtrStartDate + ',' + SessionProperties.prvQtrEndDate;
        } else if (view === 'Complete View') {
            filter = '' + ',' + '';
        }
        return filter;
    }
    getFilter(view, type) {
        console.log(SessionProperties.quaterStartDate, SessionProperties.quaterEndDate)
        let filter;
        if (view === 'Qtr To Date' || view == null) {
            filter = '(((ORDER_DATE>='
                + SessionProperties.quaterStartDate
                + ')AND(ORDER_DATE<='
                + SessionProperties.quaterEndDate
                + '))OR(ORDER_DATE IS NULL))AND(ORDER_CATEGORY=\'DOF\')AND(ORDER_TYPE LIKE \'%' + type.trim() + '%\')';
        } else if (view === 'Month To Date') {
            filter = '((ORDER_DATE>='
                + SessionProperties.monthStartDate
                + ')AND(ORDER_DATE<='
                + SessionProperties.monthEndDate
                + '))AND(ORDER_CATEGORY=\'DOF\')AND(ORDER_TYPE LIKE \'%' + type.trim() + '%\')';
        } else if (view === 'Previous Qtr') {
            filter = '((ORDER_DATE>='
                + SessionProperties.prvQtrStartDate
                + ')AND(ORDER_DATE<='
                + SessionProperties.prvQtrEndDate
                + '))AND(ORDER_CATEGORY=\'DOF\')AND(ORDER_TYPE LIKE \'%' + type.trim() + '%\')';
        } else if (view === 'Complete View') {
            filter = '(ORDER_CATEGORY=\'DOF\')AND(ORDER_TYPE LIKE \'%' + type.trim() + '%\')';
        }
        return filter;
    }
    filterForOrderNoOnly (orderNo) {
        let filter;
        return filter = '(FIN_ORDER_NO=\'' + orderNo + '\')';
    }
    getBacklogData(orderBacklogModalData: OrderBacklogReportModal): Observable<any> {
        this.setDataLoader(true);
        let filter = '';
        if (AppUtil.isNullOrUndefined(orderBacklogModalData.arrowOrderNo)) {
            filter = this.getFilter(orderBacklogModalData.view, orderBacklogModalData.orderType);
        } else {
            filter = this.filterForOrderNoOnly(orderBacklogModalData.arrowOrderNo);
        }
        filter += orderBacklogModalData.filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('offset', orderBacklogModalData.offset);
        params = params.set('filter', filter);
        params = params.set('order', orderBacklogModalData.order);
        if (orderBacklogModalData.view === '' || orderBacklogModalData.view === 'Qtr To Date') {
            params = params.set('related', 'OL_EXCEPTION_REPORT_by_FIN_ORDER_NO');
        }
        params = params.set('group', 'FIN_ORDER_NO');
        orderBacklogModalData.offset += 300;
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.BACKLOG_TABLENAME), { params: params,
            headers: AppUtil.getHttpHeaders()});
    }
    setBacklogData(data: any) {
        this.backlogData = data;
        this.backlogData$.next(this.backlogData);
    }
    onBacklogData() {
        return this.backlogData$.asObservable();
    }
    async getRemainingBacklogData(orderBacklogModalData: OrderBacklogReportModal) {
        // tslint:disable-next-line:max-line-length
        const remFields = 'PART_NO,LINE_NO,QTY,RPE_FLAG,LINE_NOTES,ORDER_DATE,FIN_ORDER_NO,LINE_STATUS,CM_WO,CM_PO,MPN,SUB_INV,INCOMING_SERIAL_NO,SALES_REP,SCHEDULED_DATE,LIC_LOAD';
        let filter = '';
        if (isNullOrUndefined(orderBacklogModalData.arrowOrderNo)) {
            filter = this.getFilter(orderBacklogModalData.view, orderBacklogModalData.orderType);
        } else {
            filter = this.filterForOrderNoOnly(orderBacklogModalData.arrowOrderNo);
        }
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('order', orderBacklogModalData.order);
        params = params.set('filter', filter);
        params = params.set('fields', remFields);
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.BACKLOG_TABLENAME), params);
        this.setRemainingBacklogData(data.body.resource);
    }
    setRemainingBacklogData(data: any) {
        this.rbacklogData = data;
        this.rbacklogData$.next(this.rbacklogData);
    }
    onRemainingBacklogData() {
        return this.rbacklogData$.asObservable();
    }
    // async getExceptionData() {
    //     if (AppUtil.isNullOrUndefined(this.exceptionDetails)) {
    //         const url = AppUtil.getUrl(AppConstant.EXCEPTION_TABLENAME) +
    //             '&related=OL_BACKLOG_REPORT_by_FIN_ORDER_NO&' +
    //             'OL_BACKLOG_REPORT_by_FIN_ORDER_NO.fields=EXCEP_CHECK';
    //         const data: any = await this.appService.checkGetMethod(url);
    //         this.setExceptionData(data.body.resource);
    //     }
    // }
    // setExceptionData(data: any) {
    //     this.exceptionDetails = data;
    //     this.exceptionDetails$.next(this.exceptionDetails);
    // }
    // onExceptionData() {
    //     return this.exceptionDetails$.asObservable();
    // }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
    updateBacklogData(admin_data, FIN_ORDER_NO): Observable<any> {
        const url = AppUtil.getUrlFilter(
            AppConstant.BACKLOG_TABLENAME,
            'FIN_ORDER_NO=' + FIN_ORDER_NO
        );

        return this.http.patch(url, admin_data);
    }
    updateOrderStatus(note, FIN_ORDER_NO, userId) {
        const updateStatus = {
            resource: [
                {
                    ORDER_STATUS: note,
                    ORDER_UID: userId
                }
            ]
        };
        return this.updateBacklogData(
            updateStatus,
            FIN_ORDER_NO
        );
    }
    updateOrderStatusShipReport(note, FIN_ORDER_NO, userId): Observable<any> {
        const updateStatus = {
            resource: [
                {
                    ORDER_STATUS: note,
                    ORDER_UID: userId
                }
            ]
        };
        const url = AppUtil.getUrlFilter(
            AppConstant.SHIP_REPORT_TABLENAME,
            'FIN_ORDER_NO=' + FIN_ORDER_NO
        );

        return this.http.patch(url, updateStatus);
    }
    updateOrderNotes(note, FIN_ORDER_NO, userId) {
        const updateNotes = {
            resource: [
                {
                    ORDER_NOTES: note,
                    ORDER_UID: userId
                }
            ]
        };
        return this.updateBacklogData(updateNotes, FIN_ORDER_NO);
    }
    updateLineNotes(note, LINE_NO, FIN_ORDER_NO, userId): Observable<any> {
        const updateNotes = {
            resource: [
                {
                    LINE_NOTES: note,
                    LINE_UID: userId
                }
            ]
        };
        const url = AppUtil.getUrlFilter(
            AppConstant.BACKLOG_TABLENAME,
            '(LINE_NO=' + LINE_NO + ')AND(FIN_ORDER_NO=' + FIN_ORDER_NO + ')'
        );
        return this.http.patch(url, updateNotes);
    }
    updateOrderMasterOrderStatus(FIN_ORDER_NO, status): Observable<any> {
        const updatedStatus = {
            resource: [
                {
                    ORDER_STATUS: status
                }
            ]
        };
        const url = AppUtil.getUrlFilter(
            AppConstant.ORDERMASTER_TABLENAME,
            'FIN_ORDER_NO=' + FIN_ORDER_NO
        );

        return this.http.patch(url, updatedStatus);
    }
    async getOrderStatusList() {
        const url = AppUtil.getUrl(AppConstant.STATUS_TABLENAME);
        const data: any = await this.appService.checkGetMethod(url);
        this.setOrderStatusList(data.body.resource);
        this.setDataLoader(false);
    }
    setOrderStatusList(data: any) {
        this.orderStatusList = data;
        this.orderStatusList$.next(this.orderStatusList);
    }
    onOrderStatusList() {
        return this.orderStatusList$.asObservable();
    }
    async getExcelBacklogData(orderBacklogModalData: OrderBacklogReportModal) {
        let filter = '';
        if (AppUtil.isNullOrUndefined(orderBacklogModalData.arrowOrderNo) || orderBacklogModalData.arrowOrderNo === '') {
            filter = this.getFilter(orderBacklogModalData.view, orderBacklogModalData.orderType);
        } else {
            filter = this.filterForOrderNoOnly(orderBacklogModalData.arrowOrderNo);
        }
        filter += orderBacklogModalData.filter;
        let param: HttpParams = AppUtil.getHttpParams();
        param = param.set('filter', filter);
        if (orderBacklogModalData.excelFieldStatus === 'Open') {
            param = param.set('fields', AppConstant.BACKLOG_OPEN_EXCELCOLUMNS);
        } else {
            param = param.set('fields', AppConstant.BACKLOG_EXCELCOLUMNS);
        }
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.BACKLOG_TABLENAME), param);
        this.setExcelBacklogData(data.body.resource);
    }
    setExcelBacklogData(data: any) {
        this.backlogExcelData = data;
        this.backlogExcelData$.next(this.backlogExcelData);
    }
    onExcelBacklogData() {
        return this.backlogExcelData$.asObservable();
    }
    async updateCancelRequest(orderNo: string) {
        const param: HttpParams = AppUtil.getHttpParams();
        await this.appService.checkGetMethodWithParams(AppUtil
            .updateCancelReqStatus(AppConstant.CANCEL_REQUEST_PROC_NAME, orderNo), param);
    }
    async editBacklogColumns(orderBacklogModalData: OrderBacklogReportModal, FIN_ORDER_NO) {
        const updateColumns = {
            resource: [
                orderBacklogModalData.dynamicColumnValues
            ]
        };
        let param: HttpParams = AppUtil.getHttpParams();
        param = param.set('filter', '(FIN_ORDER_NO=' + FIN_ORDER_NO + ')');
        const data = await this.appService.checkPatchMethodWithParams(AppUtil
            .getAppUrl(AppConstant.BACKLOG_TABLENAME), param, updateColumns);
        orderBacklogModalData.codeStatus = data.status;
        return data;
    }
    async storeOldColumnValue(finOrderNo: string, flag: string, columns: string, userId: string, reason: string) {
        const param: HttpParams = AppUtil.getHttpParams();
        return await this.appService.checkGetMethodWithParams(AppUtil
            .editColumnBacklogReport(finOrderNo, flag, columns, userId, reason, AppConstant.EDITCOLUMN_BACKLOG_PROC), param);
    }
    async getMaxDate() {
        let params: HttpParams = AppUtil.getHttpParams();
        const currentMonth = SessionProperties.currentDate.split('-')[1];
        // const filter = 'ORDER_DATE >= ' + AppConstant.currentYear + '-' + currentMonth + '-' + '01';
        // params = params.set('filter', filter);
        params = params.set('fields', 'LAST_UPDATED');
        params = params.set('order', 'LAST_UPDATED DESC');
        params = params.set('group', 'FIN_ORDER_NO');
        params = params.set('limit', '1');
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.BACKLOG_TABLENAME), params);
        this.setMaxDate(data.body.resource[0]);
    }
    setMaxDate(data: any) {
        this.maxDate = data;
        this.maxDate$.next(this.maxDate);
    }
    onMaxDate() {
        return this.maxDate$.asObservable();
    }
}
