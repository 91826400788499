import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from 'src/app/@core/constants/app.constants';
import { RmaSummaryReportModal } from '../modal/rmaSummaryReport.modal';
import { SessionProperties } from '../session/session.properties';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class RmaSummaryReportService {
    public rmaSummaryData;
    public rmaSummaryData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public rmaSummaryExcelData;
    public rmaSummaryExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public workaroundExcelData;
    public workaroundExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);
    public maxDate: any;
    public maxDate$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    currentDate;
    constructor(public appService: AppService, public http: HttpClient) {
    }
    getRmaSummaryReportData(rmaSummaryReportModal: RmaSummaryReportModal): Observable<any> {
        this.setDataLoader(true);
        const filter = rmaSummaryReportModal.filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('offset', rmaSummaryReportModal.offset);
        params = params.set('filter', filter);
        params = params.set('order', rmaSummaryReportModal.order);
        rmaSummaryReportModal.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(rmaSummaryReportModal.tableName),
        { params : params, headers: AppUtil.getHttpHeaders()});
    }
    setRmaSummaryReportData(data: any) {
        this.rmaSummaryData = data;
        this.rmaSummaryData$.next(this.rmaSummaryData);
    }
    onRmaSummaryReportData() {
        return this.rmaSummaryData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
    async getExcelRmaSummaryReportData(rmaSummaryReportModal: RmaSummaryReportModal) {
        this.setDataLoader(true);
        const filter = rmaSummaryReportModal.filter;
        let param: HttpParams = AppUtil.getHttpParams();
        param = param.set('filter', filter);
        // param = param.set('fields', AppConstant.NEW_RMA_NOW_EXCELCOLUMNS);
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(rmaSummaryReportModal.tableName), param);
        this.setExcelRmaSummaryReportData(data.body.resource);
    }
    setExcelRmaSummaryReportData(data: any) {
        this.rmaSummaryExcelData = data;
        this.rmaSummaryExcelData$.next(this.rmaSummaryExcelData);
    }
    onExcelRmaSummaryReportData() {
        return this.rmaSummaryExcelData$.asObservable();
    }
    // Generate excel for MIN QTY workaround report
    async getWorkaroundReportData() {
        this.setDataLoader(true);
        let param: HttpParams = AppUtil.getHttpParams();
        param = param.set('filter', '(CUR_MONTH=' + SessionProperties.currentMonth + ') AND (CUR_YEAR=' + SessionProperties.currentYear + ')');
        param = param.set('fields', 'DEPOT_NAME,PLATFORM,BOOK_WEEK,TOTAL_COUNT');
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.RMA_MIN_QTY_WORKAROUND_TABLENAME), param);
        this.setWorkaroundReportData(data.body.resource);
    }
    setWorkaroundReportData(data: any) {
        this.workaroundExcelData = data;
        this.workaroundExcelData$.next(this.workaroundExcelData);
    }
    onWorkaroundReportData() {
        return this.workaroundExcelData$.asObservable();
    }
}
