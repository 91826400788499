import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { NavbarComponent } from './navbar.component';
@NgModule({
    imports: [ RouterModule, CommonModule, MatButtonModule, NgMultiSelectDropDownModule, TourMatMenuModule ],
    declarations: [ NavbarComponent ],
    exports: [ NavbarComponent ]
})

export class NavbarModule {}
