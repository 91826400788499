import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class AdminService {
    constructor(public appService: AppService, public http: HttpClient) { }
    public roleData;
    public roleData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    updateStatus(userEmailId, adminFlag, role, lockFlag, actionFlag) {
        const updateAdminStatusBody = {
            resource: [
                {
                    OL_ADMIN: adminFlag,
                    OL_STATUS: 1,
                    OL_ROLE: role,
                    OL_LOCK: lockFlag,
                    OL_ACTION_FLAG: actionFlag
                }
            ]
        };
        let param: HttpParams = AppUtil.getHttpParams();
        param = param.set('filter', 'OL_EMAIL=' + userEmailId);
        return this.http.patch(AppUtil.getAppUrl(AppConstant.REGISTRATION_TABLENAME), updateAdminStatusBody, {
            params: param,
            headers: AppUtil.getHttpHeaders()
        });
    }
    getRoleData(): Observable<any> {
        return this.http.get<{ data: Object }>(AppUtil.getUrl(AppConstant.ADMIN_ROLE_TABLENAME),
            { headers: AppUtil.getHttpHeaders() });
    }
    setRoleData(data: any) {
        this.roleData = data;
        this.roleData$.next(this.roleData);
    }
    onRoleData() {
        return this.roleData$.asObservable();
    }
}
