import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { S3TestbloxModal } from '../modal/s3Testblox.modal';
import { AppUtil } from '../util/app.util';
import { S3Constants } from './../constants/s3.constant';
import { S3File } from './../modal/s3file.modal';
import { AppService } from './app.service';

@Injectable()
export class IMDBS3Service {
    public imdbFilesList;
    public imdbFilesList$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public imdbFilesData;
    public imdbFilesData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public downloadUrl;
    public downloadUrl$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

    currentDate;
    constructor(public appService: AppService, public http: HttpClient) {
    }
    // async getImdbFilesList(bucketServiceName, directoryName) {
    //     this.setDataLoader(true);
    //     console.log(bucketServiceName);
    //     const params: HttpParams = AppUtil.getHttpParams();
    //     const fileNames: S3File[] = [];
    //     for (const subDirectoryName of S3Constants.SUB_DIRECTORY_NAME) {
    //         const args = directoryName + '/' + subDirectoryName + '/';
    //         const data = await this.appService.checkGetMethodWithParams(
    //             AppUtil.getTestbloxS3Url(bucketServiceName, args), params);
    //         console.log(args, data);
    //         data.body.resource.forEach(element => {
    //             fileNames.push({
    //                 name: element.name,
    //                 folder: subDirectoryName
    //             });
    //         });
    //     }
    //     this.setImdbFilesList(fileNames);
    //     this.setDataLoader(false);
    // }
    // setImdbFilesList(data: any) {
    //     this.imdbFilesList = data;
    //     this.imdbFilesList$.next(this.imdbFilesList);
    // }
    // onImdbFilesList() {
    //     return this.imdbFilesList$.asObservable();
    // }
    getImdbFilesList(tableName: string, s3TestbloxModalData: S3TestbloxModal, folder: string): Observable<any> {
        this.setDataLoader(true);
        let param: HttpParams = AppUtil.getHttpParams();
        let filter = '';
        let locationFiler = '';
        if (tableName.includes('SPHINX')) {
            filter = '(SERIAL_NO LIKE %' + s3TestbloxModalData.searchSerialNo + '%)';
            if ( JSON.stringify(s3TestbloxModalData.searchTestArray) == JSON.stringify(S3Constants.TEST_TYPE_ARRAY)) {
                param = param.set('filter', filter);
            } else {
                s3TestbloxModalData.searchTestArray.forEach(element => {
                    locationFiler += locationFiler === '' ? ' (LOCATION LIKE %' + element + '%)' : ' OR (LOCATION LIKE %' + element + '%)';
                    param = param.set('filter', filter + ' AND (' + locationFiler + ')');
                });
            }
        } else {
            filter = '(SERIAL_NO LIKE %' + s3TestbloxModalData.searchSerialNo + '%) AND (PRODUCT LIKE %' + folder + '%)';
            param = param.set('filter', filter);
            if(JSON.stringify(s3TestbloxModalData.selectedSiteNameArray) == JSON.stringify(S3Constants.ALL_SUB_DIRECTORY_NAME)) {
                param = param.set('filter', filter);
            } else {
                s3TestbloxModalData.selectedSiteNameArray.forEach(element => {
                    locationFiler += locationFiler === '' ? ' (LOCATION LIKE %' + element + '%)' : ' OR (LOCATION LIKE %' + element + '%)';
                    param = param.set('filter', filter + ' AND (' + locationFiler + ')');
                });    
            }
        }
        param = param.set('limit', '300');
        param = param.set('offset', s3TestbloxModalData.offset);
        s3TestbloxModalData.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(tableName), { params: param,
            headers: AppUtil.getHttpHeaders()});
    }
    // getImdbFilesList(tableName: string, s3TestbloxModalData: S3TestbloxModal, folder: string): Observable<any> {
    //     this.setDataLoader(true);
    //     let param: HttpParams = AppUtil.getHttpParams();
    //     let filter = '';
    //     filter = '(SERIAL_NO LIKE %' + s3TestbloxModalData.searchSerialNo + '%) AND (PRODUCT LIKE %' + folder + '%)';
    //     let locationFiler = '';
    //     if(JSON.stringify(s3TestbloxModalData.selectedSiteNameArray) == JSON.stringify(S3Constants.ALL_SUB_DIRECTORY_NAME)) {
    //         param = param.set('filter', filter);
    //     } else {
    //         s3TestbloxModalData.selectedSiteNameArray.forEach(element => {
    //             locationFiler += locationFiler === '' ? ' (LOCATION LIKE %' + element + '%)' : ' OR (LOCATION LIKE %' + element + '%)';
    //             param = param.set('filter', filter + ' AND (' + locationFiler + ')');
    //         });
    //     }
    //     param = param.set('limit', '300');
    //     param = param.set('offset', s3TestbloxModalData.offset);
    //     s3TestbloxModalData.offset += 300;
    //     this.setDataLoader(false);
    //     return this.http.get<{ data: Object }>(AppUtil.getAppUrl(tableName), { params: param,
    //         headers: AppUtil.getHttpHeaders()});
    // }
    setImdbFilesList(data: any) {
        this.imdbFilesList = data;
        this.imdbFilesList$.next(this.imdbFilesList);
    }
    onImdbFilesList() {
        return this.imdbFilesList$.asObservable();
    }
    async downloadTestbloxfile (filePath, type, bucketName) {
        this.setDataLoader(true);
        let header: HttpHeaders = new HttpHeaders();
        header = header.set('x-api-key', 'gElMGroKiE6xFbKpL5BzBaoOHcqdvQ4r6AlgHzql');
        let param: HttpParams = AppUtil.getHttpParams();
        const path =  filePath.replace('log', 'xyz');
        param = param.set('bucket', bucketName);
        param = param.set('key', path);
        param = param.set('action', type);
        const data = await this.appService.getHTTPClient()
            .get(AppUtil.getLambdaTestbloxLogUrl(),
            { responseType: 'text', headers: header, params: param}).toPromise() as any;
        this.setTestbloxfile(data);
        this.setDataLoader(false);
    }
    setTestbloxfile(data: any) {
        this.downloadUrl = data;
        this.downloadUrl$.next(this.downloadUrl);
    }
    onTestbloxfile() {
        return this.downloadUrl$.asObservable();
    }
    // uploadAudiFile(filebody, filename): Observable<any> {
    //     let header: HttpHeaders = new HttpHeaders();
    //     header = header.set('X-File-Name', filename);
    //     return this.http.post(AppUtil.getTestbloxS3Url('AUDI/'), filebody, {headers: header});
    // }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
