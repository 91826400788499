import { Component } from '@angular/core';
import { AppConstant } from 'src/app/@core/constants/app.constants';
import { SessionProperties } from 'src/app/@core/session/session.properties';

@Component({
    selector: 'app-footer-cmp',
    templateUrl: 'footer.component.html'
})

export class FooterComponent {
    test: Date = new Date();
    public footerPrvYear: any;
    public footerCurYear: any;
    constructor() {
        this.footerPrvYear = (parseInt(SessionProperties.currentYear, 10) - 1);
        this.footerCurYear = SessionProperties.currentYear;
     }
}
