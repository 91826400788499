import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { AppUtil } from '../util/app.util';
import { S3Constants } from './../constants/s3.constant';
import { AppService } from './app.service';

@Injectable()
export class TB5DataViewService {
    public dataLoader: boolean;
    public dataLoader$: Subject<any> = new Subject<any>();
    currentDate;
    constructor(public appService: AppService, public http: HttpClient) {
    }
    async getFile(fileName: string) {
        const URL = AppUtil.getTestbloxS3Url(S3Constants.TB3_BUCKET_SERVICE, S3Constants.TB3) + '/' + fileName;
        const data = await this.appService.getHTTPClient()
            .get(URL, { responseType: 'text', headers: AppUtil.getHttpHeaders()}).toPromise() as any;
        return data;
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
