import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class TestBloxSerialNumberService {
    public serialNumberData;
    public serialNumberData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public startTimeData;
    public startTimeData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

    currentDate;
    constructor(public appService: AppService, public http: HttpClient) {
    }
    async getSerialNumberData(serialNumber: string, tableName: string) {
        if (AppUtil.isNullOrUndefined(serialNumber)) {
            return;
        }
        const filter: string = '(SERIAL_NUMBER LIKE %' + serialNumber + '%)';
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('filter', filter);
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(tableName), params);
        this.setSerialNumberData(data.body.resource);
    }
    setSerialNumberData(data: any) {
        this.serialNumberData = data;
        this.serialNumberData$.next(this.serialNumberData);
    }
    onSerialNumberData() {
        return this.serialNumberData$.asObservable();
    }
    async getStartTimeData(serialNumber: string, startTime: string, status: string) {
        if (AppUtil.isNullOrUndefined(startTime )) {
            return;
        }
        const param: HttpParams = AppUtil.getHttpParams();
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getTestbloxFailedSLNODetails(AppConstant.TESTBLOX_FAILED_SLNO_PROCEDURENAME, serialNumber, startTime, status), param);
        this.setStartTimeData(data.body);
    }
    setStartTimeData(data: any) {
        this.startTimeData = data;
        this.startTimeData$.next(this.startTimeData);
    }
    onStartTimeData() {
        return this.startTimeData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
