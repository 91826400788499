import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { AppConstant } from 'src/app/@core/constants/app.constants';
import { B2bAsnErrorReportModal } from '../modal/b2bAsnErrorReport.modal';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class B2bAsnErrorReportService {
    public b2bErrorReportReportData;
    public b2bErrorReportReportData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public b2bErrorReportExcelData;
    public b2bErrorReportExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: Subject<any> = new Subject<any>();
    currentDate;
    constructor(public appService: AppService, public http: HttpClient) {
    }
    getB2bAsnErrorReportData(b2bAsnErrorReportModal: B2bAsnErrorReportModal): Observable<any> {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', b2bAsnErrorReportModal.order);
        params = params.set('offset', b2bAsnErrorReportModal.offset);
        params = params.set('filter', b2bAsnErrorReportModal.filter);
        b2bAsnErrorReportModal.offset += 300;
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.B2B_ASN_ERROR_REPORT_TABLENAME),
            { params: params, headers: AppUtil.getHttpHeaders() });
    }
    setB2bAsnErrorReportData(data: any) {
        this.b2bErrorReportReportData = data;
        this.b2bErrorReportReportData$.next(this.b2bErrorReportReportData);
    }
    onB2bAsnErrorReportData() {
        return this.b2bErrorReportReportData$.asObservable();
    }
    async getB2bAsnErrorReportExcelData(b2bAsnErrorReportModal: B2bAsnErrorReportModal) {
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('filter', b2bAsnErrorReportModal.filter);
        params = params.set('order', b2bAsnErrorReportModal.order);
        params = params.set('fields', AppConstant.B2B_ASN_ERROR_REPORT_FIELDS);
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.B2B_ASN_ERROR_REPORT_TABLENAME), params);
        this.setB2bAsnErrorReportExcelData(data.body.resource);
    }
    setB2bAsnErrorReportExcelData(data: any) {
        this.b2bErrorReportExcelData = data;
        this.b2bErrorReportExcelData$.next(this.b2bErrorReportExcelData);
    }
    onB2bAsnErrorReportExcelData() {
        return this.b2bErrorReportExcelData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
