import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { AppConstant } from '../constants/app.constants';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class TestbloxService {
    public dashboardData;
    public dashboardData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public monitoringData;
    public monitoringData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: Subject<any> = new Subject<any>();

    constructor(public appService: AppService) { }

    async getTestbloxDashboardData() {
        const param: HttpParams = AppUtil.getHttpParams();
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.TESTBLOXDASHBOARD_TABLENAME), param);
        this.setTestbloxDashboardData(data.body.resource);
    }
    setTestbloxDashboardData(data: any) {
        this.dashboardData = data;
        this.dashboardData$.next(this.dashboardData);
    }
    onTestbloxDashboardData() {
        return this.dashboardData$.asObservable();
    }
    async getTestbloxMonitoringData() {
        const param: HttpParams = AppUtil.getHttpParams();
        const data = await this.appService.checkGetMethodWithParams
            (AppUtil.getAppUrl(AppConstant.PXE_MONITORING_TABLENAME), param);
        this.setTestbloxMonitoringData(data.body.resource);
    }
    setTestbloxMonitoringData(data: any) {
        this.monitoringData = data;
        this.monitoringData$.next(this.monitoringData);
    }
    onTestbloxMonitoringData() {
        return this.monitoringData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
