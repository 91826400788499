import { Header } from './header.modal';

export class AudiReportModal {
    readonly audiReportHeader: Header[] = [
        {
            columnName: 'SERIAL_NUMBER',
            displayName: 'SERIAL NUMBER',
            columnType: 'string'
        },
        {
            columnName: 'PART_NUMBER',
            displayName: 'PART NUMBER',
            columnType: 'string'
        },
        // {
        //     columnName: 'SOFTWARE_SKU',
        //     displayName: 'SOFTWARE SKU',
        //     columnType: 'string'
        // },
        {
            columnName: 'HWS',
            displayName: 'HWS',
            columnType: 'string'
        },
        {
            columnName: 'SITE_NAME',
            displayName: 'SITE NAME',
            columnType: 'string'
        },
        {
            columnName: 'TEST_TYPE',
            displayName: 'TEST TYPE',
            columnType: 'string'
        },
        {
            columnName: 'START_TIME',
            displayName: 'START TIME',
            columnType: 'number'
        },
        {
            columnName: 'TEST_DATE',
            displayName: 'TEST DATE',
            columnType: 'number'
        },
        {
            columnName: 'TEST_STATUS',
            displayName: 'TEST STATUS',
            columnType: 'string'
        }
    ];
    public audiData: any;
    public totalRecords: number;
    public order: any;
    public reverse: boolean;
    public collapsed: boolean;
    public collapsedRowData: any;
    public selectedName: any;
    public offset: any;
    public currentUser: any;
    public filter: string;
    public view: string;
    public OL_EMAIL: string;
    public OL_ID: number;
    public OL_GROUPNAME: string;
    public userAdminValue: any;
    public userRoleArray: any;
    public excelData: any;
    public serialNoArray: any;
    public serialNumberData: any;
    public selectedTabLabel: any;
    public createdOnDisplay: any;
    public serialNo: any;
    public searchSerialNo: any;
    public searchTestType: any;
    public searchSerialNoArray: any;
    public testTypeArray: any;
    public imdbFileList: any;
    public fileName: any;
    public eachFileData: any;
    public searchTestArray: any;
    public testTypeState: boolean;
    public selectedSiteNameArray: any;
    public startDate: any;
    public endDate: any;
    public dateRangeBool: boolean;
    public dateRangeFilter: any;
    public minDate: any;
    public maxDate: any;
    public testDate: any;
    public dateType: string;
    constructor() {
        this.order = 'SERIAL_NUMBER, START_TIME';
        this.testTypeState = false;
        this.reverse = false;
        this.selectedName = '';
        this.view = 'Qtr To Date';
        this.offset = 0;
        this.audiData = [];
        this.filter = '';
        this.userRoleArray = [];
        this.excelData = [];
        this.totalRecords = 0;
        this.serialNoArray = [];
        this.serialNumberData = [];
        this.selectedTabLabel = 'Audi Report';
        this.createdOnDisplay = '';
        this.serialNo = '';
        this.searchSerialNo = '';
        this.searchTestType = 'Test Type';
        this.searchSerialNoArray = [];
        this.testTypeArray = ['All', 'CTO', 'SWDL', 'LICCONF'];
        this.imdbFileList = [];
        this.fileName = '';
        this.eachFileData = [];
        this.searchTestArray = ['All', 'CTO', 'SWDL', 'LICCONF'];
        this.selectedSiteNameArray = [];
        this.dateRangeBool = false;
        this.dateRangeFilter = '';
        this.dateType = '';
        this.minDate = '';
        this.maxDate = '';
    }
    public setTotalRecords(totalRecords: number) {
        this.totalRecords = totalRecords;
    }
    public setExcelData(isFirst: boolean, row: any) {
        this.excelData = isFirst ? [] : this.excelData;
        this.excelData.push(Object.assign({}, row));
    }
}
