import { Comment } from './comment.modal';
import { Header } from './header.modal';
export class OrderBacklogReportModal {
    readonly orderBacklogHeader: Header[] = [
        // {
        //     columnName: 'FIN_ORDER_NO',
        //     displayName: 'ORDER NO',
        //     columnType: 'string'
        // },
        {
            columnName: 'SHIP_COUNTRY_CODE',
            displayName: 'COUNTRY CODE',
            columnType: 'string',
            checked: false
        },
        {
            columnName: 'ORDER_DATE',
            displayName: 'ORDER DATE',
            columnType: 'number',
            checked: false
        },
        {
            columnName: 'PUSH_DATE',
            displayName: 'PUSH DATE',
            columnType: 'number',
            checked: false
        },
        {
            columnName: 'ORDER_STATUS',
            displayName: 'ORDER STATUS',
            columnType: 'string',
            checked: false
        },
        {
            columnName: 'QUOTE_NUMBER',
            displayName: 'QUOTE NUMBER',
            columnType: 'string',
            checked: false
        },
        {
            columnName: 'PO_NUMBER',
            displayName: 'PO NUMBER',
            columnType: 'number',
            checked: false
        },
        {
            columnName: 'CM_SLA_DAYS',
            displayName: 'SLA DAYS',
            columnType: 'number',
            checked: false
        }
    ];
    readonly editHeaderDetails: Header[] = [
        {
            columnName: 'CM_SLA_DAYS',
            displayName: 'SLA DAYS',
            columnType: 'number',
            checked: false
        },
        {
            columnName: 'CONFIRM_DATE',
            displayName: 'CONFIRM DATE',
            columnType: 'number',
            checked: false
        },
        {
            columnName: 'ORDER_DATE',
            displayName: 'ORDER DATE',
            columnType: 'number',
            checked: false
        },
        {
            columnName: 'PUSH_DATE',
            displayName: 'PUSH DATE',
            columnType: 'number',
            checked: false
        },
        {
            columnName: 'SHIP_METHOD',
            displayName: 'SHIP METHOD',
            columnType: 'string',
            checked: false
        },
        {
            columnName: 'CM_BOOK_DATE',
            displayName: 'BOOK DATE',
            columnType: 'number',
            checked: false
        },
        {
            columnName: 'CM_SHIP_DATE',
            displayName: 'CM SHIP DATE',
            columnType: 'number',
            checked: false
        },
        {
            columnName: 'CM_ORDER_NO',
            displayName: 'CM ORDER NO',
            columnType: 'number',
            checked: false
        },
        {
            columnName: 'DID_NO',
            displayName: 'DID NO',
            columnType: 'number',
            checked: false
        },
        {
            columnName: 'CM_TRACKING_NOS',
            displayName: 'TRACKING NOS',
            columnType: 'number',
            checked: false
        },
        {
            columnName: 'DATE_IN_SHIPPING',
            displayName: 'CM DATE IN SHIPPING',
            columnType: 'number',
            checked: false
        }
    ];
    public backlogData: any;
    public rbacklogData: any;
    public exceptionDetails: any;
    public dashboardData: any;
    public dashboardChartData: any;
    public backlogExcelData: any;
    public currentUser: any;
    public systemTypeCountData: any;
    public pieData: any;
    public dofPriorityPer: any;
    public awaitShipPer: any;
    public bookedFacPer: any;
    public inProdPer: any;
    public openOrderPer: any;
    public expediteOrderPer: any;
    public asnErrosPer: any;
    public sureShipPer: any;
    public shipQuePer: any;
    public stagedAwaitPer: any;
    public stageCustPer: any;
    public shipFactPer: any;
    public domesticOrdrsPercentage;
    public internationalOrdrsPercentage: any;
    public distPer: any;
    public fedPer: any;
    public regPer: any;
    public intPer: any;
    public status: any;
    public depotRepPer: any;
    public fruShipPer: any;
    public returnStCrPer: any;
    public rmaPer: any;
    public category: string;
    public order: string;
    public line: any;
    public backlogDataCommentRow: any;
    public reverse: boolean;
    public collapsed: boolean;
    public collapsedRowData: any;
    public selectedName: any;
    public show: boolean;
    public dataLoader: boolean;
    public countryCode = [];
    public year: number;
    public notClosed = [];
    public OL_EMAIL: string;
    public OL_ID: any;
    public userRoleList: any;
    public userAdminValue: any;
    public flg: boolean;
    public finOrderNumber: string;
    public userRoleArray: any;
    public orderStatusSearchList: any;
    public orderStatusList: any;
    public statusSearch: any;
    public flagList: any;
    public totalRecords: any;
    public orderStatusFromDb: any;
    public dynamicColumnValues: any;
    public boxData: any = [
        {
            data: []
        }
    ];
    public boxDataPercentage: any = [
        {
            data: []
        }
    ];
    public widthoftr: boolean;
    public excelData: any;
    public createdOnDisplay: any;
    public createdOnTime: string;
    public notesDate: any;
    public statusValidation: boolean;
    public commentData: Comment[];
    public OL_FIRSTNAME: string;
    public filter: any;
    public toggleFilter: any;
    public offset: any;
    public view: any;
    public orderType: any;
    public filterArray: any;
    public selectedCol: any;
    public prvRow: any;
    public codeStatus: any;
    public excelFieldStatus: any;
    public arrowOrderNo: any;
    public orderStartDate: any;
    public orderEndDate: any;
    public pushStartDate: any;
    public pushEndDate: any;
    public orderDateRangeFilter: any;
    public pushDateRangeFilter: any;
    public displayStartDate: any;
    public displayEndDate: any;
    public orderDateRangeBool: boolean;
    public pushDateRangeBool: boolean;
    public minDate: any;
    public maxDate: any;
    public dateType: string;
    constructor() {
        this.backlogData = [];
        this.order = 'ORDER_DATE DESC,SFDC_ORDER_NO DESC';
        this.reverse = true;
        this.category = 'OPEN_ORDER_EXCEPTION';
        this.line = 'LINE_NO';
        this.line = parseInt(this.line, 10);
        this.userRoleArray = [];
        this.excelData = [];
        this.backlogDataCommentRow = {};
        this.totalRecords = 0;
        this.orderStatusFromDb = [];
        this.statusValidation = true;
        this.backlogExcelData = [];
        this.dashboardChartData = [];
        this.filter = '';
        this.toggleFilter = '';
        this.offset = 0;
        this.selectedCol = [];
        this.view = 'Qtr To Date';
        this.prvRow = [];
        this.dynamicColumnValues = {};
        this.codeStatus = 0;
        this.excelFieldStatus = '';
        this.orderStartDate = '';
        this.orderEndDate = '';
        this.pushStartDate = '';
        this.pushEndDate = '';
        this.orderDateRangeFilter = '';
        this.pushDateRangeFilter = '';
        this.displayStartDate = '';
        this.displayEndDate = '';
        this.minDate = '';
        this.maxDate = '';
        this.dateType = '';
        this.orderStatusSearchList = [
            'Clear',
            'SFDC Data Pending',
            'SFDC Order Received',
            'Docs Created',
            'Docs Sent to Arrow',
            'Awaiting Priority Release',
            'Release Pause Error',
            'Released to Factory',
            'Received at Factory',
            'Booked at Factory',
            'Booked Awaiting Supply',
            'In Production',
            'Infoblox Action',
            'Arrow Action',
            'Shipping Condition Exists',
            'Shipping Queue',
            'Staged Awaiting Carrier',
            'Staged Customer Carrier',
            'ASN Created',
            'Shipped From Factory',
            'ASN Received',
            'ASN Errors',
            'BackOrdered',
            'Ship Confirmed',
            'Cancel Requested',
            'Cancelled'
        ];
        this.statusSearch = 'Order Status';
        this.orderStatusList = [
            'Docs Created',
            'Docs Sent to Arrow',
            'Ship Confirmed',
            'Hold Order',
            'Cancel Requested',
            'Hold Release'
        ];
        this.flagList = [
            'Clear',
            'Cancel',
            'Hold Order',
            'Release Pause Error',
            'Sure Ship',
            'Urgent',
            'Exceptions',
            'US',
            'Non US',
            'Over 5 SLA'
        ];
        this.commentData = [{
            userName: '',
            message: '',
            date: '',
            orderNo: ''
          }];
        this.orderType = '';
        this.filterArray = [];
        this.arrowOrderNo = null;
    }
    public setTotalRecords(totalRecords: number) {
        this.totalRecords = totalRecords;
    }
    public setExcelData(isFirst: boolean, row: any) {
        this.excelData = isFirst ? [] : this.excelData;
        this.excelData.push(Object.assign({}, row));
    }

    public setBacklogCommentRow(row) {
        this.backlogDataCommentRow = row;
    }
}
