import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { isNullOrUndefined } from 'util';
import { AdminService } from '../@core/data/admin.service';
import { RegisterService } from '../@core/data/register.service';
import { AdminModal } from '../@core/modal/admin.modal';
import { AppUtil } from '../@core/util/app.util';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  adminModalData: AdminModal = new AdminModal();
  viewAll = '1|4|16|64';
  constructor(
    public registerService: RegisterService,
    public adminService: AdminService
  ) {
    this.getRegistrationDetails();
    this.adminModalData.view = 'Admin/View';
  }

  ngOnInit() {
    this.getRoleData();
  }
  onItemSelect(item: any, row) {
    const OL_ROLE_Array = this.getRegisterData('OL_ROLE_Array', row.OL_EMAIL);
    OL_ROLE_Array.push(item.item_id);
    this.setRegisterData('OL_ROLE_Array', OL_ROLE_Array, row);
    this.setRegisterData('OL_ROLE', OL_ROLE_Array.join('|'), row);
  }
  onItemDeSelect(item: any, row) {
    const OL_ROLE_Array = this.getRegisterData('OL_ROLE_Array', row.OL_EMAIL);
    OL_ROLE_Array.splice(OL_ROLE_Array.indexOf(item.item_id), 1);
    this.setRegisterData('OL_ROLE_Array', OL_ROLE_Array, row);
    this.setRegisterData('OL_ROLE', OL_ROLE_Array.join('|'), row);
  }
  onSelectAll(items: any, row) {
    const OL_ROLE_Array = [];
    if (items.length !== 0) {
      items.forEach(item => {
        OL_ROLE_Array.push(item.item_id);
      });
    }
    this.setRegisterData('OL_ROLE_Array', OL_ROLE_Array, row);
    this.setRegisterData('OL_ROLE', OL_ROLE_Array.join('|'), row);
  }
  getRegistrationDetails() {
    this.registerService.getRegistartionDetails().subscribe(registerData => {
      this.registerService.setRegistartionDetails(registerData.resource);
    });
    this.registerService.onRegistrationDetails().subscribe(registerData => {
      if (!isNullOrUndefined(registerData)) {
        this.adminModalData.registerData = registerData;
        this.adminModalData.registerData.forEach(element => {
          element.OL_ROLE_Array = [];
          element.view = 'Admin/View All';
        });
      }
    });
  }
  getRoleData() {
    this.adminService.getRoleData().subscribe(roleData => {
      this.adminService.setRoleData(roleData.resource);
    });
    this.adminService.onRoleData().subscribe(roleData => {
      if (!isNullOrUndefined(roleData)) {
        this.adminModalData.roleData = roleData;
        this.adminModalData.dropdownList = [];
        this.adminModalData.roleData.forEach(element => {
          this.adminModalData.dropdownList.push({
            item_id: element.ROLE_ID,
            item_text: element.ROLE_DESCRIPTION
          });
        });
      }
    });
    this.adminModalData.dropdownSettings = {
      disabled: true,
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: false
    };
  }
  showSwal(message) {
    swal({
      title: 'OpsLink Message::' + '\r\n' + message.fontsize(4),
      buttonsStyling: false,
      confirmButtonClass: 'btn btn-success'
    })
      .then(function() {
        location.reload();
      })
      .catch(swal.noop);
  }
  sendEmailtoUser(email, name) {
    const encryptEmail = AppUtil.encryptData(email);
    // tslint:disable-next-line:forin
    this.adminModalData.emailData.to[0].email = email;
    this.adminModalData.emailData.subject = 'OpsLink Registration Completed.';
    this.adminModalData.emailData.body_text =
      'Hi ' +
      name +
      ',' +
      '\r\n' +
      '\r\n' +
      'You are successfully registered for OpsLink.' +
      '\r\n' +
      '\r\n' +
      'Your Username is your Infoblox email address.' +
      '\r\n' +
      '\r\n' +
      'Please click on the link below to login.' +
      '\r\n' +
      '\r\n' +
      AppUtil.getEmailChangeUrl() +
      'pages/login';
    this.registerService
      .sendEmailToAdmin(this.adminModalData.emailData)
      .subscribe(data => {
          this.adminService
          .updateStatus(
            this.adminModalData.emailData.to[0].email,
            this.getRegisterData('OL_ADMIN', email),
            this.getRegisterData('OL_ROLE', email),
            0,
            'ACTIVE'
          )
          .subscribe(() => {});
        this.showSwal('Opslink approval completed.  Approval email sent.');
      });
  }
  disapproveEmail(email, name) {
    this.adminModalData.emailData.to[0].email = email;
    this.adminModalData.emailData.subject = 'Opslink registration rejected.';
    this.adminModalData.emailData.body_text =
      'Hi ' +
      name +
      ',' +
      '\r\n' +
      '\r\n' +
      'Your registration is not approved for OpsLink.' +
      '\r\n' +
      '\r\n' +
      'Please contact Jim Moody for further information.' +
      '\r\n' +
      '\r\n';
    this.registerService
      .sendEmailToAdmin(this.adminModalData.emailData)
      .subscribe(data => {
        this.showSwal('Opslink registration rejected.');
      });
  }
  setRegisterData(columnName: string, value: any, row: any) {
    this.adminModalData.registerData.filter(
      item => item.OL_EMAIL === row.OL_EMAIL
    )[0][columnName] = value;
  }
  getRegisterData(columnName: string, emailID: string): any {
    return this.adminModalData.registerData.filter(
      item => item.OL_EMAIL === emailID
    )[0][columnName];
  }
  adminViewClick(row, view, value) {
    this.setRegisterData('view', view, row);
    const custom = value === 1 ? true : false;
    this.setRegisterData('custom_dropdown', custom, row);
    this.setRegisterData('OL_ADMIN', value, row);
  }
  getRolesForDropDown(row): any {
    const OL_ADMIN = this.getRegisterData('OL_ADMIN', row.OL_EMAIL);
    const result =
      OL_ADMIN === 2
        ? this.adminModalData.dropdownList.filter(item =>
            String(item.item_text).includes('Edit') || String(item.item_text).includes('Cost')
          )
        : this.adminModalData.dropdownList;
    return result;
  }
}
