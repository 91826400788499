import { DatePipe } from '@angular/common';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import 'chartist-plugin-axistitle';
import 'chartist-plugin-barlabels';
import 'chartist-plugin-legend';
import 'chartist-plugin-pointlabels';
import 'chartist-plugin-tooltips';
import * as CryptoJS from 'crypto-js';
import { Workbook } from 'exceljs/dist/exceljs';
import * as fs from 'file-saver';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';
import { AppConstant } from '.././constants/app.constants';
import { BacklogService } from '../data/orderBacklog.service';
import { SessionProperties } from '../session/session.properties';

export class AppUtil {
    constructor(public backlogService: BacklogService) { }
    static isNullOrUndefined(value) {
        return (value === null || value === undefined);
    }
    static getAPIUrl(): string {
        let url = '';
        switch (AppConstant.ENVIROMENT) {
            case 'PROD': url = AppConstant.PROD_API_URL; break;
            case 'UAT': url = AppConstant.PROD_API_URL; break;
            case 'DEV': url = AppConstant.DEV_API_URL; break;
            case 'LOCAL': url = AppConstant.DEV_API_URL; break;
        }
        return url;
    }

    static getAPIKey(): string {
        let apiKey = '';
        switch (AppConstant.ENVIROMENT) {
            case 'PROD': apiKey = environment.PROD_API_KEY; break;
            case 'UAT': apiKey = environment.PROD_API_KEY; break;
            case 'DEV': apiKey = environment.DEV_API_KEY; break;
            case 'LOCAL': apiKey = environment.DEV_API_KEY; break;
        }
        return apiKey;
    }
    static getProcedureBaseURL() {
        let url = '';
        switch (AppConstant.ENVIROMENT) {
            case 'PROD': url = AppConstant.PROD_PROCEDURE_API_URL; break;
            case 'UAT': url = AppConstant.PROD_PROCEDURE_API_URL; break;
            case 'DEV': url = AppConstant.DEV_PROCEDURE_API_URL; break;
            case 'LOCAL': url = AppConstant.PROD_PROCEDURE_API_URL; break;
        }
        return url;
    }

    // Method for Email

    static getEmailUrl() {
        let url = '';
        switch (AppConstant.ENVIROMENT) {
            case 'PROD': url = AppConstant.PROD_EMAIL_URL + environment.PROD_EMAIL_API_KEY; break;
            case 'UAT': url = AppConstant.PROD_EMAIL_URL + environment.PROD_EMAIL_API_KEY; break;
            case 'DEV': url = AppConstant.DEV_EMAIL_URL_DEV + environment.DEV_EMAIL_API_KEY; break;
            case 'LOCAL': url = AppConstant.PROD_EMAIL_URL + environment.PROD_EMAIL_API_KEY; break;
        }
        return url;
    }
    static getTestbloxS3Url(s3BucketService, request) {
        let url = '';
        switch (AppConstant.ENVIROMENT) {
            case 'PROD': url = AppConstant.PROD_TESTBLOX_S3_API_URL + s3BucketService + '/' + request; break;
            case 'UAT': url = AppConstant.PROD_TESTBLOX_S3_API_URL + s3BucketService + '/' + request; break;
            case 'DEV': url = AppConstant.DEV_TESTBLOX_S3_API_URL + request
                + '?api_key=' + environment.DEV_API_KEY; break;
            case 'LOCAL': url = AppConstant.PROD_TESTBLOX_S3_API_URL + s3BucketService + '/' + request; break;
        }
        return url;
    }
    static getLambdaTestbloxLogUrl() {
        let url = '';
        switch (AppConstant.ENVIROMENT) {
            case 'PROD': url = AppConstant.AWS_TESTBLOX_S3_API_URL; break;
            case 'UAT': url = AppConstant.AWS_TESTBLOX_S3_API_URL; break;
            case 'DEV': url = AppConstant.AWS_TESTBLOX_S3_API_URL; break;
            case 'LOCAL': url = AppConstant.AWS_TESTBLOX_S3_API_URL; break;
        }
        return url;
    }
    static getDownloadS3Url(s3BucketService, request) {
        const fileType = request.includes('zip') ? 'zip' : 'download';
        let url = '';
        switch (AppConstant.ENVIROMENT) {
            case 'PROD': url = AppConstant.PROD_TESTBLOX_S3_API_URL + s3BucketService + '/' + request
                + '?api_key=' + environment.PROD_API_KEY; break;
            case 'UAT': url = AppConstant.PROD_TESTBLOX_S3_API_URL + s3BucketService + '/' + request
                + '?api_key=' + environment.PROD_API_KEY; break;
            case 'DEV': url = AppConstant.DEV_TESTBLOX_S3_API_URL + request
                + '?api_key=' + environment.DEV_API_KEY; break;
            case 'LOCAL': url = AppConstant.PROD_TESTBLOX_S3_API_URL + s3BucketService + '/' + request
                + '?api_key=' + environment.PROD_API_KEY; break;
        }
        return url + '&' + fileType + '=true';
    }
    static getImdbBaseUrl(request) {
        const url = AppConstant.IMDB_API_URL + request;
        return url;
    }
    // static getUrl(tableName) {
    //     return this.getAPIUrl() + tableName + '?api_key=' + this.getAPIKey();
    // }
    static getUrl(tableName) {
        return this.getAPIUrl() + tableName;
    }
    static getBaxterUrl(procName) {
        return this.getProcedureBaseURL() + procName;
    }
    static updateCancelReqStatus(procName, orderNo) {
        return this.getProcedureBaseURL() + procName + '(' + orderNo + ')';
    }
    static deleteNotMovedData(procName, tableName, orderNo, flag) {
        return this.getProcedureBaseURL() + procName +
            '(' + tableName + ',' + orderNo + ',' + flag + ')';
    }
    static getTestbloxFailedSLNODetails(procName, serialNo, startTime, Status) {
        return this.getProcedureBaseURL() + procName + '(' + serialNo + ',' + startTime + ',' + Status + ')';
    }
    static addNewRowArrowReport(procName, data: any) {
        let url = this.getProcedureBaseURL() + procName + '(';
        data.forEach(element => url += AppUtil.isNullOrUndefined(element) || element === '' ? ' ,' : element + ',');
        url += ')';
        return url;
    }
    static searchColumnsReport(procName, tableName, columns, dates, orderType) {
        let filter = '', str = '';
        const len = columns.length;
        if (len < 10) {
            for (let i = columns.length; i < 10; i++) {
                columns[i] = ' ';
            }
        }
        str = columns.join(',');
        filter = '(' + tableName + ',' + str + ',' + dates + ',' + orderType + ')';
        return this.getProcedureBaseURL() + procName + filter;
    }
    static editColumnBacklogReport(finOrderNo, flag, columns, userId, reason, procName) {
        let filter = '';
        filter = '(' + finOrderNo + ',' + flag + ',' + columns + ',' + userId + ',' + reason + ')';
        return this.getProcedureBaseURL() + procName + filter;
    }
    static getAppUrl(tableName) {
        return this.getAPIUrl() + tableName;
    }

    static isEmptyArray(array: any) {
        return AppUtil.isNullOrUndefined(array) || array.length === 0;
    }
    static convertToJson(jsonString: string) {
        if (AppUtil.isNullOrUndefined(jsonString) || typeof jsonString !== 'string') {
            return jsonString;
        }
        try {
            return JSON.parse(jsonString);
        } catch (e) {
            return jsonString;
        }
    }

    static getHttpParams(): HttpParams {
        let param: HttpParams = new HttpParams();
        // param = param.set('api_key', this.getAPIKey());
        param = param.set('continue', 'true');
        param = param.set('include_count', 'true');
        return param;
    }
    // HEADERS
    static getHttpHeaders(): HttpHeaders {
        let header: HttpHeaders = new HttpHeaders();
        if (AppConstant.ENVIROMENT === 'PROD' || AppConstant.ENVIROMENT === 'UAT') {
            header = header.set(AppConstant.HEADER_API_KEY, environment.PROD_API_KEY);
            // header = header.set(AppConstant.HEADER_SESSION_TOKEN, AppConstant.JWT);
        } else if (AppConstant.ENVIROMENT === 'LOCAL') {
            header = header.set(AppConstant.HEADER_API_KEY, environment.PROD_API_KEY);
        } else {
            header = header.set(AppConstant.HEADER_API_KEY, environment.DEV_API_KEY);
        }
        return header;
    }
    // Methods for Prod

    static getUrlLazyLoading(offset, column_name, tableName, filter) {
        return this.getUrl(tableName)
            + '&limit=300'
            + '&offset=' + offset
            + '&filter=' + filter
            + '&order=' + column_name
            + '&continue=true&include_count=true';
    }

    static getUrlFilter(tableName, filter) {
        return this.getAPIUrl() +
            tableName +
            '?filter=' +
            filter +
            '&continue=true&api_key=' +
            this.getAPIKey();
    }

    static getUrlFieldFilter(tableName, fields, filter) {
        return (
            this.getAPIUrl() +
            tableName +
            '?fields=' +
            fields +
            '&filter=' +
            filter +
            '&continue=true&api_key=' +
            this.getAPIKey()
        );
    }

    static showSwal(title: string, fontsize: number, message: string) {
        swal({
            title: title + '\r\n' + message.fontsize(fontsize),
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-success'
        }).catch(swal.noop);
    }
    static getQuarterDate(currentDate) {
        const currentYear = currentDate.split('-')[0];
        const currentMonth = parseInt(currentDate.split('-')[1], 10);
        if (currentMonth === 2 || currentMonth === 3 || currentMonth === 4) {
            // quaterStarDate | quaterEndDate
            return currentYear + '-02-01' + '|' + currentYear + '-04-30';
        } else if (currentMonth === 5 || currentMonth === 6 || currentMonth === 7) {
            return currentYear + '-05-01' + '|' + currentYear + '-07-31';
        } else if (currentMonth === 8 || currentMonth === 9 || currentMonth === 10) {
            return currentYear + '-08-01' + '|' + currentYear + '-10-31';
        } else if (currentMonth === 11 || currentMonth === 12) {
            return currentYear + '-11-01' + '|' + (parseInt(currentYear, 10) + 1) + '-01-31';
        } else if (currentMonth === 1) {
            return (parseInt(currentYear, 10) - 1) + '-11-01' + '|' + currentYear + '-01-31';
        }
    }
    static getNextQuarterDate(currentDate) {
        const currentYear = currentDate.split('-')[0];
        const currentMonth = parseInt(currentDate.split('-')[1], 10);
        if (currentMonth === 2 || currentMonth === 3 || currentMonth === 4) {
            return currentYear + '-05-01' + '|' + currentYear + '-07-31';
        } else if (currentMonth === 5 || currentMonth === 6 || currentMonth === 7) {
            return currentYear + '-08-01' + '|' + currentYear + '-10-30';
        } else if (currentMonth === 8 || currentMonth === 9 || currentMonth === 10) {
            return currentYear + '-11-01' + '|' + (parseInt(currentYear, 10) + 1) + '-01-31';
        } else if (currentMonth === 11 || currentMonth === 12) {
            return (parseInt(currentYear, 10) + 1) + '-02-01' + '|' + (parseInt(currentYear, 10) + 1)  + '-04-30';
        } else if (currentMonth === 1) {
            return (currentYear) + '-02-01' + '|' + (currentYear) + '-04-31';
        }
    }
    static getPrvQuarterDate(currentDate) {
        const currentYear = currentDate.split('-')[0];
        const currentMonth = parseInt(currentDate.split('-')[1], 10);
        if (currentMonth === 2 || currentMonth === 3 || currentMonth === 4) {
            // quaterStarDate | quaterEndDate
            return currentYear - 1 + '-11-01' + '|' + currentYear + '-01-31';
        } else if (currentMonth === 5 || currentMonth === 6 || currentMonth === 7) {
            return currentYear + '-02-01' + '|' + currentYear + '-04-30';
        } else if (currentMonth === 8 || currentMonth === 9 || currentMonth === 10) {
            return currentYear + '-05-01' + '|' + currentYear + '-07-31';
        } else if (currentMonth === 11 || currentMonth === 12) {
            return currentYear + '-08-01' + '|' + currentYear + '-10-31';
        } else if (currentMonth === 1) {
            return (currentYear - 1) + '-08-01' + '|' + (currentYear - 1) + '-10-31';
        }
    }
    static getMonthlyDate(currentDate) {
        const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (!AppUtil.isNullOrUndefined(currentDate)) {
            const currentYear = currentDate.split('-')[0];
            const currentMonth = parseInt(currentDate.split('-')[1], 10);
            return (
                currentYear +
                '-' +
                currentMonth +
                '-01' +
                '|' +
                currentYear +
                '-' +
                currentMonth +
                '-' +
                monthDays[currentMonth]
            );
        }
    }

    static getCurrentQuater(month: number) {
        return (month - 1) / 3 + 1;
    }
    static getStackBarQuarterView(currentDate) {
        if (!AppUtil.isNullOrUndefined(currentDate)) {
            const currentYear = currentDate.split('-')[0];
            const currentMonth = parseInt(currentDate.split('-')[1], 10);
            if (currentMonth === 2 || currentMonth === 3 || currentMonth === 4) {
                return '2,' + '4,' + currentYear;
            } else if (currentMonth === 5 || currentMonth === 6 || currentMonth === 7) {
                return '5,' + '7,' + currentYear;
            } else if (currentMonth === 8 || currentMonth === 9 || currentMonth === 10) {
                return '8,' + '10,' + currentYear;
            } else if (currentMonth === 11 || currentMonth === 12) {
                return '11,' + '12,' + (currentYear);
            } else if (currentMonth === 1) {
                return '01,' + (currentYear + 1);
            }
        }
    }
    static getEmailChangeUrl(): string {
        if (String(window.location.href).includes('opslink.infoblox.com')) {
            return 'https://opslink.infoblox.com/#/';
        } else if (String(window.location.href).includes('http://opslinkdev.infoblox.com')) {
            return 'https://opslinkdev.infoblox.com/#/';
        }
        return 'http://localhost:4200/#/';
    }

    // Methods for Encryption and Decryption

    static encryptData(data) {
        try {
            return CryptoJS.AES.encrypt(
                JSON.stringify(data),
                AppConstant.encryptSecretKey
            ).toString();
        } catch (e) {
            console.log(e);
        }
    }
    static decryptData(data) {
        try {
            const bytes = CryptoJS.AES.decrypt(data, AppConstant.encryptSecretKey);
            if (bytes.toString()) {
                return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            }
            return data;
        } catch (e) {
            console.log(e);
        }
    }

    // Methods for Generating excel

    static geneRateExcelFromFile(dataFile: any[], title: string, header: string[], seperator: string, extension: string) {
        const data = [];
        data.push([]);
        dataFile.forEach(item => {
            data.push(item.split(seperator));
        });
        this.downloadExcel(data, title, header, extension);
    }
    // Generate Excel from JSON format data
    static generateExcelFromJSON(title: string, header: string[], dataArray: any[], extension: string) {
        const data = [];
        if (AppUtil.isNullOrUndefined(dataArray)) {
            return null;
        }
        dataArray.forEach(item => {
            const rowData = [];
            header.forEach(head => {
                rowData.push(item[head]);
            });
            data.push(rowData);
        });
        this.downloadExcel(data, title, header, extension);
    }
    static convertJson(jsonArray: any[], seperator: string, title: string, extension: string) {
        if (AppUtil.isNullOrUndefined(jsonArray)) {
            return null;
        }
        const rowArray = [];
        const index = [];
        jsonArray.forEach((item) => {
            Object.keys(item).forEach(key => {
                if (index.indexOf(key) === -1) {
                    index.push(key);
                }
            });
        });
        index.splice(index.indexOf('CREATED_ON'));
        jsonArray.forEach((item) => {
            let row = '';
            index.forEach(key => {
                row += AppUtil.isNullOrUndefined(item[key]) ? '' : item[key];
                row += seperator;
            });
            row = row.slice(0, row.length - 1);
            rowArray.push(row + '\r\n');
        });
        this.downloadFile(rowArray, title, extension);
    }
    static downloadExcel(data: any[], title: string, header: string[], extension: string) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(title);
        const headerRow = worksheet.addRow(header);
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'd9d9d9' },
                bgColor: { argb: 'd9d9d9' }
            };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });
        data.forEach(d => {
            worksheet.addRow(d);
        });
        worksheet.views = [
            {
                state: 'frozen',
                xSplit: 0,
                ySplit: 1,
                topLeftCell: 'G10',
                activeCell: '1'
            }
        ];
        worksheet.autoFilter = {
            from: 'A1',
            to: 'BB1',
        };
        workbook.xlsx.writeBuffer().then(sdata => {
            const blob = new Blob([sdata as BlobPart], {
                // type: AppConstant.EXCEL_TYPE
            });
            fs.saveAs(blob, title + extension);
        });
    }
    static downloadFile(data: any, title: string, extension: string) {
        const blob = new Blob(data, { type: extension });
        fs.saveAs(blob, title + extension);
    }
    // Method to change the date format

    static changeDateFormat(anyDate) {
        const datePipe = new DatePipe('en-US');
        try {
            return datePipe.transform(anyDate, 'MM/dd/yyyy');
        } catch (e) {
            return null;
        }
    }
    // Method to Split the String

    static splitString(val: string, num: number) {
        return AppUtil.isNullOrUndefined(val) ? '' : val.substring(0, num) + '...';
    }
    static getPercentage(numerator: number, denominator: number) {
        return parseFloat(String((numerator / denominator) * 100)).toFixed(2);
    }
    static formatToDollar(n, currency) {
        if (!(AppUtil.isNullOrUndefined(n))) {
            return currency + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        }
    }

    static isEmpty(value: string): boolean {
        return (AppUtil.isNullOrUndefined(value) || value === '');
    }
    static validatePassword(password) {
        const minMaxLength = /^[\s\S]{8,32}$/;
        const upper = /[A-Z]/;
        const lower = /[a-z]/;
        const number = /[0-9]/;
        const special = /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

        if (
            minMaxLength.test(password) &&
            upper.test(password) &&
            lower.test(password) &&
            number.test(password) &&
            special.test(password)
        ) {
            return true;
        }

        return false;
    }
    static getPercentages(val: number, sum: number) {
        if (sum === 0) {
            return 0;
        } else {
            return parseFloat(String((val / sum) * 100)).toFixed(2);
        }
    }
    static parseDate(str): Date {
        if (AppUtil.isNullOrUndefined(str)) {
            return null;
        }
        const mdy = str.split('/');
        return new Date(mdy[2], mdy[0] - 1, mdy[1]);
    }
    static datediff(first, second) {
        // Take the difference between the dates and divide by milliseconds per day.
        // Round to nearest whole number to deal with DST.
        return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }

    static makeStringMap(keySet: Array<any>): Map<any, string> {
        const map = new Map<any, string>();
        keySet.forEach(key => {
            map.set(key, '');
        });
        return map;
    }
    static makeMap(keySet: Array<any>): Map<any, number> {
        const map = new Map<any, number>();
        keySet.forEach(key => {
            map.set(key, 0);
        });
        return map;
    }
    static getCurrentYear() {
        return parseInt(SessionProperties.currentDate.split('-')[0], 10);
    }
    static getCurrentMonth() {
        return parseInt(SessionProperties.currentDate.split('-')[1], 10);
    }
    static makeMapStacked(len: number, keySet: Array<string>): Map<string, Array<number>> {
        const map = new Map<string, Array<number>>();
        keySet.forEach(key => {
            const initializeArray = new Array<number>(len).fill(0);
            map.set(key, initializeArray);
        });
        return map;
    }
    static startAnimationForBarChart(chart: any) {
        let seq2: number, delays2: number, durations2: number;
        seq2 = 0;
        delays2 = 5;
        durations2 = 10;
        chart.on('draw', function(data: any) {
            if (data.type === 'bar') {
                seq2++;
                data.element.animate({
                    opacity: {
                        begin: seq2 * delays2,
                        dur: durations2,
                        from: 0,
                        to: 1,
                        easing: 'ease'
                    }
                });
            }
        });
        seq2 = 0;
    }
    static generateYearArray() {
        const month = parseInt(SessionProperties.currentDate.split('-')[1], 10) - 1;
        const monthArray = Array.from(Array(12).keys()).map(i => {
            const val = (i + (3 + month - 1)) % 12;
            return val === 0 ? 12 : val;
        });
        return AppUtil.generateMonthName(monthArray);
    }
    static generateMonthName(monthArray: any) {
        const monthName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul',
            'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        let index = 0;
        const currYear = new Date().getFullYear().toString().substr(-2);
        const PrvYear = parseInt(currYear, 10) - 1;
        for (let i = 0; i < monthArray.length; i++) {
            if (monthArray[i] === 12) {
                index = i;
            }
            if (monthArray[i] >= 1 && monthArray[i] <= 12) {
                monthName.push(monthName[monthArray[i] - 1]);
            }
        }
        for (let i = 0; i < monthName.length; i++) {
            if (i <= index) {
                return monthName[i] = monthName[i] + '-' + PrvYear;
            } else {
                return monthName[i] = monthName[i] + '-' + currYear;
            }
        }
    }
    static getDateRangeValue(value: string) {
        if (value === '' || value === null) {
            return '';
        }
        return value.startsWith(' AND ') ? value : ' AND ' + value;
    }
}
