import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { AppUtil } from '../util/app.util';
import { ArrowEngagementReportModal } from './../modal/arrowEngagementReport.modal';
import { AppService } from './app.service';

@Injectable()
export class ArrowEngagementReportService {
    public arrowEngReportData;
    public arrowEngReportData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public arrowEngReportExcelData;
    public arrowEngReportExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

    constructor(public appService: AppService, public http: HttpClient) { }

    getArrowEngReportData(arrowEngReportModalData: ArrowEngagementReportModal): Observable<any> {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', arrowEngReportModalData.order);
        params = params.set('offset', arrowEngReportModalData.offset);
        params = params.set('filter', arrowEngReportModalData.filter);
        this.setDataLoader(true);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.ARROW_ENGAGEMENT_REPORT_TABLENAME),
        { params : params});
    }
    setArrowEngReportData(data: any) {
        this.arrowEngReportData = data;
        this.arrowEngReportData$.next(this.arrowEngReportData);
    }
    onArrowEngReportData() {
        return this.arrowEngReportData$.asObservable();
    }
    async getArrowEngReportExcelData(arrowEngReportModalData: ArrowEngagementReportModal) {
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('filter', arrowEngReportModalData.filter);
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.ARROW_ENGAGEMENT_REPORT_TABLENAME)
        , params);
        this.setArrowEngReportExcelData(data.body.resource);
    }
    setArrowEngReportExcelData(data: any) {
        this.arrowEngReportExcelData = data;
        this.arrowEngReportExcelData$.next(this.arrowEngReportExcelData);
    }
    onArrowEngReportExcelData() {
        return this.arrowEngReportExcelData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
    async addOrEditRow(arrowEngagementModalData: ArrowEngagementReportModal) {
        const param: HttpParams = AppUtil.getHttpParams();
        const data = [];
        const datePipe = new DatePipe('en-US');
        arrowEngagementModalData.arrowEngReportHeader.forEach(element => {
            let value = arrowEngagementModalData.tableRow[element.columnName];
            value = AppUtil.isNullOrUndefined(value) ? '' : value;
            value = String(value).split(',').join('.');
            if (element.columnName === 'ORDER_CREATION_TIMESTAMP' && value !== '') {
                value = datePipe.transform(value.split(' ')[0], 'yyyy-mm-dd') + ' ' + value.split(' ')[1];
            }
            if (value !== '' && element.checked) {
                value = String(value).split(',').join('.');
                value = String(value).split('"').join('&');
            }
            data.push(value);
        });
        this.setDataLoader(false);
        return await this.appService.checkGetMethodWithParams(AppUtil
            .addNewRowArrowReport(AppConstant.UPDATE_EDIT_ARROW_ENGAGEMENT_PROC, data), param);
    }
}
