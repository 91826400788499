export class AdminModal {
  public registerData: any;
  public roleData: any;
  public name: any;
  public view: any = 'Admin/View';
  public custom_dropdown: boolean;
  public emailData = {
    to: [
      {
        name: '',
        email: ''
      }
    ],
    subject: '',
    body_text: ''
  };
  public dropdownList = [];
  public selectedItems = [];
  public dropdownSettings = {};

  constructor() {
  }
}
