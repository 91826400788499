import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class RMADashboardService {
    public NBDCountryData;
    public NBDCountryData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public rmaTrendData;
    public rmaTrendData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public codeDescriptionData;
    public codeDescriptionData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public rmaSlaData;
    public rmaSlaData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(public appService: AppService) { }

    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }

    async getRmaTrendData() {
        this.setDataLoader(true);
        const param: HttpParams = AppUtil.getHttpParams();
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.RMA_TREND_TABLENAME), param);
        this.setRmaTrendData(data.body.resource);
        this.setDataLoader(false);
    }
    setRmaTrendData(data: any) {
        this.rmaTrendData = data;
        this.rmaTrendData$.next(this.rmaTrendData);
    }
    onRmaTrendData() {
        return this.rmaTrendData$.asObservable();
    }
    async getRmaSlaData() {
        this.setDataLoader(true);
        const param: HttpParams = AppUtil.getHttpParams();
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.RMA_SLA_VIEWNAME), param);
        this.setRmaSlaData(data.body.resource);
        this.setDataLoader(false);
    }
    setRmaSlaData(data: any) {
        this.rmaSlaData = data;
        this.rmaSlaData$.next(this.rmaSlaData);
    }
    onRmaSlaData() {
        return this.rmaSlaData$.asObservable();
    }
    async getCodeDescriptionData() {
        this.setDataLoader(true);
        const param: HttpParams = AppUtil.getHttpParams();
        const data = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.FLASH_CODE_DESCRIPTION_TABLENAME), param);
        this.setCodeDescriptionData(data.body.resource);
        this.setDataLoader(false);
    }
    setCodeDescriptionData(data: any) {
        this.codeDescriptionData = data;
        this.codeDescriptionData$.next(this.codeDescriptionData);
    }
    onCodeDescriptionData() {
        return this.codeDescriptionData$.asObservable();
    }
    async getNBDCountryData() {
        this.setDataLoader(true);
        const param: HttpParams = AppUtil.getHttpParams();
        const data = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.RMA_NBD_COUNTRY_TABLENAME), param);
        this.setNBDCountryData(data.body.resource);
        this.setDataLoader(false);
    }
    setNBDCountryData(data: any) {
        this.NBDCountryData = data;
        this.NBDCountryData$.next(this.NBDCountryData);
    }
    onNBDCountryData() {
        return this.NBDCountryData$.asObservable();
    }
}
