import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { InstallBaseModal } from '../modal/installBase.modal';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class InstallBaseService {
    public installBaseData;
    public installBaseData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public excelInstallBaseData;
    public excelInstallBaseData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public installBaseDataLazy;
    public installBaseDataLazy$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dashboardSales;
    public dashboardSales$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public installTopCountriesData;
    public installTopCountriesData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public installModalData;
    public installModalData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

    constructor(public appService: AppService,  public http: HttpClient) { }

    getInstallBaseDataLazy(installBaseModalData: InstallBaseModal): Observable<any> {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', installBaseModalData.order);
        params = params.set('offset', installBaseModalData.offset);
        params = params.set('filter', installBaseModalData.filter);
        installBaseModalData.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.INSTALLBASE_TABLENAME),
        { params : params, headers: AppUtil.getHttpHeaders()});
    }
    setInstallBaseDataLazy(data: any) {
        this.installBaseDataLazy = data;
        this.installBaseDataLazy$.next(this.installBaseDataLazy);
    }
    onInstallBaseDataLazy() {
        return this.installBaseDataLazy$.asObservable();
    }
    async getExcelInstallBaseData(installBaseModalData: InstallBaseModal) {
        this.setDataLoader(true);
        let param: HttpParams = AppUtil.getHttpParams();
        param = param.set('fields', AppConstant.INSTALL_BASE_DATA_FIELD);
        param = param.set('filter', installBaseModalData.filter);
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.INSTALLBASE_TABLENAME), param);
        this.setExcelInstallBaseData(data.body.resource);
    }
    setExcelInstallBaseData(data: any) {
        this.excelInstallBaseData = data;
        this.excelInstallBaseData$.next(this.excelInstallBaseData);
    }
    onExcelInstallBaseData() {
        return this.excelInstallBaseData$.asObservable();
    }
    async getInstallBaseData(installBaseModalData: InstallBaseModal) {
        this.setDataLoader(true);
        if (AppUtil.isNullOrUndefined(this.installBaseData)) {
            const param: HttpParams = AppUtil.getHttpParams();
            const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.INSTALLBASE_TABLENAME), param);
            this.setInstallBaseData(data.body.resource);
            installBaseModalData.IBCompleteExcelData = data.body.resource;
            this.setDataLoader(false);
        }
    }
    setInstallBaseData(data: any) {
        this.installBaseData = data;
        this.installBaseData$.next(this.installBaseData);
    }
    onInstallBaseData() {
        return this.installBaseData$.asObservable();
    }
    async getDashboardSysCount() {
        if (AppUtil.isNullOrUndefined(this.dashboardSales)) {
            this.setDataLoader(true);
            const param: HttpParams = AppUtil.getHttpParams();
            const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.DASHBOARDSALES_VIEWNAME), param);
            this.setDashboardSysCount(data.body.resource);
        }
        this.setDataLoader(false);
    }
    setDashboardSysCount(data: any) {
        this.dashboardSales = data;
        this.dashboardSales$.next(this.dashboardSales);
    }
    onDashboardSysCount() {
        return this.dashboardSales$.asObservable();
    }
    async getInstallTopCountriesData() {
        this.setDataLoader(true);
        if (AppUtil.isNullOrUndefined(this.installTopCountriesData)) {
            const param: HttpParams = AppUtil.getHttpParams();
            const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.DASHBOARD_COUNTRY), param);
            this.setInstallTopCountriesData(data.body.resource);
            this.setDataLoader(false);
        }
    }
    setInstallTopCountriesData(data: any) {
        this.installTopCountriesData = data;
        this.installTopCountriesData$.next(this.installTopCountriesData);
    }
    onInstallTopCountriesData() {
        return this.installTopCountriesData$.asObservable();
    }
    async getInstallModalData(installBaselModalData: InstallBaseModal) {
        let param: HttpParams = AppUtil.getHttpParams();
        let filter: string;
        if (AppUtil.isNullOrUndefined(installBaselModalData.partNo) || installBaselModalData.partNo === '') {
            filter = '(INSTALLED_CC=' + installBaselModalData.code + ')';
        } else if (/^([0-9]+)$/.test(installBaselModalData.partNo) || installBaselModalData.partNo === 'netMRI') {
            filter = '(INSTALLED_CC=' + installBaselModalData.code + ')and(FAMILY LIKE %' + installBaselModalData.partNo + '%)';
        } else {
            filter = '(INSTALLED_CC=' + installBaselModalData.code + ')and(PART_NO LIKE %' + installBaselModalData.partNo + '%)';
        }
        param = param.set('offset', String(installBaselModalData.offsetModal));
        param = param.set('order', String(installBaselModalData.orderModal));
        param = param.set('filter', filter);
        param = param.set('fields', 'COUNTRY_CODE, PART_NO, SERIAL_NO, INSTALL_DATE, SALES_REP, ACCOUNT_NAME');
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.INSTALLBASE_TABLENAME), param);
        installBaselModalData.offsetModal += 300;
        this.setInstallModalData(data.body.resource);
    }
    setInstallModalData(data: any) {
        this.installModalData = data;
        this.installModalData$.next(this.installModalData);
    }
    onInstallModalData() {
        return this.installModalData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
