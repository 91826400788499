import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { ComponentConstants } from '../constants/component.constants';
import { OpportunitiesModal } from '../modal/opportunities.modal';
import { SessionProperties } from '../session/session.properties';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class OpportunitiesReportService {
    constructor(public appService: AppService, public http: HttpClient) { }
    public opportunitiesDashboardDataGroupby;
    public opportunitiesDashboardDataGroupby$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public opportunitiesDashboardData;
    public opportunitiesDashboardData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public OpportunitiesExcelData;
    public OpportunitiesExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public opportunitiesData;
    public opportunitiesData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public opportunitiesRemainData;
    public opportunitiesRemainData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public oppsSummaryData;
    public oppsSummaryData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

    getFilter(view) {
        let filter;
        if (view === 'Qtr To Date' || view == null) {
            filter = '((CLOSED_DATE>='
                + SessionProperties.quaterStartDate
                + ')AND(CLOSED_DATE<='
                + SessionProperties.quaterEndDate
                + '))';
        } else if (view === 'Next Qtr') {
            filter = '((CLOSED_DATE>='
                + SessionProperties.nextQuaterStartDate
                + ')AND(CLOSED_DATE<='
                + SessionProperties.nextQuaterEndDate
                + '))';
        } else if (view === 'Complete View') {
            filter = '';
        }
        return filter;
    }
    async getOpportunitiesExcelData(opportunitiesModalData: OpportunitiesModal) {
        this.setDataLoader(true);
        const filter = this.getFilter(opportunitiesModalData.view) + opportunitiesModalData.filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('filter', filter);
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.OPPORTUNITIES_TABLENAME), params);
        this.setOpportunitiesExcelData(data.body.resource);
        this.setDataLoader(false);
    }
    setOpportunitiesExcelData(data: any) {
        this.OpportunitiesExcelData = data;
        this.OpportunitiesExcelData$.next(this.OpportunitiesExcelData);
    }
    onOpportunitiesExcelData() {
        return this.OpportunitiesExcelData$.asObservable();
    }
    getOpportunitiesData(opportunitiesModalData: OpportunitiesModal): Observable<any> {
        const filter = this.getFilter(opportunitiesModalData.view) + opportunitiesModalData.filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', opportunitiesModalData.order);
        params = params.set('offset', opportunitiesModalData.offset);
        params = params.set('filter', filter);
        if (opportunitiesModalData.selectedTabLabel === ComponentConstants.SUMMARY_VIEW
            || opportunitiesModalData.selectedTabLabel === '') {
            params = params.set('group', 'QUOTE_NO');
        }
        opportunitiesModalData.offset += 300;
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.OPPORTUNITIES_TABLENAME), {
            params: params,
            headers: AppUtil.getHttpHeaders()
        });
    }
    setOpportunitiesData(data: any) {
        this.opportunitiesData = data;
        this.opportunitiesData$.next(this.opportunitiesData);
    }
    onOpportunitiesData() {
        return this.opportunitiesData$.asObservable();
    }
    async getOpportunitiesRemainData(opportunitiesModalData: OpportunitiesModal) {
        const filter = this.getFilter(opportunitiesModalData.view) + opportunitiesModalData.filter;
        const url = AppUtil.getUrlFieldFilter(
            AppConstant.OPPORTUNITIES_TABLENAME,
            'QUOTE_NO, PART_NUMBER, QUANTITY, UNIT_PRICE, TOTAL_PRICE',
            filter) + '&order=' + opportunitiesModalData.order;
        const data: any = await this.appService.checkGetMethod(url);
        this.setOpportunitiesRemainData(data.body.resource);
        data.body.resource.forEach(element => {
            element.UNIT_PRICE = AppUtil.formatToDollar(element.UNIT_PRICE, '$ ');
            element.TOTAL_PRICE = AppUtil.formatToDollar(element.TOTAL_PRICE, '$ ');
        });
        this.setDataLoader(false);
    }
    setOpportunitiesRemainData(data: any) {
        this.opportunitiesRemainData = data;
        this.opportunitiesRemainData$.next(this.opportunitiesRemainData);
    }
    onOpportunitiesRemainData() {
        return this.opportunitiesRemainData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
