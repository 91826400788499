import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { AppConstant } from '../constants/app.constants';
import { NavbarModal } from '../modal/navBar.modal';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class NavbarService {

    public year$: Subject<any> = new Subject<any>();
    public view$: BehaviorSubject<any> = new BehaviorSubject<any>('Qtr To Date');
    public orderType$: BehaviorSubject<any> = new BehaviorSubject<any>('All');

    public notificationData;
    public notificationData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(public appService: AppService, public http: HttpClient) { }

    setyear(year) {
        this.year$.next(year);
    }
    onyear() {
        return this.year$.asObservable();
    }
    async getNotificationData(modal: NavbarModal) {
        const param: HttpParams = AppUtil.getHttpParams();
        const data = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.NOTIFICATIONDATA_VIEWNAME), param);
        modal.totalRecords = data.body.resource.length;
        this.setNotificationData(data.body.resource);
    }
    setNotificationData(data: any) {
        this.notificationData = data;
        this.notificationData$.next(this.notificationData);
    }
    onNotificationData() {
        return this.notificationData$.asObservable();
    }
    updateNotification(userId) {
        const updateNotes = {
            resource: [
                {
                    value: {
                        READ: 1,
                        USR_ID: userId
                    }
                }
            ]
        };
        return this.http.patch(AppUtil.getUrl(AppConstant.NOTIFICATIONDATA_VIEWNAME),
            updateNotes, { params: AppUtil.getHttpParams() });

    }
    setView(view) {
        this.view$.next(view);
    }

    setDefaultView() {
        this.view$.next('Qtr To Date');
    }

    onView() {
        return this.view$.asObservable();
    }
    setOrderType(orderType) {
        this.orderType$.next(orderType);
    }
    setDefaultOrderType() {
        this.orderType$.next('All');
    }
    onOrderType() {
        return this.orderType$.asObservable();
    }
}
