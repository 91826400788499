import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { SkuPartMapModal } from '../modal/skuPartMap.modal';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class SkuPartMapService {
    public partMapData;
    public partMapData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public partMapExcelData;
    public partMapExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(public appService: AppService, public http: HttpClient) { }

    getPartMapData(skuPartMapModalData: SkuPartMapModal): Observable<any> {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('offset', skuPartMapModalData.offset);
        params = params.set('filter', skuPartMapModalData.filter);
        params = params.set('order', skuPartMapModalData.order);
        skuPartMapModalData.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.SKU_PARTMAP_TABLENAME), { params : params,
            headers: AppUtil.getHttpHeaders()});
    }
    setPartMapData(data: any) {
        this.partMapData = data;
        this.partMapData$.next(this.partMapData);
    }
    onPartMapData() {
        return this.partMapData$.asObservable();
    }
    async getPartMapExcelData(skuPartMapModalData: SkuPartMapModal) {
        this.setDataLoader(true);
        let param: HttpParams = AppUtil.getHttpParams();
        param = param.set('filter', skuPartMapModalData.filter);
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.SKU_PARTMAP_TABLENAME), param);
        this.setPartMapExcelData(data.body.resource);
    }
    setPartMapExcelData(data: any) {
        this.partMapExcelData = data;
        this.partMapExcelData$.next(this.partMapExcelData);
    }
    onPartMapExcelData() {
        return this.partMapExcelData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
