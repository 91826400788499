export class LoginModal {
  public profileName: any;
  public test: Date;
  public toggleButton: any;
  public sidebarVisible: boolean;
  public nativeElement: Node;
  public isLoginValid: boolean;
  public OL_EMAIL: any;
  public OL_PASSWORD: any;
  public OL_DAILY_ATTEMPT: number;
  public OL_GROUPNAME: string;
  public emailData = {
    to: [
      {
        name: '',
        email: ''
      }
    ],
    subject: '',
    body_text: ''
  };
  public OL_ATTEMPT: number;
  public OL_STATUS: number;
  public show: boolean;
  public OTP: string;
  public userEnteredOTP: any;
  constructor() {
    this.test = new Date();
    this.OL_ATTEMPT = 0;
    this.OL_STATUS = 0;
    this.OL_DAILY_ATTEMPT = 0;
    this.userEnteredOTP = '';
    this.OTP = '';
  }
}
