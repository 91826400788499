import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { AppConstant } from '../constants/app.constants';
import { AppUtil } from '../util/app.util';
import { S3Constants } from './../constants/s3.constant';
import { TB3LogFileReportModal } from './../modal/tb3LogFileReport.modal';
import { AppService } from './app.service';

@Injectable()
export class TB3LogFileReportService {
    public tb3LogReportData;
    public tb3LogReportData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public tb3LogExcelData;
    public tb3LogExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);
    currentDate;
    constructor(public appService: AppService, public http: HttpClient) {
    }
    async getFile(fileName: string) {
        const URL = AppUtil.getTestbloxS3Url(S3Constants.TB3_BUCKET_SERVICE, S3Constants.TB3) + '/' + fileName;
        const data = await this.appService.getHTTPClient()
            .get(URL, { responseType: 'text', headers: AppUtil.getHttpHeaders()}).toPromise() as any;
        return data;
    }
    getTB3LogData(tb3LogFileModalData: TB3LogFileReportModal): Observable<any> {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', tb3LogFileModalData.order);
        params = params.set('offset', tb3LogFileModalData.offset);
        params = params.set('filter', tb3LogFileModalData.filter);
        tb3LogFileModalData.offset += 300;
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.TB3_LOG_FILE_REPORT_TABLENAME),
            { params: params, headers: AppUtil.getHttpHeaders() });
    }
    setTB3LogData(data: any) {
        this.tb3LogReportData = data;
        this.tb3LogReportData$.next(this.tb3LogReportData);
    }
    onTB3LogData() {
        return this.tb3LogReportData$.asObservable();
    }
    async getTB3LogExcelData(tb3LogFileModalData: TB3LogFileReportModal) {
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('filter', tb3LogFileModalData.filter);
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.TB3_LOG_FILE_REPORT_TABLENAME), params);
        this.setTB3LogExcelData(data.body.resource);
    }
    setTB3LogExcelData(data: any) {
        this.tb3LogExcelData = data;
        this.tb3LogExcelData$.next(this.tb3LogExcelData);
    }
    onTB3LogExcelData() {
        return this.tb3LogExcelData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
