import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { AppConstant } from '../constants/app.constants';
import { MercedesReportModal } from '../modal/mercedesReport.modal';
import { SessionProperties } from '../session/session.properties';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class MercedesReportService {
    public mercedesData;
    public mercedesData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public excelMercedesData;
    public excelMercedesData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public imdbFilesList;
    public imdbFilesList$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public imdbFilesData;
    public imdbFilesData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: Subject<any> = new Subject<any>();
    currentDate;
    constructor(public appService: AppService, public http: HttpClient) {
    }
    getFilter(view) {
        let filter;
        if (view === 'Qtr To Date' || view == null) {
            filter = '(TEST_DATE>='
                + SessionProperties.quaterStartDate
                + ')AND(TEST_DATE<='
                + SessionProperties.quaterEndDate
                + ')';
        } else if (view === 'Month To Date') {
            filter = '(TEST_DATE>='
                + SessionProperties.monthStartDate
                + ')AND(TEST_DATE<='
                + SessionProperties.monthEndDate
                + ')';
        } else if (view === 'Previous Qtr') {
            filter = '(TEST_DATE>='
                + SessionProperties.prvQtrStartDate
                + ')AND(TEST_DATE<='
                + SessionProperties.prvQtrEndDate
                + ')';
        } else if (view === 'Complete View') {
            filter = '';
        }
        return filter;
    }
    getMercedesData(mercedesModalData: MercedesReportModal): Observable<any> {
        this.setDataLoader(true);
        let filter = mercedesModalData.dateRangeBool ? '' : this.getFilter(mercedesModalData.view);
        filter += mercedesModalData.filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', mercedesModalData.order);
        params = params.set('offset', mercedesModalData.offset);
        if (mercedesModalData.dateRangeBool) {
            params = params.set('filter', mercedesModalData.filter);
        } else {
            params = params.set('filter', filter);
        }
        mercedesModalData.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.MERCEDES_REPORT_VIEWNAME),
        { params : params, headers: AppUtil.getHttpHeaders()});
    }
    setMercedesData(data: any) {
        this.mercedesData = data;
        this.mercedesData$.next(this.mercedesData);
    }
    onMercedesData() {
        return this.mercedesData$.asObservable();
    }
    async getMercedesExcelData(mercedesModalData: MercedesReportModal) {
        let filter = this.getFilter(mercedesModalData.view);
        filter += mercedesModalData.filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = mercedesModalData.dateRangeBool ? params.set('filter', mercedesModalData.filter) :
        params.set('filter', filter);
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.MERCEDES_REPORT_VIEWNAME), params);
        this.setMercedesExcelData(data.body.resource);
    }
    setMercedesExcelData(data: any) {
        this.excelMercedesData = data;
        this.excelMercedesData$.next(this.excelMercedesData);
    }
    onMercedesExcelData() {
        return this.excelMercedesData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
