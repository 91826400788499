import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class OrderManagementService {

  public tabLabel$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() {}

  setTabLabel(tabLabel) {
    this.tabLabel$.next(tabLabel);
  }
  onTabLabel() {
    return this.tabLabel$.asObservable();
  }
}
