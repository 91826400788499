import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { OpportunitiesModal } from '../modal/opportunities.modal';
import { SessionProperties } from '../session/session.properties';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class OpportunitiesDashboardService {
    constructor(public appService: AppService, public http: HttpClient) { }
    public opportunitiesDashboardDataGroupby;
    public opportunitiesDashboardDataGroupby$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public opportunitiesDashboardData;
    public opportunitiesDashboardData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public OpportunitiesExcelData;
    public OpportunitiesExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public opportunitiesData;
    public opportunitiesData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public opportunitiesRemainData;
    public opportunitiesRemainData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public oppsSummaryData;
    public oppsSummaryData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

    getFilter(view) {
        let filter;
        if (view === 'Qtr To Date' || view == null) {
            filter = '((CLOSED_DATE>='
                + SessionProperties.quaterStartDate
                + ')AND(CLOSED_DATE<='
                + SessionProperties.quaterEndDate
                + '))';
        } else if (view === 'Next Qtr') {
            filter = '((CLOSED_DATE>='
                + SessionProperties.nextQuaterStartDate
                + ')AND(CLOSED_DATE<='
                + SessionProperties.nextQuaterEndDate
                + '))';
        } else if (view === 'Complete View') {
            filter = '';
        }
        return filter;
    }
    async getOpportunitiesDashboardDataGroupby(opportunitiesModalData: OpportunitiesModal) {
        this.setDataLoader(true);
        const filter = this.getFilter(opportunitiesModalData.view);
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('filter', filter);
        params = params.set('group', 'QUOTE_NO');
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.OPPORTUNITIES_TABLENAME), params);
        this.setOpportunitiesDashboardDataGroupby(data.body.resource);
        this.setDataLoader(false);
    }
    setOpportunitiesDashboardDataGroupby(data: any) {
        this.opportunitiesDashboardDataGroupby = data;
        this.opportunitiesDashboardDataGroupby$.next(this.opportunitiesDashboardDataGroupby);
    }
    onOpportunitiesDashboardDataGroupby() {
        return this.opportunitiesDashboardDataGroupby$.asObservable();
    }
    async getOpportunitiesDashboardData(opportunitiesModalData: OpportunitiesModal) {
        this.setDataLoader(true);
        const filter = this.getFilter(opportunitiesModalData.view);
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('filter', filter);
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.OPPORTUNITIES_TABLENAME), params);
        this.setOpportunitiesDashboardData(data.body.resource);
        this.setDataLoader(false);
    }
    setOpportunitiesDashboardData(data: any) {
        this.opportunitiesDashboardData = data;
        this.opportunitiesDashboardData$.next(this.opportunitiesDashboardData);
    }
    onOpportunitiesDashboardData() {
        return this.opportunitiesDashboardData$.asObservable();
    }
    async getOpportunitiesExcel(tag: string) {
        this.setDataLoader(true);
        const filter = '(TAG LIKE \'%' + tag + '%\')';
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('filter', filter);
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.OPPS_DASHBOARD_VIEWNAME), params);
        this.setOpportunitiesExcel(data.body.resource);
        this.setDataLoader(false);
    }
    setOpportunitiesExcel(data: any) {
        this.OpportunitiesExcelData = data;
        this.OpportunitiesExcelData$.next(this.OpportunitiesExcelData);
    }
    onOpportunitiesExcel() {
        return this.OpportunitiesExcelData$.asObservable();
    }
    async getOppsSummaryDashboardData() {
        if (AppUtil.isNullOrUndefined(this.oppsSummaryData)) {
            const url = AppUtil.getUrl(
                AppConstant.OPPS_DASHBOARD_VIEWNAME
            );
            const data: any = await this.appService.checkGetMethod(url);
            this.setOppsSummaryDashboardData(data.body.resource);
            this.setDataLoader(false);
        }
    }
    setOppsSummaryDashboardData(data: any) {
        this.oppsSummaryData = data;
        this.oppsSummaryData$.next(this.oppsSummaryData);
    }
    onOppsSummaryDashboardData() {
        return this.oppsSummaryData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
