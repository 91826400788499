import { S3TestbloxModal } from '../modal/s3Testblox.modal';

export class S3Constants {
    // S3 BUCKET NAMES
    static readonly TB4_S3_BUCKET_NAME: string = 'mfglogstoretb4prod';
    static readonly TB3_S3_BUCKET_NAME: string = 'mfglogstoretb3';
    static readonly PST_S3_BUCKET_NAME: string = 'mfglogstorepst';
    static readonly TB5_S3_BACKET_NAME: string = 'mfglogstore';
    /////////////////////////////////////////////////////////////
    static readonly TEST_BUCKET_SERVICE: string = 'testbloxs3';
    static readonly TESTBLOX_BUCKET_SERVICE: string = 'prods3testblox';
    static readonly TB4_BUCKET_SERVICE: string = 'prods3tb4';
    static readonly PST_BUCKET_SERVICE: string = 'prods3pst';
    static readonly TB3_BUCKET_SERVICE: string = 'prods3tb3';
    static readonly BOOMI_FOLDER = 'BOOMI_POC';
    static readonly AUDI = 'AUDI';
    static readonly BMW = 'BMW';
    static readonly MERCEDES = 'MERCEDES';
    static readonly TESLA = 'TESLA';
    static readonly PST = 'PST';
    static readonly TB3 = 'TB3';
    static readonly SUB_DIRECTORY_NAME = ['ARR-PHOENIX', 'ARR-MEXICO', 'SMC-SANJOSE', 'SMC-TAIWAN', 'IB', 'IB-TEST'];
    static readonly ALL_SUB_DIRECTORY_NAME = ['All', 'ARR-PHOENIX', 'ARR-MEXICO', 'SMC-SANJOSE', 'SMC-TAIWAN', 'IB', 'IB-TEST'];
    static readonly ALL = 'All';
    static readonly TEST = 'Test';
    static readonly SITE = 'Site';
    // static readonly TEST_TYPE_ARRAY = [S3Constants.ALL, 'CTO', 'SWDL', 'LICCONF'];
    static readonly TEST_TYPE_ARRAY = [S3Constants.ALL, 'burnin', 'fru', 'licConfig', 'osboot', 'runin', 'stress', 'swDownload', 'functional'];
    static readonly SITE_NAME_ARRAY = [S3Constants.ALL, 'ARR-PHOENIX', 'ARR-MEXICO', 'SMC-SANJOSE', 'SMC-TAIWAN', 'IB', 'IB-TEST'];
    static getTestTypeArray (s3TestbloxModalData: S3TestbloxModal) {
        return Object.assign([], s3TestbloxModalData.searchTestArray);
    }
    static getSiteNameArray (s3TestbloxModalData: S3TestbloxModal) {
        return Object.assign([], s3TestbloxModalData.selectedSiteNameArray);
    }
}
