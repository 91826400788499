import { PlatformLocation } from '@angular/common';
import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { AppConstant } from '../@core/constants/app.constants';
import { AdminService } from '../@core/data/admin.service';
import { LoginService } from '../@core/data/login.service';
import { RegisterService } from '../@core/data/register.service';
import { AdminModal } from '../@core/modal/admin.modal';
import { LoginModal } from '../@core/modal/login.modal';
import { AppUtil } from '../@core/util/app.util';
import { AppService } from './../@core/data/app.service';
import { NavbarService } from './../@core/data/navbar.service';
declare const $: any;

// Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    roleViewId?: number;
    roleEditId?: number;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

// Menu Items
export const ROUTES: RouteInfo[] = [
    {
        path: '/dashboard',
        title: 'Main Dashboard',
        type: 'link',
        icontype: 'dashboard'
    },
    {
        path: '/mainDashboard',
        title: 'Exec Dashboard',
        type: 'link',
        icontype: 'chrome_reader_mode'
    },
    {
        path: '/sales',
        title: 'Sales',
        type: 'sub',
        icontype: 'shopping_cart',
        roleViewId: 512,
        roleEditId: 1024,
        collapse: 'sales',
        children: [
            { path: 'shipReport', title: 'Ship Report', ab: 'SR' },
            { path: 'legacyReport', title: 'Legacy Ship Report', ab: 'LSR' }
        ]
    },
    {
        path: '/order_management',
        title: 'Order Management',
        type: 'sub',
        icontype: 'library_books',
        roleViewId: 1,
        roleEditId: 2,
        collapse: 'reports',
        children: [
            { path: 'orderBacklogReport', title: 'Order Backlog Report', ab: 'OBR' },
            { path: 'arrowStatusReport', title: 'Arrow Status Report', ab: 'ASR' },
            // { path: 'arrowEngagementReport', title: 'Arrow Engagement Report', ab: 'AER' },
            { path: 'opportunitiesReport', title: 'Opportunities Report', ab: 'OR' },
            { path: 'asnErrorReport', title: 'ASN Error Report', ab: 'AER' },
            { path: 'exceptionReport', title: 'Exceptions Report', ab: 'ER' },
            { path: 'skuPartMapReport', title: 'SKU Part Map Report', ab: 'SPR' },
            { path: 'ibRegionsReport', title: 'IB Regions Report', ab: 'IRR' },
            // { path: 'orderBacklogArchiveReport', title: 'Backlog Archive Report', ab: 'BAR' }
        ]
    },
    {
        path: '/RMA',
        title: 'RMA',
        type: 'sub',
        icontype: 'tab',
        roleViewId: 2048,
        roleEditId: 4096,
        collapse: 'rma',
        children: [
            // { path: 'rmaBacklogReport', title: 'RMA BACKLOG REPORT', ab: 'RBR' },
            { path: 'flashrmaReport', title: 'FLASH RMA SHIP/RECEIVE', ab: 'FRSR' },
            { path: 'rmaReport', title: 'RMA REPORT', ab: 'RR' },
            { path: 'flashOrderHistory', title: 'FLASH ORDER HISTORY REPORT', ab: 'FOHR' },
            { path: 'flashASNErrorReport', title: 'FLASH ASN ERROR REPORT', ab: 'FAER' },
            { path: 'rmaSummaryReport', title: 'MIN QTY REPORT', ab: 'MQR' },
            { path: 'rmaInventoryReport', title: 'FLASH MIN QTY REPORT', ab: 'FMQR' }
        ]
    },
    {
        path: '/shipping',
        title: 'Shipping',
        type: 'sub',
        icontype: 'local_shipping',
        roleViewId: 8192,
        roleEditId: 16384,
        collapse: 'shipping',
        children: [
            { path: 'ssuiReport', title: 'SSUI REPORT', ab: 'SR' }
        ]
    },
    {
        path: '/inventory_management',
        title: 'Inventory Management',
        type: 'sub',
        icontype: 'assignment',
        collapse: 'Inventory Management',
        roleViewId: 16,
        roleEditId: 32,
        children: [
            {path: 'arrowInvReport', title: 'ARROW INVENTORY REPORT', ab: 'AIR'},
            {path: 'ohReport', title: 'IFX OH REPORT', ab: 'OHR'},
            {path: 'snShippedSMC', title: 'SN SHIPPED FROM SMC', ab: 'SSS'},
            {path: 'flashReport', title: 'FLASH INV REPORT', ab: 'FIR'},
            {path: 'flashInvExceptionsReport', title: 'FLASH INV EXCEPTIONS REPORT', ab: 'FIER'},
            { path: 'aeronetReport', title: 'AERONET INV REPORT', ab: 'AR' },
            {path: 'aeronetExceptionsReport', title: 'AERONET EXCEPTIONS REPORT', ab: 'AER'},
        ]
    },
    {
        path: '/install_base',
        title: 'Install Base',
        type: 'sub',
        icontype: 'portrait',
        collapse: 'Install Base',
        roleViewId: 4,
        roleEditId: 8,
        children: [
            { path: 'installBaseTable', title: 'Install Base Report', ab: 'IB' },
            { path: 'baxterReport', title: 'Baxter Report', ab: 'BR' }
        ]
    },
    {
        path: '/testblox',
        title: 'Testblox',
        type: 'sub',
        icontype: 'build',
        collapse: 'Testblox',
        roleViewId: 64,
        roleEditId: 128,
        children: [
            {path: 'testbloxExceptionReport', title: 'Testblox Exception Report', ab: 'TER'},
            {path: 'audiReport', title: 'AUDI Report', ab: 'AR'},
            {path: 'bmwReport', title: 'BMW Report', ab: 'BR'},
            {path: 'mercedesReport', title: 'MERCEDES Report', ab: 'MR'},
            {path: 'teslaReport', title: 'TESLA Report', ab: 'TR'},
            {path: 'pstReport', title: 'PST Report', ab: 'PR'},
            {path: 'tb3LogReport', title: 'TB3 Log Report', ab: 'TLR'}
        ]
    }
    // {
    //   path: '/components',
    //   title: 'Components',
    //   type: 'sub',
    //   icontype: 'apps',
    //   collapse: 'components',
    //   children: [
    //     { path: 'buttons', title: 'Buttons', ab: 'B' },
    //     { path: 'grid', title: 'Grid System', ab: 'GS' },
    //     { path: 'panels', title: 'Panels', ab: 'P' },
    //     { path: 'sweet-alert', title: 'Sweet Alert', ab: 'SA' },
    //     { path: 'notifications', title: 'Notifications', ab: 'N' },
    //     { path: 'icons', title: 'Icons', ab: 'I' },
    //     { path: 'typography', title: 'Typography', ab: 'T' }
    //   ]
    // }
    // {
    //   path: '/forms',
    //   title: 'Forms',
    //   type: 'sub',
    //   icontype: 'content_paste',
    //   collapse: 'forms',
    //   children: [
    //     { path: 'regular', title: 'Regular Forms', ab: 'RF' },
    //     { path: 'extended', title: 'Extended Forms', ab: 'EF' },
    //     { path: 'validation', title: 'Validation Forms', ab: 'VF' },
    //     { path: 'wizard', title: 'Wizard', ab: 'W' }
    //   ]
    // },
    // {
    //   path: '/tables',
    //   title: 'Tables',
    //   type: 'sub',
    //   icontype: 'grid_on',
    //   collapse: 'tables',
    //   children: [
    //     { path: 'regular', title: 'Regular Tables', ab: 'RT' },
    //     { path: 'extended', title: 'Extended Tables', ab: 'ET' },
    //     { path: 'datatables.net', title: 'Datatables.net', ab: 'DT' }
    //   ]
    // },
    // {
    //   path: '/maps',
    //   title: 'Maps',
    //   type: 'sub',
    //   icontype: 'place',
    //   collapse: 'maps',
    //   children: [
    //     { path: 'google', title: 'Google Maps', ab: 'GM' },
    //     { path: 'fullscreen', title: 'Full Screen Map', ab: 'FSM' },
    //     { path: 'vector', title: 'Vector Map', ab: 'VM' }
    //   ]
    // },
    // {
    //   path: '/widgets',
    //   title: 'Widgets',
    //   type: 'link',
    //   icontype: 'widgets'
    // },
    // {
    //   path: '/charts',
    //   title: 'Charts',
    //   type: 'link',
    //   icontype: 'timeline'
    // },
    // {
    //   path: '/calendar',
    //   title: 'Calendar',
    //   type: 'link',
    //   icontype: 'date_range'
    // },
    // {
    //   path: '/pages',
    //   title: 'Pages',
    //   type: 'sub',
    //   icontype: 'image',
    //   collapse: 'pages',
    //   children: [
    //     { path: 'pricing', title: 'Pricing', ab: 'P' },
    //     { path: 'timeline', title: 'Timeline Page', ab: 'TP' },
    //     { path: 'login', title: 'Login Page', ab: 'LP' },
    //     { path: 'register', title: 'Register Page', ab: 'RP' },
    //     { path: 'lock', title: 'Lock Screen Page', ab: 'LSP' },
    //     { path: 'user', title: 'User Page', ab: 'UP' }
    //   ]
    // }
];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public currentUserData: any;
    currentUserFirstName: any;
    currentUserLastName: any;
    public fName: string;
    OL_EMAIL: string;
    OL_ADMIN: number;
    roleList: any;
    userRoleList: any;
    userAdminValue: any;
    userRoleArray: any = [];
    cost: boolean;
    adminModalData: AdminModal = new AdminModal();
    loginModalData: LoginModal = new LoginModal();
    current_url: string;
    view = 'View';
    viewOrder = 'OrderType';
    viewTest = 'TestType';
    yearView = 'Year View';
    viewList = ['Qtr To Date', 'Previous Qtr', 'Month To Date', 'Complete View'];
    viewOrderList = ['All', ' Distributor', 'Federal', 'Regular', 'Internal'];
    viewTestList = ['All', ' Licconf', 'SWDL', 'CTO'];
    yearList = ['FY19', 'FY18', 'FY17', 'FY16', 'FY15', 'FY14', 'FY13', 'FY12', 'Complete View'];
    innerWidth: number;
    imgUrl: any;
    constructor(
        public appService: AppService,
        public loginService: LoginService,
        public cd: ChangeDetectorRef,
        public ngZone: NgZone,
        location: PlatformLocation,
        public registerService: RegisterService,
        public adminService: AdminService,
        public navbarService: NavbarService
    ) {
        this.userRoleArray = [];
        location.onPopState(() => {
            this.loginService.setBackClicked(true);
            return false;
        });
        this.getCurrentUserData();
    }
    getCurrentUserData() {
        // if (AppConstant.ENVIROMENT === 'PROD') {
        this.appService.onUserData().subscribe(currentUserData => {
            if (!AppUtil.isNullOrUndefined(currentUserData)) {
                this.currentUserFirstName = currentUserData[0].OL_FIRSTNAME;
                this.currentUserLastName = currentUserData[0].OL_LASTNAME;
                this.OL_EMAIL = currentUserData[0].OL_EMAIL;
                this.OL_ADMIN = currentUserData[0].OL_ADMIN;
                this.userAdminValue = parseInt(String(currentUserData[0].OL_ADMIN), 10);
                this.userRoleArray = AppUtil.isNullOrUndefined(currentUserData[0].OL_ROLE) ? '' :
                currentUserData[0].OL_ROLE.split('|').map(Number);
                this.imgUrl = currentUserData[0].OL_PIC;
            }
        });
        // } 
        // else {
        //     this.currentUserFirstName = AppConstant.EMAIL_ID.split('@')[0];
        //     this.OL_EMAIL = AppConstant.EMAIL_ID;
        //     this.currentUserLastName = '';
        // }
    }
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>(
                document.querySelector('.sidebar .sidebar-wrapper')
            );
            const ps = new PerfectScrollbar(elemSidebar, {
                wheelSpeed: 2,
                suppressScrollX: true
            });
        }
    }
    isMac(): boolean {
        let bool = false;
        if (
            navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
            navigator.platform.toUpperCase().indexOf('IPAD') >= 0
        ) {
            bool = true;
        }
        return bool;
    }
    isBackButton() { }
    logout() {
        localStorage.clear();
        if (this.loginService.getBackClicked()) {
            this.loginService.setBackClicked(false);
            // push current state again to prevent further attempts.
            history.pushState(null, null, location.href);
            localStorage.clear();
            return false;
        }
        return true;
    }
    checkUserViewPermission(permissionId: number) {
        if (AppUtil.isNullOrUndefined(permissionId)) {
            return true;
        }
        return (this.userAdminValue !== 0 || this.userRoleArray.includes(permissionId));
    }

    checkTestBloxUser() {
        if (AppUtil.isNullOrUndefined(this.userRoleArray)) {
            return false;
        }
        return this.userRoleArray.length === 1 && this.userRoleArray.includes(8192);
    }
    checkUserEditPermission(permissionId: number) {
        if (AppUtil.isNullOrUndefined(permissionId)) {
            return true;
        }
        return (this.userAdminValue === 1 || this.userRoleArray.includes(permissionId));
    }
    defaultOrder () {
        this.navbarService.setDefaultView();
    }
    getQuarterView() {
        this.current_url = window.location.href;
        if (
            (this.current_url.includes('sales') &&
                !this.current_url.includes('legacyReport')) ||
            this.current_url.includes('sales/shipReport') ||
            (this.current_url.includes('order_management') &&
                !this.current_url.includes('exceptionReport')) ||
            this.current_url.includes('order_management/backlogReport') ||
            this.current_url.includes('inventory_management') ||
            this.current_url.includes('testblox') ||
            this.current_url.includes('testblox/audiReport') ||
            this.current_url.includes('testblox/bmwReport') ||
            this.current_url.includes('testblox/mercedesReport') ||
            this.current_url.includes('testblox/teslaReport')
        ) {
            return true;
        }
        return false;
    }
    getOrderTypeView() {
        this.current_url = window.location.href;
        if (this.current_url.includes('sales/shipReport') || this.current_url.includes('order_management/orderBacklogReport')) {
            return true;
        }
        return false;
    }
    // getTestTypeView() {
    //     this.current_url = window.location.href;
    //     if (this.current_url.includes('testblox/audiReport') || this.current_url.includes('testblox/bmwReport')
    //     || this.current_url.includes('testblox/mercedesReport') || this.current_url.includes('testblox/teslaReport')) {
    //         return true;
    //     }
    //     return false;
    // }
    getYearView() {
        this.current_url = window.location.href;
        if (this.current_url.includes('legacyReport')) {
            return true;
        }
        return false;
    }
}
