import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { LoginModal } from '../modal/login.modal';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class LoginService {
  public loginData;
  public loginData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public appService: AppService, public http: HttpClient) {}

  getLoginDataExecutive(loginModalData: LoginModal): Observable<any> {
    let param: HttpParams = AppUtil.getHttpParams();
    if (loginModalData.OTP === '') {
      param = param.set('filter', AppConstant.LOGIN_API_URL_FILTER_EXECUTIVE
      .replace('<<replaceUserName>>', loginModalData.OL_EMAIL)
      .replace('<<replacePassword>>', loginModalData.userEnteredOTP) );
    } else {
      param = param.set('filter', AppConstant.LOGIN_API_URL_FILTER_OTP
      .replace('<<replaceUserName>>', loginModalData.OL_EMAIL)
      .replace('<<replacePassword>>', loginModalData.userEnteredOTP) );
    }
    return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.LOGIN_TABLENAME), { params: param });
    // return this.appService.getLoginExecutive(userName, OTP);
  }
  getUserData(userName): Observable<any> {
    let param: HttpParams = AppUtil.getHttpParams();
    param = param.set('filter', AppConstant.USER_DATA_API_URL_FILTER.replace('<<replaceUserName>>', userName));
    return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.LOGIN_TABLENAME), { params: param });
  }
  setLoginData(data: any) {
    this.loginData = data;
    this.loginData$.next(this.loginData);
  }
  onLoginData() {
    return this.loginData$.asObservable();
  }
  updateAdminStatusLoginTable(admin_data, userEmailId: string): Observable<any> {
    let param: HttpParams = AppUtil.getHttpParams();
    param = param.set('filter', 'OL_EMAIL=' + userEmailId);
    return this.http.patch(AppUtil.getAppUrl(AppConstant.REGISTRATION_TABLENAME), admin_data, { params: param });
  }
  updateAttempt(userEmailId, OL_ATTEMPT) {
    const updateAdminStatusBody = {
      resource: [
        {
          OL_ATTEMPT: OL_ATTEMPT
        }
      ]
    };
    return this.updateAdminStatusLoginTable(
      updateAdminStatusBody,
      userEmailId
    );
  }
  updateDailyAttempt(userEmailId, OL_DAILY_ATTEMPT) {
    const updateAdminStatusBody = {
      resource: [
        {
          OL_DAILY_ATTEMPT: OL_DAILY_ATTEMPT
        }
      ]
    };
    return this.updateAdminStatusLoginTable(
      updateAdminStatusBody,
      userEmailId
    );
  }
  updateLock(userEmailId, OL_LOCK) {
    const updateAdminStatusBody = {
      resource: [
        {
          OL_LOCK: OL_LOCK
        }
      ]
    };
    return this.updateAdminStatusLoginTable(
      updateAdminStatusBody,
      userEmailId
    );
  }
  getOLATTEMPT(): Observable<any> {
    return this.http.get(AppUtil.getUrl(AppConstant.REGISTRATION_TABLENAME));
  }
  getBackClicked() {
    return true;
  }
  setBackClicked(val) {
    val = true;
  }
  async getDailyAttempt(loginModalData: LoginModal) {
    let param: HttpParams = AppUtil.getHttpParams();
    param = param.set('fields', 'OL_DAILY_ATTEMPT');
    const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.REGISTRATION_TABLENAME), param);
    loginModalData.OL_DAILY_ATTEMPT = data.body.resource;
  }
  sendEmailForOtp(emailIdData) {
    return this.http.post(AppUtil.getEmailUrl(), emailIdData);
  }
  updateOTPinDB(userEmailId: string, OTP: string) {
    const updateLoginBody = {
      resource: [
        {
          OL_OTP: OTP
        }
      ]
    };
    return this.updateAdminStatusLoginTable(
      updateLoginBody,
      userEmailId
    );
  }
}
