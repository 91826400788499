export class ComponentConstants {
  // DashBoard Constants
  static readonly dashboardResponsiveOptionsSimpleBarChart: any = [
    [
      'screen and (max-width: 640px)',
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function(value: any) {
            return value[0];
          }
        }
      }
    ]
  ];

  // Login Component
  static readonly USER_NOT_APPROVED: string = 'User has not yet been approved by the Admin';
  static readonly USER_LOCKED: string = 'Your Account is Locked. Contact Jim Moody';
  static readonly INCORRECT_USERNAME_PASSWORD: string = 'Incorrect UserName or Password';

  // Install Base
  static readonly HEADER_ARRAY = [];

  // Legacy Report
  static readonly SUMMARY_VIEW = 'Summary View';
  static readonly DETAILED_VIEW = 'Detailed View';

}
