import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { AppUtil } from '../util/app.util';
import { CompleteReportFilter } from './../modal/completeShipReport.modal';
import { AppService } from './app.service';

@Injectable()
export class CompleteShipReportService {
    public completeData;
    public completeData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(public appService: AppService) { }

    async getCompleteShipData(completeReportFilter: CompleteReportFilter) {
        if (AppUtil.isNullOrUndefined(completeReportFilter) || AppUtil.isNullOrUndefined(completeReportFilter.partNumber)) {
            return;
        }
        let filter: string = '(PART_NO LIKE %25' + completeReportFilter.partNumber + '%25)';
        filter += AppUtil.isNullOrUndefined(completeReportFilter.orderStartDate) ? '' : 'AND(ORDER_DATE>='
            + completeReportFilter.orderStartDate + ')';
        filter += AppUtil.isNullOrUndefined(completeReportFilter.orderEndDate) ? '' : 'AND(ORDER_DATE>=' +
            completeReportFilter.orderEndDate + ')';
        const url = AppUtil.getUrlFilter(AppConstant.COMPLETE_VIEWNAME, filter);
        const data: any = await this.appService.checkGetMethod(url);
        this.setCompleteShipData(data.body.resource);
        this.setDataLoader(false);
    }
    setCompleteShipData(data: any) {
        this.completeData = data;
        this.completeData$.next(this.completeData);
    }
    onCompleteShipData() {
        return this.completeData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
