import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { AppUtil } from '../util/app.util';
import { MainDashboardModal } from './../modal/mainDashboard.modal';
import { AppService } from './app.service';

@Injectable()
export class MainDashboardService {
    public DashboardDataCount;
    public DashboardDataCount$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dashboardSales;
    public dashboardSales$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dashboardSysDetails;
    public dashboardSysDetails$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public rollingQtrBarData;
    public rollingQtrBarData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public linearityData;
    public linearityData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public doaLineChartData;
    public doaLineChartData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public rmaTrendData;
    public rmaTrendData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(public appService: AppService) { }

    async getDashboardDataCount() {
        if (AppUtil.isNullOrUndefined(this.DashboardDataCount)) {
            this.setDataLoader(true);
            const data = await this.appService.checkGetMethod(AppUtil.getUrl(AppConstant.DASHBOARD_COUNTRY));
            this.setDashboardDataCount(data.body.resource);
            this.setDataLoader(false);
        }
    }

    setDashboardDataCount(data: any) {
        this.DashboardDataCount = data;
        this.DashboardDataCount$.next(this.DashboardDataCount);
    }

    onDashboardDataCount() {
        return this.DashboardDataCount$.asObservable();
    }

    async getDashboardSales() {
        if (AppUtil.isNullOrUndefined(this.dashboardSales)) {
            this.setDataLoader(true);
            const param: HttpParams = AppUtil.getHttpParams();
            // const filter = '(FY_QTR = \'FY19Q4\')';
            // param = param.set('filter', filter);
            const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.DASHBOARDSALES_VIEWNAME), param);
            this.setDashboardSales(data.body.resource);
            this.setDataLoader(false);
        }
    }

    setDashboardSales(data: any) {
        this.dashboardSales = data;
        this.dashboardSales$.next(this.dashboardSales);
    }

    onDashboardSales() {
        return this.dashboardSales$.asObservable();
    }

    async getRollingQtrBarData() {
        if (AppUtil.isNullOrUndefined(this.rollingQtrBarData)) {
          const url = AppUtil.getUrl(AppConstant.ROLLINGBARDATA_TABLENAME);
          const data: any = await this.appService.checkGetMethod(url);
          this.setRollingQtrBarData(data.body.resource);
          this.setDataLoader(false);
        }
    }

    setRollingQtrBarData(data: any) {
        this.rollingQtrBarData = data;
        this.rollingQtrBarData$.next(this.rollingQtrBarData);
    }

    onRollingQtrBarData() {
        return this.rollingQtrBarData$.asObservable();
    }

    async getLinearityData(mainDashboradModalData: MainDashboardModal) {
        this.setDataLoader(true);
        let param: HttpParams = AppUtil.getHttpParams();
        let filter = '';
        if (mainDashboradModalData.view === 'Qtr To Date') {
            filter = '(TAG = \'CURNTQTR_RLINGAND PERF\')OR(TAG = \'CURNTQTR_LINEARITY\')OR(TAG = \'CURNTQTR_UNITSFORCAST\')';
        } else if (mainDashboradModalData.view === 'Previous Qtr') {
            filter = '(TAG = \'PRV_QTR\')OR(TAG = \'PRVQTR_UNITSFORCAST\')';
        }
        param = param.set('filter', filter);
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.LINEARITY_TABLENAME), param);
        this.setLinearityData(data.body.resource);
        this.setDataLoader(false);
    }

    setLinearityData(data: any) {
        this.linearityData = data;
        this.linearityData$.next(this.linearityData);
    }

    onLinearityData() {
        return this.linearityData$.asObservable();
    }

    async getDashboardSysDetails(modal: MainDashboardModal) {
        this.setDataLoader(true);
        let param: HttpParams = AppUtil.getHttpParams();
        let filter: string;
        if (AppUtil.isNullOrUndefined(modal.partNo) || modal.partNo === '') {
            filter = '(INSTALLED_CC=' + modal.code + ')';
        } else if (/^([0-9]+)$/.test(modal.partNo) || modal.partNo === 'netMRI') {
            filter = '(INSTALLED_CC=' + modal.code + ')and(FAMILY LIKE %' + modal.partNo + '%)';
        } else {
            filter = '(INSTALLED_CC=' + modal.code + ')and(PART_NO LIKE %' + modal.partNo + '%)';
        }
        param = param.set('offset', String(modal.offset));
        param = param.set('order', String(modal.orderModal));
        param = param.set('filter', filter);
        param = param.set('fields', 'COUNTRY_CODE, PART_NO, SERIAL_NO, INSTALL_DATE, SALES_REP, ACCOUNT_NAME');
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.INSTALLBASE_TABLENAME), param );
        modal.offset += 100;
        this.setDashboardSysDetails(data.body.resource);
        this.setDataLoader(false);
    }

    setDashboardSysDetails(data: any) {
        this.dashboardSysDetails = data;
        this.dashboardSysDetails$.next(this.dashboardSysDetails);
    }

    onDashboardSysDetails() {
        return this.dashboardSysDetails$.asObservable();
    }

    async getDoaLineChartData() {
        if (AppUtil.isNullOrUndefined(this.doaLineChartData)) {
          this.setDataLoader(true);
          const param: HttpParams = AppUtil.getHttpParams();
          const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.DOALINECHARTDATA_VIEWNAME), param);
          this.setDoaLineChartData(data.body.resource);
          this.setDataLoader(false);
        }
    }

    setDoaLineChartData(data: any) {
        this.doaLineChartData = data;
        this.doaLineChartData$.next(this.doaLineChartData);
    }

    onDoaLineChartData() {
        return this.doaLineChartData$.asObservable();
    }

    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }

    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
    async getRmaTrendData() {
        this.setDataLoader(true);
        const param: HttpParams = AppUtil.getHttpParams();
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.RMA_TREND_TABLENAME), param);
        this.setRmaTrendData(data.body.resource);
        this.setDataLoader(false);
    }

    setRmaTrendData(data: any) {
        this.rmaTrendData = data;
        this.rmaTrendData$.next(this.rmaTrendData);
    }

    onRmaTrendData() {
        return this.rmaTrendData$.asObservable();
    }
}
