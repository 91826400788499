import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AddNewUserModal } from 'src/app/@core/modal/addNewUser.modal';
import { AppUtil } from 'src/app/@core/util/app.util';
import { AddNewUserService } from '../@core/data/addNewUser.service';
@Component({
    selector: 'app-add-new-user',
    templateUrl: './add-new-user.component.html',
    styleUrls: ['./add-new-user.component.scss']
})
export class AddNewUserComponent implements OnInit {
    addNewUserModalData: AddNewUserModal = new AddNewUserModal();

    constructor(public addnewuserService: AddNewUserService,
        public router: Router,
        public _location: Location) { }

    ngOnInit() {
        this.getUserDetails();
     }
    sendEmail() {
        // tslint:disable-next-line:forin
        for (const p in this.addNewUserModalData.registerData) {
            this.addNewUserModalData.emailData.body_text =
                'Name : ' +
                this.addNewUserModalData.registerData[p].OL_FIRSTNAME +
                ' ' +
                this.addNewUserModalData.registerData[p].OL_LASTNAME +
                '\r\n' +
                'Group Name : ' +
                this.addNewUserModalData.registerData[p].OL_GROUPNAME +
                '\r\n' +
                'Email ID : ' +
                this.addNewUserModalData.registerData[p].OL_EMAIL +
                '\r\n' +
                '\r\n' +
                'Please click on the link below to Approve/Decline the request ' +
                '\r\n' +
                '\r\n' +
                AppUtil.getEmailChangeUrl() +
                'admin';
        }
        this.addnewuserService.sendEmailToAdmin(this.addNewUserModalData.emailData).subscribe(data => {
            AppUtil.showSwal('', 3, 'Email sent to the OpsLink Admin');
        });
    }
    getUserDetails() {
        this.addnewuserService.getRegistartionDetails().subscribe(registerData => {
            this.addnewuserService.setRegistartionDetails(registerData.resource);
        });
        this.addnewuserService.onRegistrationDetails().subscribe(registerData => {
            this.addNewUserModalData.registerData = registerData;
        });
    }
    checkForUserEmailIdAlreadyExists(): boolean {
        if (AppUtil.isEmptyArray(this.addNewUserModalData.registerData)) {
            return;
        } else {
            return this.addNewUserModalData.registerData.some(
                emailId => emailId.OL_EMAIL === this.addNewUserModalData.registrationDetails.resource.OL_EMAIL);
        }
    }
    register() {
        if (this.checkForUserEmailIdAlreadyExists()) {
            AppUtil.showSwal('Alert!', 3,
            'This email address already exists in OpsLink. Please contact Jim Moody for further information.');
        } else {
            this.addnewuserService.registerNewUser(this.addNewUserModalData.registrationDetails).subscribe(
                data => {
                    AppUtil.showSwal('', 3,
                        'Registration completed.  Once verified and enabled, you will receive an email confirmation.');
                    this.sendEmail();
                    this.getUserDetails();
                },
                err => {
                    if (err.status === 500) {
                        alert('Error');
                        location.reload();
                    }
                }
            );
        }
    }
    backClicked() {
        this._location.back();
    }
}
