import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { AppConstant } from '../constants/app.constants';
import { TeslaReportModal } from '../modal/teslaReport.modal';
import { SessionProperties } from '../session/session.properties';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class TeslaReportService {
    public teslaData;
    public teslaData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public excelTeslaData;
    public excelTeslaData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public imdbFilesList;
    public imdbFilesList$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public imdbFilesData;
    public imdbFilesData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: Subject<any> = new Subject<any>();
    currentDate;
    constructor(public appService: AppService, public http: HttpClient) {
    }
    getFilter (view) {
        let filter;
        if (view === 'Qtr To Date' || view == null) {
            filter = '(TEST_DATE>='
                + SessionProperties.quaterStartDate
                + ')AND(TEST_DATE<='
                + SessionProperties.quaterEndDate
                + ')';
        } else if (view === 'Month To Date') {
            filter = '(TEST_DATE>='
                    + SessionProperties.monthStartDate
                    + ')AND(TEST_DATE<='
                    + SessionProperties.monthEndDate
                    + ')';
        } else if (view === 'Previous Qtr') {
            filter = '(TEST_DATE>='
                    + SessionProperties.prvQtrStartDate
                    + ')AND(TEST_DATE<='
                    + SessionProperties.prvQtrEndDate
                    + ')';
        } else if (view === 'Complete View') {
            filter = '';
        }
        return filter;
    }
    getTeslaData (testlaModalData: TeslaReportModal): Observable<any> {
        this.setDataLoader(true);
        let filter = testlaModalData.dateRangeBool ? '' : this.getFilter(testlaModalData.view);
        filter += testlaModalData.filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', testlaModalData.order);
        params = params.set('offset', testlaModalData.offset);
        if (testlaModalData.dateRangeBool) {
            params = params.set('filter', testlaModalData.filter);
        } else {
            params = params.set('filter', filter);
        }
        testlaModalData.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.TESLA_REPORT_VIEWNAME),
        { params : params, headers: AppUtil.getHttpHeaders()});
    }
    setTeslaData(data: any) {
        this.teslaData = data;
        this.teslaData$.next(this.teslaData);
    }
    onTeslaData() {
        return this.teslaData$.asObservable();
    }
    async getTeslaExcelData(testlaModalData: TeslaReportModal) {
        let filter = this.getFilter(testlaModalData.view);
        filter += testlaModalData.filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = testlaModalData.dateRangeBool ? params.set('filter', testlaModalData.filter) :
            params.set('filter', filter);
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.TESLA_REPORT_VIEWNAME), params);
        this.setTeslaExcelData(data.body.resource);
    }
    setTeslaExcelData(data: any) {
        this.excelTeslaData = data;
        this.excelTeslaData$.next(this.excelTeslaData);
    }
    onTeslaExcelData() {
        return this.excelTeslaData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
