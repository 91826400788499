import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { PSTReportModal } from 'src/app/@core/modal/pstReport.modal';
import { AppConstant } from '../constants/app.constants';
import { AppUtil } from './../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class PSTReportService {
    public pstReportData;
    public pstReportData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public pstExcelData;
    public pstExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);
    currentDate;
    constructor(public appService: AppService, public http: HttpClient) {
    }
    getPSTData (pstReportModal: PSTReportModal): Observable<any> {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', pstReportModal.order);
        params = params.set('offset', pstReportModal.offset);
        params = params.set('filter', pstReportModal.filter);
        pstReportModal.offset += 300;
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.PST_REPORT_TABLENAME),
        { params : params, headers: AppUtil.getHttpHeaders()});
    }
    setPSTData(data: any) {
        this.pstReportData = data;
        this.pstReportData$.next(this.pstReportData);
    }
    onPSTData() {
        return this.pstReportData$.asObservable();
    }
    async getPSTExcelData(pstReportModal: PSTReportModal) {
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('filter', pstReportModal.filter);
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.PST_REPORT_TABLENAME), params);
        this.setPSTExcelData(data.body.resource);
    }
    setPSTExcelData(data: any) {
        this.pstExcelData = data;
        this.pstExcelData$.next(this.pstExcelData);
    }
    onPSTExcelData() {
        return this.pstExcelData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
