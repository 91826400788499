import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { InventoryManagementModal } from '../modal/inventoryManagement.modal';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class InventoryManagementService {
    public ohReportData;
    public ohReportData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public ohReportDataLazy;
    public ohReportDataLazy$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public ohReportExcelData;
    public ohReportExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dashboardData;
    public dashboardData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

    constructor(public appService: AppService, public http: HttpClient) { }

    async getInventoryOhReportData() {
        if (AppUtil.isNullOrUndefined(this.ohReportData)) {
            const param: HttpParams = AppUtil.getHttpParams();
            const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.INVENTORYOH_VIEWNAME), param);
            this.setInventoryOhReportData(data.body.resource);
        }
    }
    setInventoryOhReportData(data: any) {
        this.ohReportData = data;
        this.ohReportData$.next(this.ohReportData);
    }
    onInventoryOhReportData() {
        return this.ohReportData$.asObservable();
    }
    getInventoryOhReportDataLazy(inventoryModalData: InventoryManagementModal): Observable<any> {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', inventoryModalData.order);
        params = params.set('offset', inventoryModalData.offset);
        params = params.set('filter', inventoryModalData.filter);
        inventoryModalData.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.INVENTORYOH_VIEWNAME),
        { params : params, headers: AppUtil.getHttpHeaders()});
    }
    setInventoryOhReportDataLazy(data: any) {
        this.ohReportDataLazy = data;
        this.ohReportDataLazy$.next(this.ohReportDataLazy);
    }
    onInventoryOhReportDataLazy() {
        return this.ohReportDataLazy$.asObservable();
    }
    async getOhReportExcelData(filter) {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('filter', filter);
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.INVENTORYOH_VIEWNAME), params);
        this.setOhReportExcelData(data.body.resource);
        this.setDataLoader(false);
    }
    setOhReportExcelData(data: any) {
        this.ohReportExcelData = data;
        this.ohReportExcelData$.next(this.ohReportExcelData);
    }
    onOhReportExcelData() {
        return this.ohReportExcelData$.asObservable();
    }
    async getInventoryDashboardData() {
        const param: HttpParams = AppUtil.getHttpParams();
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.INVENTORYDASHBOARD_VIEWNAME), param);
        this.setInventoryDashboardData(data.body.resource);
    }
    setInventoryDashboardData(data: any) {
        this.dashboardData = data;
        this.dashboardData$.next(this.dashboardData);
    }
    onInventoryDashboardData() {
        return this.dashboardData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
