import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterPipe } from 'ngx-filter-pipe';
import { BehaviorSubject } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { SessionProperties } from '../session/session.properties';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class ArrowStatusReportService {
    public arrowStatusReportData;
    public arrowStatusReportData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public entireArrowStatusReportData;
    public entireArrowStatusReportData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);
    public orderNo$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(public appService: AppService, public http: HttpClient) { }
    getFilter() {
        let filter;
            filter = '(((ORDER_DATE>='
                + SessionProperties.quaterStartDate
                + ')AND(ORDER_DATE<='
                + SessionProperties.quaterEndDate
                + '))OR(ORDER_DATE IS NULL))AND(ORDER_CATEGORY=\'DOF\')';
        return filter;
    }
    async getArrowStatusData() {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        let filter  = this.getFilter();
        params = params.set('filter', filter);
        params =  params.set('group', 'FIN_ORDER_NO');
        params = params.set('order', 'LAST_UPDATED DESC');
        params = params.set('include_count', 'true');
        const data = await this.appService.checkGetMethodWithParams
        (AppUtil.getAppUrl(AppConstant.BACKLOG_TABLENAME), params);
        this.setDataLoader(false);
        this.setArrowStatusData(data.body.resource);
    }
    setArrowStatusData(data: any) {
        this.arrowStatusReportData = data;
        this.arrowStatusReportData$.next(this.arrowStatusReportData);
    }
    onArrowStatusData() {
        return this.arrowStatusReportData$.asObservable();
    }
    async getEntireArrowStatusData() {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        let filter  = this.getFilter();
        params = params.set('filter', filter);
        // params = params.set('filter', '(ORDER_CATEGORY=\'DOF\') AND (ORDER_STATUS != \'Closed\')');
        const data = await this.appService.checkGetMethodWithParams
        (AppUtil.getAppUrl(AppConstant.BACKLOG_TABLENAME), params);
        this.setDataLoader(false);
        this.setEntireArrowStatusData(data.body.resource);
    }
    setEntireArrowStatusData(data: any) {
        this.entireArrowStatusReportData = data;
        this.entireArrowStatusReportData$.next(this.entireArrowStatusReportData);
    }
    onEntireArrowStatusData() {
        return this.entireArrowStatusReportData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
    setOrderNo(orderNo) {
        this.orderNo$.next(orderNo);
    }

    setDefaultOrderNo() {
        // this.orderNo$.next(null);
    }

    onOrderNo() {
        return this.orderNo$.asObservable();
    }
}
