import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { MonitoringDashboardModal } from '../modal/monitoringDashboard.modal';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class MonitoringDashboardService {

    public monitoringData;
    public monitoringData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public modalData;
    public modalData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

    constructor(public appService: AppService, public http: HttpClient) { }

    async getMonitoringData(monitoringModalData: MonitoringDashboardModal) {
        this.setDataLoader(true);
        const param: HttpParams = AppUtil.getHttpParams();
        const data = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.MONITORINGDATA_TABLENAME), param);
        monitoringModalData.totalRecords = data.body.resource.length;
        this.setMonitoringData(data.body.resource);
        this.setDataLoader(false);
    }
    setMonitoringData(data: any) {
        this.monitoringData = data;
        this.monitoringData$.next(this.monitoringData);
    }
    onMonitoringData() {
        return this.monitoringData$.asObservable();
    }
    getMonitoringModalData(monitoringModalData: MonitoringDashboardModal): Observable<any> {
        this.setDataLoader(true);
        const filter = '(MOVED_FLAG=\'N\')' +  monitoringModalData.filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', monitoringModalData.order);
        params = params.set('offset', monitoringModalData.offset);
        params = params.set('filter', filter);
        params = params.set('fields', monitoringModalData.fields);
        monitoringModalData.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(monitoringModalData.tableName), { params : params});
    }
    setMonitoringModalData(data: any) {
        this.modalData = data;
        this.modalData$.next(this.modalData);
    }
    onMonitoringModalData() {
        return this.modalData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
    async deleteNotMovedData(tableName: string, orderNo: string, flag: string) {
        const param: HttpParams = AppUtil.getHttpParams();
        await this.appService.checkGetMethodWithParams(AppUtil
            .deleteNotMovedData(AppConstant.DELETE_UNMOVEDDATA_PROC, tableName, orderNo, flag), param);
    }
}
