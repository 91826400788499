export class SessionProperties {
    // SESSION TOKEN
    static JWT = '';
    static USER_EMAIL_ID = '';
    // Variables for changing the View
    static quaterStartDate: any;
    static quaterEndDate: any;
    static nextQuaterStartDate: any;
    static nextQuaterEndDate: any;
    static monthStartDate: any;
    static monthEndDate: any;
    static prvQtrStartDate: any;
    static prvQtrEndDate: any;
    static currentYear: any;
    static currentQuarterStart: any;
    static currentQuarterEnd: any;
    static currentMonth: any;
    static PrevStartMonth: any;
    static PrevEndMonth: any;
    static CURRENT_QTR: any;
    static currentDate: any;
    static currentTime: any;
    static currentTimeStamp: any;
}
