import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { FlashInventoryReportModal } from '../modal/flashInventoryReport.modal';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class FlashReportService {
    public flashReportData;
    public flashReportData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public flashExcelData;
    public flashExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

    constructor(public appService: AppService, public http: HttpClient) { }

    getFlashReportData(flashReportModalData: FlashInventoryReportModal): Observable<any> {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', flashReportModalData.order);
        params = params.set('offset', flashReportModalData.offset);
        params = params.set('filter', flashReportModalData.filter);
        flashReportModalData.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.FLASHINVENTORYREPORT_TABLENAME),
        { params : params, headers: AppUtil.getHttpHeaders()});
    }
    setFlashReportData(data: any) {
        this.flashReportData = data;
        this.flashReportData$.next(this.flashReportData);
    }
    onFlashReportData() {
        return this.flashReportData$.asObservable();
    }
    async getFlashDataExcel(filter) {
        let params: HttpParams = AppUtil.getHttpParams();
        if (AppUtil.isNullOrUndefined(this.flashExcelData)) {
            params = params.set('filter', filter);
            const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.FLASHINVENTORYREPORT_TABLENAME)
            , params);
            this.setFlashDataExcel(data.body.resource);
        }
    }
    setFlashDataExcel(data: any) {
        this.flashExcelData = data;
        this.flashExcelData$.next(this.flashExcelData);
    }
    onFlashDataExcel() {
        return this.flashExcelData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
