export class AddNewUserModal {
    public selectedGroup: any;
    public count: number;
    public profilePic: string;
    public registrationDetails = {
      resource: {
        OL_FIRSTNAME: '',
        OL_LASTNAME: '',
        OL_GROUPNAME: '',
        OL_EMAIL: '',
        OL_PASSWORD: '',
        OL_ACTION_FLAG: ''
      }
    };
    public registerData: any;
    public emailIdData = new Array();
    readonly groupList = [
      'Executive',
      'Mfg Ops Mgt',
      'Mfg Ops SC',
      'Mfg Ops TE',
      'Sales',
      'Sales Mgt',
      'Sales Ops',
      'Order Mgt',
      'Finance',
      'Other'
    ];
    readonly profilePicList = [ 'boy', 'man', 'girl', 'girl(1)', 'girl(2)', 'girl(3)'];
    public emailData = {
      to: [
        {
          name: 'Jim',
          email: 'svavhinipati@infoblox.com'
        }
      ],
      subject: 'Registration Request From OpsLink',
      body_text: '',
      from_name: 'Manufacturing Operations',
      from_email: 'mfgOps@infoblox.com'
    };

  constructor() {}
}
