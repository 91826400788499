import { Md5 } from 'ts-md5/dist/md5';
export class AppConstant {
    // HEADER FOR SESSION TOKEN
    static HEADER_SESSION_TOKEN = 'X-DreamFactory-Session-Token';
    // HADER FRO API KEY
    static HEADER_API_KEY = 'X-DreamFactory-API-Key';
    // ENVIROMENT
    // TO RUN LOCALLY USE LOCAL
    // TO RUN IN DEV USE DEV
     // TO RUN IN UAT USE UAT
    // TO RUN IN PROD USE PROD
    static ENVIROMENT = 'PROD';
    // API ACCESS CONSTANTS FOR PROD
    static EMAIL_ID = '';
    static readonly AWS_TESTBLOX_S3_API_URL = 'https://o8c94upbud.execute-api.us-east-1.amazonaws.com/v1/get-file';

    // API ACCESS CONSTANTS FOR PROD
    static readonly PROD_API_URL: string =
        'https://df-prod-lb-pub.infoblox.com/api/v2/opslinkprod/_table/';
    static readonly PROD_PROCEDURE_API_URL: string =
        'https://df-prod-lb-pub.infoblox.com/api/v2/opslinkprod/_proc/';
    static readonly PROD_TESTBLOX_S3_API_URL: string =
        'https://df-prod-lb-pub.infoblox.com/api/v2/';


    // API ACCESS CONSTANTS FOR DEV
    static readonly DEV_API_URL: string =
        'https://df-prod-lb-pub.infoblox.com/api/v2/opslinkprod/_table/';
    static readonly DEV_PROCEDURE_API_URL: string =
        'https://df-prod-lb-pub.infoblox.com/api/v2/opslinkdev/_proc/';
    static readonly DEV_TESTBLOX_S3_API_URL: string =
        'https://df-prod-lb-pub.infoblox.com/api/v2/';

    // API ACCESS CONSTANTS FOR EMAIL DEV

    static readonly DEV_EMAIL_URL_DEV: string =
        'https://df-dev-lb.infoblox.com/api/v2/regisemail?api_key=';

    // API ACCESS CONSTANTS FOR EMAIL PROD
    static readonly PROD_EMAIL_URL: string =
        'https://df-prod-lb-pub.infoblox.com/api/v2/regisemail?api_key=';


    // API ACCESS CONSTANTS FOR AWS S3 BUCKET
    static readonly IMDB_API_URL: string =
        'https://41l4fhhgge.execute-api.us-east-1.amazonaws.com/dev/';
    // OKta URL
    static readonly UAT_OKTA_URL = 'https://8wdrx0ujz5.execute-api.us-east-1.amazonaws.com/uat/authenticate';
    static readonly PROD_OKTA_URL = 'https://ywy1opwtp2.execute-api.us-east-1.amazonaws.com/prod/authenticate';
    static readonly DREAMFACTORY_URL_PROD = 'https://df-prod-lb-pub.infoblox.com/api/v2/';
    static readonly OKTA_URL: string = 'https://infoblox.oktapreview.com';
    static readonly OKTA_AUTHORIZE_DEV_URL: string = AppConstant.getDreamFactoryUrl() + 'php';
    static readonly OKTA_AUTHORIZE_URL: string = AppConstant.getDreamFactoryUrl() + 'okta_sso/sso';
    static readonly OKTA_USER_URL: string = AppConstant.getDreamFactoryUrl() + 'system/user';
    static readonly OKTA_SAML_URL: string = AppConstant.getDreamFactoryUrl() + 'okta-saml';
    static getDreamFactoryUrl() {
        return this.ENVIROMENT === 'PROD' ? this.PROD_OKTA_URL : this.UAT_OKTA_URL;
    }
    // OKTA Config
    static readonly OKTA_OPSLINK_CONFIG = {
        issuer: AppConstant.OKTA_URL,
        clientId: '0oaq8akrfvallbUmJ0h7',
        redirectUri: 'https://opslink-linux.infoblox.com',
        scopes: ['openid', 'profile', 'email'],
        response_type: 'code',
        pkce: true,
        tokenManager: {
            storage: localStorage
        }
    };

    static readonly LOGIN_API_URL_FILTER_EXECUTIVE: string =
        '(OL_EMAIL=\'<<replaceUserName>>\') AND (OL_PASSWORD=\'<<replacePassword>>\')';

    static readonly LOGIN_API_URL_FILTER_OTP: string =
        '(OL_EMAIL=\'<<replaceUserName>>\') AND (OL_OTP=\'<<replacePassword>>\')';

    static readonly USER_DATA_API_URL_FILTER: string = '(OL_EMAIL=\'<<replaceUserName>>\')';
    // Table Name
    static readonly LOGIN_TABLENAME: string = 'OL_LOGIN';
    static readonly REGISTRATION_TABLENAME: string = 'OL_LOGIN';
    static readonly SHIP_REPORT_TABLENAME: string = 'OL_SHIP_REPORT';
    static readonly INSTALLBASE_TABLENAME: string = 'IB_INSTALL_BASE';
    static readonly ADMIN_ROLE_TABLENAME: string = 'OL_ROLE_MASTER';
    static readonly BACKLOG_TABLENAME: string = 'OL_BACKLOG_REPORT';
    static readonly ORDERMASTER_TABLENAME: string = 'OL_ORDER_MASTER';
    static readonly EXCEPTION_TABLENAME: string = 'OL_EXCEPTION_REPORT';
    static readonly LEGACYSUMMARY_TABLENAME: string = 'OL_ORDER_MASTER_ARCHIVE';
    static readonly LEGACYDETAILED_TABLENAME: string = 'OL_ORDER_DETAILS_ARCHIVE';
    static readonly BACKLOGSUMMARY_TABLENAME: string = 'OL_BACKLOG_SUMMARY';
    static readonly OPPORTUNITIES_TABLENAME: string = 'OL_OPPS_MASTER';
    static readonly SHIPREMAININGDATA_TABLE: string = 'OL_ORDER_DETAILS';
    static readonly FLASHINVENTORYREPORT_TABLENAME: string = 'OL_INVENTORY_MASTER_Flash';
    static readonly STATUS_TABLENAME: string = 'OL_ORDER_STATUS';
    static readonly LINEARITY_TABLENAME: string = 'OL_LINEARITY_PWK';
    static readonly ROLLINGBARDATA_TABLENAME: string = 'OL_SYSTEM_SHIPPED_QTR';
    static readonly ARROW_NOTMOVED_TABLENAME = 'OL_ARROW_SMCARD_Staging';
    static readonly ASN_NOTMOVED_TABLENAME = 'OL_ASN_ERROR_Staging';
    static readonly FUSIONBACKLOG_NOTMOVED_TABLENAME = 'OL_FUSION_BKLOG_Staging';
    static readonly FUSIONSHIP_NOTMOVED_TABLENAME = 'OL_FUSION_SHIP_Staging';
    static readonly UNPAIDINVOICES_NOTMOVED_TABLENAME = 'OL_UNPAID_INVOICES_Staging';
    static readonly DUPLICATED_ASN_TABLENAME = 'IMDB.ORDERS';
    static readonly AERONET_REPORT_TABLENAME = 'OL_AERONET_INVENTORY';
    static readonly FLASHRMA_REPORT_TABLENAME = 'OL_RMA_RECEIPT_SHIPPING_INFO';
    static readonly RMA_TREND_TABLENAME = 'v_RMA_YM_Data';
    static readonly RMA_NOW_REPORT_TABLENAME = 'OL_RMA_DAILY_INFO';
    static readonly NEW_RMA_NOW_REPORT_TABLENAME = 'OL_RMA_DAILY_INFO_SCLOUD';
    static readonly SKU_PARTMAP_TABLENAME = 'OL_PART_MAP';
    static readonly RMA_BACKLOG_TABLENAME = 'OL_BACKLOG_REPORT_RMA';
    static readonly MONITORINGDATA_TABLENAME: string = 'OL_MONITORING_Dashboard';
    static readonly TESTBLOXDASHBOARD_TABLENAME: string = 'OL_TESTBLOX_SUMMARY';
    static readonly FLASH_CODE_DESCRIPTION_TABLENAME: string = 'OL_GLOBAL_EXCEPTIONS_FLASH';
    static readonly RMA_NBD_COUNTRY_TABLENAME: string = 'IB_REGIONS';
    static readonly ARROW_ENGAGEMENT_REPORT_TABLENAME: string = 'OL_ARROW_SMCARD_Staging';
    static readonly PST_REPORT_TABLENAME: string = 'OL_PST_SUMMARY';
    static readonly PXE_MONITORING_TABLENAME: string = 'OL_TB_PXE_MONITORING';
    static readonly SSUI_REPORT_TABLENAME: string = 'OL_SSUID_DETAIL';
    static readonly TB3_LOG_FILE_REPORT_TABLENAME: string = 'OL_TRINZIC_TESTHEADER';
    static readonly B2B_ASN_ERROR_REPORT_TABLENAME: string = 'OL_ASN_ERROR_Staging';
    static readonly FLASH_ASN_ERROR_REPORT_TABLENAME: string = 'OL_FLASH_ASN_ERRORLOG_REPORT';
    static readonly TESTBLOX_LOG_NAMES_TABLENAME: string = 'OL_TESTBLOX_S3_INDEX';
    static readonly SPHINX_LOG_NAMES_TABLENAME: string = 'OL_SPHINX_S3_INDEX';
    static readonly IB_REGIONS_TABLE_NAME = 'IB_REGIONS';
    static readonly RMA_INVENTORY_INSTALLED_TABLENAME = 'OL_RMA_FLASH_MINVAL_SUMMARY';
    static readonly RMA_INVENTORY_COUNTRY_CODE_TABLENAME = 'OL_RMA_FLASH_MINVAL_SUMMARY_CC';
    static readonly RMA_MIN_QTY_WORKAROUND_TABLENAME = 'OL_RMA_RNOW_SUMMARY';

    static readonly USER_ROLE_PERMISSION = {
        'order_management': 1,
        'install_base': 4,
        'sales': 512,
        'inventory_management': 16,
        'testblox': 64,
    };

    // DEFAULT PASSWORD
    static readonly DEFAULT_PASSWORD = Md5.hashStr('welcome123');
    // DEFAULT STATUS VALUE
    static readonly DEFAULT_STATUS = 0;
    // DEFAULT ADMIN VALUE
    static readonly DEFAULT_ADMIN_VALUE = null;
    // DEFAULT ROLE VALUE
    static readonly DEFAULT_ROLE = 0;
    // DEFAULT LOCK VALUE
    static readonly DEFAULT_LOCK = 0;
    // DEFAULT ACTION FLAG VALUE
    static readonly OL_ACTION_FLAG = 'ACTIVE';

    // Email Template Name
    static readonly EMAIL_TEMPLATE = 'APPROVAL EMAIL TEMPLATE';

    // View Name
    static readonly NOTIFICATIONDATA_VIEWNAME: string = 'v_RPE_Exception';
    static readonly SYSTEMSTYPES_VIEWNAME: string = 'v_SHIPRPORT_BY_SHPDAT';
    static readonly AUDI_REPORT_VIEWNAME: string = 'v_TESTHEADER_AUDI';
    static readonly BMW_REPORT_VIEWNAME: string = 'v_TESTHEADER_BMW';
    static readonly MERCEDES_REPORT_VIEWNAME: string = 'v_TESTHEADER_MERCEDES';
    static readonly TESLA_REPORT_VIEWNAME: string = 'v_TESTHEADER_TESLA';
    static readonly DASHBOARDSALES_VIEWNAME: string = 'v_DASHBOARD_SUMMARY';
    static readonly OPPS_DASHBOARD_VIEWNAME: string = 'v_OPPS_SUMMARY';
    static readonly LEGACY_VIEWNAME: string = 'v_LEGACY_REPORT';
    static readonly COMPLETE_VIEWNAME: string = 'v_SHIP_REPORT_MERGED';
    static readonly INVENTORYDASHBOARD_VIEWNAME: string = 'v_INVENTORY_SUMMARY';
    static readonly BACKLOGSUMMARY_VIEWNAME: string = 'v_BACKLOG_SUMMARY';
    static readonly NEW_BACKLOGSUMMARY_VIEWNAME: string = 'v_BACKLOG_SUMMARY_New';
    static readonly LEGACYDASHBOARD_VIEWNAME: string = 'v_LEGACY_SUMMARY';
    static readonly DASHBOARD_COUNTRY: string = 'v_INSTALL_SUMMARY';
    static readonly DOALINECHARTDATA_VIEWNAME: string = 'v_QUALITY_MATRICES';
    static readonly CURENTDATE_TIME_VIEWNAME: string = 'v_QTR_TIME';
    static readonly SNSHIPPED_VIEWNAME: string = 'v_SHIP_SMC';
    static readonly BACKLOG_ARCHIVE_VIEWNAME = 'v_BACKLOG_Archive';
    static readonly INVENTORYOH_VIEWNAME: string = 'v_INVENTORY_MASTER';
    static readonly FLASHINV_EXCEPTIONS_REPORT_TABLENAME: string = 'v_EXCEPTION_FLASH';
    static readonly FLASH_EXCEPTION_VIEWNAME = 'v_AERONET_EXCEPTION';
    static readonly ARROW_INV_REPORT_VIEWNAME = 'v_INVENTORY_Arrow';
    static readonly FLASH_ORDER_HISTORY_VIEWNAME = 'v_ORDER_HISTORY';
    static readonly RMA_SLA_VIEWNAME = 'v_RMA_SLA';
    static readonly TEXTBLOX_EXCEPTION_REPORT_VIEWNAME: string = 'v_TB_EXCEPTION_REPORT';
    static readonly SHIPPING_DASHBOARD_VIEWNAME: string = 'v_SSUI_DASHBOARD';
    static readonly MIN_QTY_INTSALLED_COUNTRY_VIEWNAME = 'v_MIN_QTY';
    static readonly MIN_QTY_COUNTRY_CODE_VIEWNAME = 'v_MIN_QTY_CC';

    // Procedures Name

    static readonly BAXTERREPORT_PROCEDURENAME: string = 'proc_BAXTER_REPORT';
    static readonly CANCEL_REQUEST_PROC_NAME = 'proc_ORDER_CANCEL';
    static readonly DELETE_UNMOVEDDATA_PROC = 'proc_data_del';
    static readonly SEARCH_COLUMNS_PROC = 'proc_data_download';
    static readonly EDITCOLUMN_BACKLOG_PROC = 'proc_data_edit';
    static readonly TESTBLOX_FAILED_SLNO_PROCEDURENAME: string = 'proc_TESTBLOX_FAILED';
    static readonly UPDATE_EDIT_ARROW_ENGAGEMENT_PROC: string = 'Proc_Arrow_insert';


    // Label Constant
    static readonly labelForBarChart = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    static readonly labelLegacyBarChart = ['FY12', 'FY13', 'FY14', 'FY15', 'FY16', 'FY17', 'FY18', 'FY19'];

    // Encryption Key
    static readonly encryptSecretKey = 'xyz';

    // Encryption Key
    static tabIndex = false;

    // ADMIN EMAIL ID
    static readonly EMAILID_ADMIN = 'jmoody@infoblox.com';

    // Excel
    static readonly EXCEL_TYPE = 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet;charset=UTF-8';

    // Filter for Ship Report

    static readonly SHIP_TABLE_FILTER =
        ' AND (FIN_ORDER_NO LIKE %<<fin order no>>%) AND '
        // + '(SFDC_ORDER_NO LIKE %<<sfdc order no>>%) AND '
        + '(QUOTE_NUMBER LIKE %<<quote number>>%) AND '
        + '(PO_NUMBER LIKE %<<po number>>%) AND '
        + '(REGION LIKE %<<region>>%) AND '
        + '(SALES_REP LIKE %<<sales rep>>%) AND '
        + '(SHIP_TO_CUSTOMER LIKE %<<ship to customer>>%) AND '
        + '(ORDER_STATUS LIKE %<<order status>>%)';

    // Filter for Order Backlog Report
    static readonly BACKLOG_TABLE_FILTER1 =
        ' AND (FIN_ORDER_NO LIKE %<<order no>>%) AND '
        + '(SHIP_COUNTRY_CODE LIKE %<<country code>>%) AND '
        // + '(ORDER_STATUS LIKE %<<order status>>%) AND '
        + '((QUOTE_NUMBER LIKE %<<quote number>>%)) AND '
        + '((PO_NUMBER LIKE %<<po number>>%)) AND '
        + '((CM_SLA_DAYS LIKE %<<sla days>>%))';
    static readonly BACKLOG_TABLE_FILTER2 =
        ' AND (FIN_ORDER_NO LIKE %<<order no>>%) AND '
        + '(SHIP_COUNTRY_CODE LIKE %<<country code>>%) AND '
        // + '(ORDER_STATUS LIKE %<<order status>>%) AND '
        + '((QUOTE_NUMBER LIKE %<<quote number>>%) OR (QUOTE_NUMBER IS NULL)) AND '
        + '((PO_NUMBER LIKE %<<po number>>%) OR (PO_NUMBER IS NULL)) AND '
        + '((CM_SLA_DAYS LIKE %<<sla days>>%) OR (CM_SLA_DAYS IS NULL))';

    // Arrow Engagement Report
    static readonly ARROW_ENG_TABLEFILTER = '(CUS_PO_NO LIKE %<<b2b order no>>%) AND '
        + '(ORDER_NO LIKE %<<arrow order no>>%) AND '
        + '(DID_NO LIKE %<<did #>>%) AND '
        + '(PROD_STATUS LIKE %<<production status>>%) AND '
        + '(URGENT LIKE %<<urgent>>%) AND '
        + '(SURESHIP LIKE %<<sureship>>%) AND '
        + '(MVD_SHIP_QUEUE LIKE %<<in shipping queue>>%) AND '
        + '(TRACKING_NO LIKE %<<tracking no>>%) AND '
        + '(REFRESH_UPDATE LIKE %<<daily refresh update>>%) AND '
        + '(INFOBLOX_NOTES LIKE %<<infoblox notes>>%)';

    // Filter for SKU PART MAP Report
    static readonly SKU_PART_MAP_TABLEFILTER = '(PART_NO LIKE %<<part no>>%)AND'
        + '(SKU LIKE %<<sku>>%)AND'
        + '(FAMILY LIKE %<<family>>%)AND'
        + '(SW LIKE %<<sw>>%)AND'
        + '(HDD LIKE %<<hdd>>%)AND'
        + '(POWER LIKE %<<power>>%)AND'
        + '(CARD LIKE %<<card>>%)';

    // Filter for Legacy Summary Report
    static readonly LEGACYSUMMARY_HEADERS = [
        'ORDER NO',
        'ORDER TYPE',
        'SHIP TO CUSTOMER',
        'COUNTRY',
        'PART NO',
        'SERIAL NO',
    ];
    static readonly LEGACYSUMMARY_TABLE_FILTER =
        ' AND (ORDER_NO LIKE %<<order no>>%) AND ' +
        '(ORDER_TYPE LIKE %<<order type>>%) AND ' +
        '(SHIP_TO_CUSTOMER LIKE %<<ship to customer>>%) AND ' +
        '(COUNTRY LIKE %<<country>>%) AND ' +
        '(PART_NO LIKE %<<part no>>%) AND ' +
        '(SERIAL_NOS LIKE %<<serial no>>%)';

    // Filter for Opportunity Summary Report
    static readonly OPPORTUNITY_TABLE_FILTER =
        ' AND (QUOTE_NO LIKE %<<quote no>>%) AND '
        + '(OWNER_GEO LIKE %<<owner geo>>%) AND '
        + '(OWNER LIKE %<<owner>>%) AND '
        + '(OPP_TYPE LIKE %<<opp type>>%) AND '
        + '(STAGE LIKE %<<stage>>%) AND '
        + '(OPPS_NAME LIKE %<<opps name>>%) AND '
        + '(OWNER_REGION LIKE %<<owner region>>%) AND '
        + '(PROJECT_DRIVER LIKE %<<project driver>>%) AND '
        + '(PART_NUMBER LIKE %<<part number>>%) AND '
        + '((CONTRACT_NO LIKE %<<po number>>%) OR (CONTRACT_NO IS NULL)) AND '
        + '(ACCOUNT_NAME LIKE %<<account name>>%)';
    // '(PART_NUMBER LIKE %<<part number>>%)' ;

    // Filter for B2B ASN Error Report
    static readonly B2B_ASN_ERROR_FILTER =
        '(B2B_ORDER_NUMBER LIKE %<<order no>>%) AND '
        + '(SHIPMENT_NUMBER LIKE %<<tracking no>>%) AND '
        + '(HAWB LIKE %<<waybill>>%) AND '
        // + '(LINES_DEL_DETAIL_ID LIKE %<<uspo>>%) AND '
        + '(ITEM_NUMBER LIKE %<<item no>>%) AND '
        + '((SERIAL_NUMBER LIKE %<<serial no>>%) OR (SERIAL_NUMBER IS NULL)) AND '
        // + '(SHIPPED_QUANTITY LIKE %<<ship qty>>%) AND '
        + '(ERROR_MESSAGE LIKE %<<error message>>%)';

    // fIELDS for B2B ASN Error Report
    static readonly B2B_ASN_ERROR_REPORT_FIELDS =
        'LAST_UPDATE_DATE,B2B_ORDER_NUMBER,ITEM_NUMBER,SERIAL_NUMBER,DELIVERY_DETAIL_ID,SHIPMENT_NUMBER'
        + ',HAWB,ERROR_MESSAGE,BATCH_NAME,CREATION_DATE,DATE_RANGE,ESTIMATED_DATE_OF_ARRIVAL,MAWB,MESSAGE_ID'
        + ',NO_OF_RECORDS,RECEIPT_DATE,SERIAL_QUANTITY,SHIPMENT_DATE,SHIPPED_QUANTITY,STATUS,TRANSACTION_ID'
        + ',CREATED_ON,MOVED_FLAG';

    // Filter for Install Base Component
    static readonly INSTALL_BASE_HEADERS = [
        'ACCOUNT NAME',
        'PART NO',
        'SERIAL NO',
        'COUNTRY CODE',
        'QUOTE NO',
        'FIN ORDER NO'
    ];
    static readonly INSTALL_TABLE_FILTER =
        ' AND (ACCOUNT_NAME LIKE %<<account name>>%) AND '
        + '(PART_NO LIKE %<<part no>>%) AND '
        + '(SERIAL_NO LIKE %<<serial no>>%) AND '
        + '(COUNTRY_CODE LIKE %<<country code>>%) AND '
        + '(QUOTE_NO LIKE %<<quote no>>%) AND '
        + '(FIN_ORDER_NO LIKE %<<fin order no>>%)';

    // Filter for Inventory Oh Report
    static readonly INVENTORYOH_TABLE_FILTER =
        '(ORGANISATION LIKE  %<<org>>%) AND '
        + '(ITEM_NAME LIKE  %<<item>>%) AND '
        + '(SKU LIKE  %<<sku>>%) AND '
        + '(PLATFORM_NAME LIKE  %<<platform name>>%) AND '
        + '(LOCATION LIKE  %<<location>>%) AND '
        + '(PRODUCT_TYPE LIKE  %<<product type>>%) AND '
        + '(SERIAL_NO LIKE  %<<serial no>>%) AND '
        + '(SUB_INV LIKE  %<<sub inv>>%)';
    // '((QTY is NULL) OR (QTY = <<qty>>)) AND ' +
    // '((RECEIPT_DATE is NULL) OR (RECEIPT_DATE = <<receipt date>>)) AND ' +

    // Arrow Inventory Report Filter
    static readonly ARROW_INV_TABLE_FILTER =
        ' AND (BASE_PART_NUMBER LIKE  %<<base part no>>%) AND '
        + '(INFX_PART_NUMBER LIKE  %<<infx part no>>%) AND '
        + '(MANUFACTURER LIKE  %<<manufacturer>>%) AND '
        + '(ITEM LIKE  %<<item>>%) AND '
        + '(CAT_ID LIKE  %<<cat id>>%) AND '
        + '(FREE_ON_HAND LIKE  %<<free on hand>>%) AND '
        + '(SELLABLE_ON_HAND LIKE  %<<sellable on hand>>%)';


    // Filter for Flash Inventory Report
    static readonly FLASHINVENTORY_TABLE_FILTER =
        '(Warehouse LIKE %<<warehouse>>%) AND '
        + '(Country LIKE %<<country>>%) AND '
        + '(FLASH_ID LIKE %<<flash id>>%) AND '
        + '(PART_NO LIKE %<<part no>>%) AND '
        + '(SERIAL_NO LIKE %<<serial no>>%) AND '
        + '(BIN LIKE %<<bin>>%) AND '
        + '(ORDER_NO LIKE %<<committed to order no>>%) AND '
        + '(INTRANSIT LIKE %<<intransit>>%) AND '
        + '(DEST_WAREHOUSE LIKE %<<dest warehouse>>%)';

    // Filter for Flash Inventory Exceptions Report
    static readonly FLASHINV_EXC_TABLE_FILTER =
        '(Warehouse LIKE %<<warehouse>>%) AND '
        + '(Country LIKE %<<country>>%) AND '
        + '(FLASH_ID LIKE %<<flash id>>%) AND '
        + '(PART_NO LIKE %<<part no>>%) AND '
        + '(SERIAL_NO LIKE %<<serial no>>%) AND '
        + '(TAG LIKE %<<reason>>%)';
    // + '(DEST_WAREHOUSE LIKE %<<dest warehouse>>%)';

    // Filter forSN Shipped SMC Report
    static readonly SNSHIPPED_TABLE_FILTER =
        '(BLANKET_PO LIKE %<<blanket po>>%) AND '
        + '(RELEASE_NO LIKE %<<release no>>%) AND '
        + '(IFX_SERIAL_NUMBER LIKE %<<serial no>>%) AND '
        + '(HWS_SERIAL_NUMBER LIKE %<<hw serial no>>%)';


    // Filter for Inventory Oh Report
    static readonly AERONET_REPORT_FILTER =
        '(ITEM_NO LIKE %<<item no>>%) AND '
        + '(DESCRIPTION LIKE %<<description>>%) AND '
        + '(SERIAL_NO LIKE %<<serial no>>%) AND '
        + '(QTY_ON_HAND LIKE %<<qty on hand>>%) AND '
        + '(LOCATION LIKE %<<location>>%) AND '
        + '(TAG LIKE %<<tag>>%) AND '
        + '(REASON LIKE %<<reason>>%)';

    // Filter for TESTBLOX EXCEPTION Report
    static readonly TESTBLOX_EXCEPTION_TABLE_FILTER = '(SERIAL_NUMBER LIKE %<<serial number>>%) AND '
        + '(FAMILY LIKE %<<family>>%) AND '
        + '(PART_NUMBER LIKE %<<part number>>%) AND '
        + '(SOFTWARE_SKU LIKE %<<software sku>>%) AND '
        + '(HWS LIKE %<<hws>>%) AND '
        + '(SITE_NAME LIKE %<<site name>>%) AND '
        + '(TEST_TYPE LIKE %<<test type>>%) AND '
        + '(TEST_STATUS LIKE %<<test status>>%)';

    // Filter for Audi Report
    static readonly AUDI_TABLE_FILTER =
        ' AND (SERIAL_NUMBER LIKE %<<serial number>>%) AND '
        + '(PART_NUMBER LIKE %<<part number>>%) AND '
        // + '(SOFTWARE_SKU LIKE %<<software sku>>%) AND '
        + '(HWS LIKE %<<hws>>%) AND '
        + '(SITE_NAME LIKE %<<site name>>%) AND '
        + '(TEST_DATE LIKE %<<test date>>%) AND '
        + '(TEST_TYPE LIKE %<<test type>>%) AND '
        + '(TEST_STATUS LIKE %<<test status>>%)';

    // Filter for BMW Report
    static readonly BMW_TABLE_FILTER =
        ' AND (SERIAL_NUMBER LIKE %<<serial number>>%) AND '
        + '(PART_NUMBER LIKE %<<part number>>%) AND '
        // + '(SOFTWARE_SKU LIKE %<<software sku>>%) AND '
        + '(HWS LIKE %<<hws>>%) AND '
        + '(SITE_NAME LIKE %<<site name>>%) AND '
        + '(TEST_DATE LIKE %<<test date>>%) AND '
        + '(TEST_TYPE LIKE %<<test type>>%) AND '
        + '(TEST_STATUS LIKE %<<test status>>%)';

    // Filter for PST Report
    static readonly PST_TABLE_FILTER =
        '(PLATFORM!=\'INVALID\') AND '
        + '(IBX_SKU LIKE %<<system sku>>%) AND '
        + '(PLATFORM LIKE %<<platform>>%) AND '
        + '(OEM_PN LIKE %<<part no>>%) AND '
        + '(OEM_sysSN LIKE %<<serial no>>%) AND '
        + '(System_SN LIKE %<<hardware sn>>%) AND '
        + '(Test_Result LIKE %<<test result>>%) AND '
        + '(Start_Time LIKE %<<start time>>%) AND '
        + '(Failure_Code LIKE %<<failure code>>%) AND '
        + '(File_Name LIKE %<<file name>>%)';

    // Filter for TB3 LOG FILE Report
    static readonly TB3_LOG_FILES_FILTER =
        // '(CUR_DATE_TIME %<<test date/time>>%) AND '
        '(UUT_SL_NO LIKE %<<serial no>>%) AND '
        + '(UUT_MODEL_NO LIKE %<<model no>>%) AND '
        + '(UUT_PART_NO LIKE %<<part no>>%) AND '
        + '(TEST_STEP_NAME LIKE %<<test type>>%) AND '
        + '((OS LIKE %<<os ver>>%) OR (OS IS NULL)) AND '
        + '(TESTBLOX_REV_NO LIKE %<<testblox rev>>%) AND '
        + '(INI_FILE_REV_NO LIKE %<<ini file rev>>%) AND '
        + '(TEST_RESULT LIKE %<<result>>%) AND '
        + '((ERR_CODE LIKE %<<error code>>%) OR (ERR_CODE IS NULL)) AND '
        + '(FILENAME LIKE %<<filename>>%)';


    // Monitoring Dashboard Arraw Smartcard Filter
    // Filter for Monitoring Not Moved Data Report
    static readonly ARROWSMARTCARD_FILTER =
        ' AND (CUS_PO_NO LIKE %<<cus po no>>%) AND '
        + '(ORDER_NO LIKE %<<order no>>%) AND '
        + '(PROD_STATUS LIKE %<<prod status>>%)';

    static readonly BACKLOG_FILTER =
        ' AND (ORDER_NO LIKE %<<order no>>%) AND '
        + '(SKU LIKE %<<sku>>%) AND '
        + '(LINE_NO LIKE %<<line no>>%) AND '
        + '(LINE_STATUS LIKE %<<line status>>%) AND '
        + '(ORDER_TYPE LIKE %<<order type>>%)';

    static readonly ASNERROR_FILTER =
        ' AND (ORDER_NO LIKE %<<order no>>%) AND '
        + '(SKU LIKE %<<sku>>%) AND '
        + '(ORDER_LINE_NO LIKE %<<order line no>>%) AND '
        + '(ORDER_TYPE LIKE %<<order type>>%)';

    static readonly SHIPSTAGING_FILTER =
        ' AND (ORDER_NO LIKE %<<order no>>%) AND '
        + '(SKU LIKE %<<sku>>%) AND '
        + '(ORDER_LINE_NO LIKE %<<order line no>>%) AND '
        + '(ORDER_TYPE LIKE %<<order type>>%)';

    static readonly UNPAID_FILTER =
        ' AND (ORDER_NO LIKE %<<order no>>%) AND '
        + '(ITEM_NUMBER LIKE %<<item number>>%) AND '
        + '(LINE_NO LIKE %<<line no>>%) AND '
        + '(LINE_STATUS LIKE %<<line status>>%) AND '
        + '(ORDER_TYPE LIKE %<<order type>>%)';

    static readonly INSTALL_BASE_DATA_FIELD = 'ACCOUNT_NAME,RESELLER_NAME,DISTY_NAME,' +
        'PART_NO,HW_PF,SERIAL_NO,INSTALL_DATE,' +
        'END_DATE,START_DATE,' +
        'ADDRESS,CITY,STATE,ZIP_CODE,COUNTRY,COUNTRY_CODE,INSTALLED_COUNTRY,INSTALLED_CC,' +
        'REGION,COVERED,MAINT_STATUS,MAINTENANCE_TYPE,STATUS,PRODUCT,FAMILY,MPN,QUOTE_NO,FIN_ORDER_NO';

    static readonly BACKLOG_EXCELCOLUMNS = 'FIN_ORDER_NO,SFDC_ORDER_NO,B2B_ORDER_NO,QUOTE_NUMBER,PO_NUMBER,ORDER_TYPE'
        + ',ORDER_DATE,CM_BOOK_DATE,PUSH_DATE,CONFIRM_DATE,CONFIRMED,DATE_IN_SHIPPING,SHIP_TO_CUSTOMER,SHIP_PROVINCE,SHIP_CODE'
        + ',REGION,SHIP_ADDRESS,SHIP_COUNTRY,SHIP_COUNTRY_CODE,FOB_TERMS,WEEK,FYQTR,SHIP_INSTRUCTIONS,SHIP_METHOD,FREIGHT_TERMS'
        + ',CM_SLA_DAYS,EXPEDITE_FLAG,TRACKING_NOS,LIC_LOAD,ORDER_STATUS,LINE_STATUS,PART_NO,LINE_NO,QTY,PART_TYPE,MPN,SUB_INV'
        + ',INCOMING_SERIAL_NO,SALES_REP,SCHEDULED_DATE,ITEM_DESCRIPTION,SURESHIP_FLAG,DID_NO,SERIAL_NOS,SFDC_FULFILL_STATUS'
        + ',CM_PROD_STATUS,CM_SHIP_DATE,CM_ORDER_NO,CM_WO,CM_PO,ORDER_CATEGORY,ORDER_PRODUCT_ID,RPE_FLAG,EXCEP_CHECK,ORDER_NOTES';
    static readonly BACKLOG_OPEN_EXCELCOLUMNS = 'FIN_ORDER_NO,B2B_ORDER_NO,CM_ORDER_NO,DID_NO,CM_PO,ORDER_DATE,PUSH_DATE,CM_BOOK_DATE,'
        + 'DATE_IN_SHIPPING,CM_SHIP_DATE,LIC_LOAD,EXPEDITE_FLAG,SURESHIP_FLAG,ORDER_STATUS,CM_PROD_STATUS,PART_NO,LINE_NO,QTY,MPN,'
        + 'SHIP_TO_CUSTOMER,SHIP_PROVINCE,SHIP_CODE';
    // Filter for RMA Reports
    // Filter for Flash RMA report
    static readonly FLASHRMA_RECEIPT_TABLEFILTER =
        ' AND (SERIAL_NO LIKE %<<serial no>>%) AND '
        + '(CUS_REF_NO LIKE %<<cus ref no>>%) AND '
        + '(HUB LIKE %<<hub>>%) AND '
        + '(ALERT_TYPE LIKE %<<alert type>>%) AND '
        + '((TRACKING_NO LIKE %<<tracking no>>%) OR (TRACKING_NO IS NULL))';

    static readonly FLASHRMA_DEPOT_TABLEFILTER = ' AND (ITEM_NO LIKE %<<item no>>%) AND '
        + '(ORDER_TYPE LIKE %<<order type>>%) AND '
        + '(TRACKING_NO LIKE %<<tracking no>>%) AND '
        + '(SERIAL_NO LIKE %<<serial no>>%) AND '
        + '(RMA_NO LIKE %<<rma no>>%)';
    // Filter for RMA NOW Report
    static readonly RMA_NOW_TABLEFILTER = '(RMA_NO LIKE %<<rma no>>%) AND '
        + '(FAULT_CODE LIKE %<<fault code>>%) AND '
        + '(REP_SL_NO LIKE %<<rep sn>>%) AND '
        + '(EBS_REP_STATUS LIKE %<<ebs rep status>>%) AND '
        + '(DEF_ITEM_NO LIKE %<<def item no>>%) AND '
        + '(REP_ITEM_NO LIKE %<<rep item no>>%) AND '
        + '(CASE_NO LIKE %<<case no>>%) AND '
        + '(ORG_NAME LIKE %<<org name>>%) AND '
        + '(EBS_RTN_STATUS LIKE %<<ebs rtn status>>%) AND '
        + '(DEF_SL_NO LIKE %<<defective sn>>%)';

    // Filter for RMA SUMMARY Report
    static readonly RMA_SUMMARY_TABLEFILTER = '(WAREHOUSE LIKE %<<warehouse>>%) AND '
        + '(PRODUCT_GENERATION LIKE %<<product generation>>%) AND '
        + '(PLATFORM_FAMILY LIKE %<<platform family>>%) AND '
        + '(PLATFORM LIKE %<<platform>>%)';
        // + '(TOTAL_COUNT LIKE %<<total count>>%) AND '
        // + '(LEAD_TIME LIKE %<<lead time>>%) AND '
        // + '(MEAN_VAL LIKE %<<mean val>>%) AND '
        // + '(STD_DEV LIKE %<<std dev>>%) AND '
        // + '(MIN_QTY LIKE %<<min qty>>%)';

    // Filter for RMA INVENTORY Report
    static readonly RMA_INVENTORY_TABLEFILTER = 'AND(DEPOT_NAME LIKE %<<depot name>>%) AND '
        + '(PART_NO LIKE %<<part no>>%) AND '
        + '(FSL LIKE %<<fsl>>%)';
        // + '(TOTAL_COUNT LIKE %<<total count>>%) AND '
        // + '(LEAD_TIME LIKE %<<lead time>>%) AND '
        // + '(MEAN_VAL LIKE %<<mean val>>%) AND '
        // + '(STD_DEV LIKE %<<std dev>>%) AND '
        // + '(MIN_QTY LIKE %<<min qty>>%)';

    // Filter for NEW RMA NOW Report
    static readonly NEW_RMA_NOW_TABLEFILTER = '(RMA_ORDER_NO LIKE %<<rma order no>>%) AND '
        // + '(CASE_NO LIKE %<<case no>>%) AND '
        + '(DEF_SL_NO LIKE %<<defective sn>>%) AND '
        + '(REPL_SL_NO LIKE %<<replace sn>>%) AND '
        + '(HOST_SKU LIKE %<<host sku>>%) AND '
        + '(RMA_FRU_SKU LIKE %<<rma sku>>%) AND '
        + '(INSTALL_AT_CUST LIKE %<<installed at>>%) AND '
        + '(INSTALL_AT_COUNTRY LIKE %<<country>>%)';
    static readonly NEW_RMA_NOW_EXCELCOLUMNS = 'RMA_ORDER_NO,CASE_NO,BOOKED_DATE,SHIP_DATE,DEF_SL_NO,HOST_SKU,'
        + 'INSTALL_AT_CUST,RMA_FRU_SKU,INSTALL_AT_COUNTRY';

    // Filter for Flash ORDER HISTORY Report
    static readonly FLASH_ORDER_HISTORY_TABLEFILTER = '(FLASH_ORDER_NO LIKE %<<flash order no>>%) AND '
        + '(RMA_NO LIKE %<<rma no>>%) AND '
        + '(DEPOT LIKE %<<depot>>%) AND '
        // + '(ORDER_TYPE LIKE %<<order type>>%) AND '
        + '(SL_NO LIKE %<<serial no>>%) AND '
        + '(LATE_CONTROL LIKE %<<late control>>%) AND '
        + '(PART_NO LIKE %<<part no>>%)';

    // Filter for NEW RMA NOW Report
    static readonly FLASH_ASN_ERROR_TABLEFILTER = ' AND (RMA_NUMBER LIKE %<<rma no>>%) AND '
        + '(ITEM_NUMBER LIKE %<<item no>>%) AND '
        + '(SERIAL_NUMBER LIKE %<<serial no>>%) AND '
        // + '(QTY_SHIPPED LIKE %<<ship qty>>%) AND '
        + '(SHIPMENT_METHOD LIKE %<<ship method>>%) AND '
        + '(TRACKING_NUMBER LIKE %<<tracking no>>%) AND '
        + '(PROCESS_FLAG LIKE %<<status>>%) AND '
        + '(ERROR_MESSAGE LIKE %<<error message>>%)';

    // Filter for SSUI Report
    static readonly SSUI_TABLE_FILTER = '(SSI_TRANS_ID LIKE %<<ssi trans id>>%) AND '
        + '(IBLOX_GL LIKE %<<iblox gl>>%) AND '
        + '(GL_SECTION_NO LIKE %<<gl section no>>%) AND '
        + '(REFERENCE_NOTES LIKE %<<reference notes>>%) AND '
        + '(CARRIER_NAME LIKE %<<carrier name>>%) AND '
        + '(BILL_TO_NO LIKE %<<bill to no>>%) AND '
        + '(WAYBILL_NO LIKE %<<waybill no>>%) AND '
        + '(INVOICE_NO LIKE %<<invoice no>>%) AND '
        + '(BATCH_NO LIKE %<<batch no>>%) AND '
        + '(SHIP_COMPANY LIKE %<<ship company>>%) AND '
        + '(SHIP_NAME LIKE %<<ship name>>%) AND '
        + '(REC_COMPANY LIKE %<<rec company>>%) AND '
        + '(REC_NAME LIKE %<<rec name>>%)';
    // + '(PROC_DATE LIKE %<<proc date>>%) AND '
    // + '(AUDIT_DATE LIKE %<<audit date>>%) AND ';
    // + '(INV_DATE LIKE %<<inv date>>%)';
    // + '(SHIP_DATE LIKE %<<ship date>>%)';
}
