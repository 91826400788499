import { Header } from './header.modal';

export class MercedesReportModal {
    readonly mercedesReportHeader: Header[] = [
        {
            columnName: 'SERIAL_NUMBER',
            displayName: 'SERIAL NUMBER',
            columnType: 'string'
        },
        {
            columnName: 'PART_NUMBER',
            displayName: 'PART NUMBER',
            columnType: 'string'
        },
        // {
        //     columnName: 'SOFTWARE_SKU',
        //     displayName: 'SOFTWARE SKU',
        //     columnType: 'string'
        // },
        {
            columnName: 'HWS',
            displayName: 'HWS',
            columnType: 'string'
        },
        {
            columnName: 'SITE_NAME',
            displayName: 'SITE NAME',
            columnType: 'string'
        },
        {
            columnName: 'TEST_TYPE',
            displayName: 'TEST TYPE',
            columnType: 'string'
        },
        {
            columnName: 'START_TIME',
            displayName: 'START TIME',
            columnType: 'string'
        },
        {
            columnName: 'TEST_DATE',
            displayName: 'TEST DATE',
            columnType: 'number'
        },
        {
            columnName: 'TEST_STATUS',
            displayName: 'TEST STATUS',
            columnType: 'string'
        }
    ];
    public mercedesData: any;
    public totalRecords: number;
    public currentUser: any;
    public order: string;
    public reverse: boolean;
    public collapsed: boolean;
    public collapsedRowData: any;
    public selectedName: any;
    public show: boolean;
    public OL_EMAIL: string;
    public OL_ID: number;
    public OL_GROUPNAME: string;
    public userAdminValue: any;
    public userRoleArray: any;
    public view: string;
    public offset: any;
    public filter: string;
    public serialNumberData: any;
    public serialNoArray: any;
    public selectedTabLabel: any;
    public createdOnDisplay: any;
    public serialNo: any;
    public searchSerialNo: any;
    public searchTestType: any;
    public searchSerialNoArray: any;
    public testTypeArray: any;
    public imdbFileList: any;
    public fileName: any;
    public eachFileData: any;
    public searchTestArray: any;
    public testTypeState: boolean;
    public downloadFileBool: boolean;
    public selectedSiteNameArray: any;
    public startDate: any;
    public endDate: any;
    public dateRangeBool: boolean;
    public dateRangeFilter: string;
    public minDate: any;
    public maxDate: any;
    public testDate: string;
    public dateType: string;
    constructor() {
        this.order = 'SERIAL_NUMBER, START_TIME';
        this.reverse = false;
        this.userRoleArray = [];
        this.mercedesData = [];
        this.view = 'Qtr To Date';
        this.offset = 0;
        this.totalRecords = 0;
        this.selectedName = '';
        this.filter = '';
        this.serialNumberData = [];
        this.serialNoArray = [];
        this.selectedTabLabel = 'Mercedes Report';
        this.createdOnDisplay = '';
        this.serialNo = '';
        this.testTypeState = false;
        this.searchSerialNo = '';
        this.searchTestType = 'Test Type';
        this.searchSerialNoArray = [];
        this.testTypeArray = ['All', 'CTO', 'SWDL', 'LICCONF'];
        this.imdbFileList = [];
        this.fileName = '';
        this.eachFileData = [];
        this.searchTestArray = [];
        this.downloadFileBool = false;
        this.selectedSiteNameArray = [];
        this.dateRangeBool = false;
        this.dateRangeFilter = '';
        this.dateType = '';
        this.minDate = '';
        this.maxDate = '';
    }
    public setTotalRecords(totalRecords: number) {
        this.totalRecords = totalRecords;
    }
}
