import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { S3Constants } from '../constants/s3.constant';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class ShowLogFilesService {
    public logFileData;
    public logFileData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);
    constructor(public appService: AppService, public http: HttpClient) {
    }
    getRequestSerialNoType (fileName) {
        const modelType: any = {
            '0805': 'AUDI',
            '1405': 'BMW',
            '2205': 'MERCEDES',
            '4005': 'TESLA'
        };
        for (const key of Object.keys(modelType)) {
            if (fileName.includes(key)) {
                return modelType[key] + '/' + fileName;
            } else if (fileName.includes(S3Constants.PST) || fileName.includes('_Log.txt')) {
                return fileName;
            }
        }
    }
    // Get each file data for PST AND TB3
    async getEachFileData(filePath: string, actionType: string, bucketName: string) {
        this.setDataLoader(true);
        let bucket = '';
        if (filePath.includes('PST')) {
            bucket = S3Constants.PST_S3_BUCKET_NAME
        } else if (filePath.includes('TB3')) {
            bucket = S3Constants.TB3_S3_BUCKET_NAME
        } else {
            bucket = bucketName;
        }
        console.log('shipa', bucket)
        let header: HttpHeaders = new HttpHeaders();
        header = header.set('x-api-key', 'gElMGroKiE6xFbKpL5BzBaoOHcqdvQ4r6AlgHzql');
        let param: HttpParams = AppUtil.getHttpParams();
        param = param.set('bucket', bucket);
        param = param.set('key', filePath);
        param = param.set('action', actionType);
        const data = await this.appService.getHTTPClient()
            .get(AppUtil.getLambdaTestbloxLogUrl(),
            { responseType: 'text', headers: header, params: param}).toPromise() as any;
        this.setEachFileData(data);
        this.setDataLoader(false);
    }
    setEachFileData(data: any) {
        this.logFileData = data;
        this.logFileData$.next(this.logFileData);
    }
    onEachFileData() {
        return this.logFileData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}

