import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { ArrowInvReportModal } from '../modal/arrowInvReport.modal';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class ArrowInvReportService {
    public arrowInvReportData;
    public arrowInvReportData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public arrowInvReportExcelData;
    public arrowInvReportExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

    constructor(public appService: AppService, public http: HttpClient) { }

    getArrowInvReportData(arrowInvReportModalData: ArrowInvReportModal): Observable<any> {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', arrowInvReportModalData.order);
        params = params.set('offset', arrowInvReportModalData.offset);
        if (arrowInvReportModalData.selectedTabLabel === 'X01'
            || arrowInvReportModalData.selectedTabLabel === '' ) {
            params = params.set('filter', '(WAREHOUSE LIKE \'%X01%\')' + arrowInvReportModalData.filter);
        } else {
            params = params.set('filter', '(WAREHOUSE LIKE \'%W42%\')' + arrowInvReportModalData.filter);
        }
        arrowInvReportModalData.offset += 300;
        this.setDataLoader(true);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.ARROW_INV_REPORT_VIEWNAME),
        { params : params, headers: AppUtil.getHttpHeaders()});
    }
    setArrowInvReportData(data: any) {
        this.arrowInvReportData = data;
        this.arrowInvReportData$.next(this.arrowInvReportData);
    }
    onArrowInvReportData() {
        return this.arrowInvReportData$.asObservable();
    }
    async getArrowInvReportExcelData(arrowInvReportModalData: ArrowInvReportModal) {
        let params: HttpParams = AppUtil.getHttpParams();
        if (arrowInvReportModalData.selectedTabLabel === 'X01'
            || arrowInvReportModalData.selectedTabLabel === '' ) {
            params = params.set('filter', '(WAREHOUSE LIKE \'%X01%\')' + arrowInvReportModalData.filter);
        } else {
            params = params.set('filter', '(WAREHOUSE LIKE \'%W42%\')' + arrowInvReportModalData.filter);
        }
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.ARROW_INV_REPORT_VIEWNAME)
        , params);
        this.setArrowInvReportExcelData(data.body.resource);
    }
    setArrowInvReportExcelData(data: any) {
        this.arrowInvReportExcelData = data;
        this.arrowInvReportExcelData$.next(this.arrowInvReportExcelData);
    }
    onArrowInvReportExcelData() {
        return this.arrowInvReportExcelData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
