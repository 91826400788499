import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstant } from '../constants/app.constants';
import { AppUtil } from '../util/app.util';

@Injectable()
export class ResetPasswordService {
  constructor(public http: HttpClient) {}
  resetPassword(password, userEmailId) {
    const resetPasswordBody = {
      'resource' : [
        {
          'OL_PASSWORD' : password
        }
      ]
    };
    let param: HttpParams = AppUtil.getHttpParams();
    param = param.set('filter', 'OL_EMAIL=' + userEmailId);
    return this.http.patch(AppUtil.getAppUrl(AppConstant.REGISTRATION_TABLENAME), resetPasswordBody, { params: param });
  }
}
