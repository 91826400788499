import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { AppUtil } from '../util/app.util';
import { AeronetExceptionsReportModal } from './../modal/aeronetExceptions.modal';
import { AppService } from './app.service';

@Injectable()
export class AeronetExceptionsReportService {
    public aeronetExceptionsData;
    public aeronetExceptionsData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public aeronetExceptionsExcelData;
    public aeronetExceptionsExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

    constructor(public appService: AppService, public http: HttpClient) { }

    getAeronetExceptionsData(aeronetExceptionsModalData: AeronetExceptionsReportModal): Observable<any> {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', aeronetExceptionsModalData.order);
        params = params.set('offset', aeronetExceptionsModalData.offset);
        params = params.set('filter', aeronetExceptionsModalData.filter);
        aeronetExceptionsModalData.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.FLASH_EXCEPTION_VIEWNAME),
            { params: params, headers: AppUtil.getHttpHeaders() });
    }
    setAeronetExceptionsData(data: any) {
        this.aeronetExceptionsData = data;
        this.aeronetExceptionsData$.next(this.aeronetExceptionsData);
    }
    onAeronetExceptionsData() {
        return this.aeronetExceptionsData$.asObservable();
    }
    async getAeronetExceptionsExcelData(filter) {
        let params: HttpParams = AppUtil.getHttpParams();
        if (AppUtil.isNullOrUndefined(this.aeronetExceptionsExcelData)) {
            params = params.set('filter', filter);
            const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.FLASH_EXCEPTION_VIEWNAME)
                , params);
            this.setAeronetExceptionsExcelData(data.body.resource);
        }
    }
    setAeronetExceptionsExcelData(data: any) {
        this.aeronetExceptionsExcelData = data;
        this.aeronetExceptionsExcelData$.next(this.aeronetExceptionsExcelData);
    }
    onAeronetExceptionsExcelData() {
        return this.aeronetExceptionsExcelData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
