import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { SessionProperties } from '../session/session.properties';
import { AppUtil } from '../util/app.util';
import { CompleteReportFilter } from './../modal/completeShipReport.modal';
import { ShipReportModal } from './../modal/shipReport.modal';
import { AppService } from './app.service';

@Injectable()
export class ShipReportService {
    public shipData;
    public shipData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public remainShipData;
    public remainShipData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public systemTypeCount;
    public systemTypeCount$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public shipExcelData;
    public shipExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public partNumber;
    public partNumber$: BehaviorSubject<CompleteReportFilter> = new BehaviorSubject<CompleteReportFilter>(null);
    public dashboardSales;
    public dashboardSales$: BehaviorSubject<CompleteReportFilter> = new BehaviorSubject<CompleteReportFilter>(null);
    public orderType;
    public orderType$: BehaviorSubject<any> = new BehaviorSubject<any>('All');
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);
    currentDate;
    constructor(public appService: AppService, public http: HttpClient) {
    }
    // getFilter(view) {
    //     let filter;
    //     if (view === 'Qtr To Date' || view == null) {
    //         filter = '(((CONFIRM_DATE>='
    //             + AppConstant.quaterStartDate
    //             + ')AND(CONFIRM_DATE<='
    //             + AppConstant.quaterEndDate
    //             + '))OR(CONFIRM_DATE IS NULL))AND(ORDER_CATEGORY=\'DOF\')';
    //     } else if (view === 'Month To Date') {
    //         filter = '((CONFIRM_DATE>='
    //             + AppConstant.monthStartDate
    //             + ')AND(CONFIRM_DATE<='
    //             + AppConstant.monthEndDate
    //             + '))AND(ORDER_CATEGORY=\'DOF\')';
    //     } else if (view === 'Previous Qtr') {
    //         filter = '((CONFIRM_DATE>='
    //             + AppConstant.prvQtrStartDate
    //             + ')AND(CONFIRM_DATE<='
    //             + AppConstant.prvQtrEndDate
    //             + '))AND(ORDER_CATEGORY=\'DOF\')';
    //     } else if (view === 'Complete View') {
    //         filter = '(ORDER_CATEGORY=\'DOF\')';
    //     }
    //     return filter;
    // }
    getFilter(view: string, type: string) {
        let filter: string;
        const orderType = AppUtil.isNullOrUndefined(type) ? '' : type.trim();
        if (view === 'Qtr To Date' || view == null) {
            filter = '(((ORDER_DATE>='
                + SessionProperties.quaterStartDate
                + ')AND(ORDER_DATE<='
                + SessionProperties.quaterEndDate
                + '))OR(ORDER_DATE IS NULL))AND(ORDER_CATEGORY=\'DOF\')AND(ORDER_TYPE LIKE \'%' + orderType.trim() + '%\')';
        } else if (view === 'Month To Date') {
            filter = '((ORDER_DATE>='
                + SessionProperties.monthStartDate
                + ')AND(ORDER_DATE<='
                + SessionProperties.monthEndDate
                + '))AND(ORDER_CATEGORY=\'DOF\')AND(ORDER_TYPE LIKE \'%' + orderType.trim() + '%\')';
        } else if (view === 'Previous Qtr') {
            filter = '((ORDER_DATE>='
                + SessionProperties.prvQtrStartDate
                + ')AND(ORDER_DATE<='
                + SessionProperties.prvQtrEndDate
                + '))AND(ORDER_CATEGORY=\'DOF\')AND(ORDER_TYPE LIKE \'%' + orderType.trim() + '%\')';
        } else if (view === 'Complete View') {
            filter = '(ORDER_CATEGORY=\'DOF\')AND(ORDER_TYPE LIKE \'%' + orderType.trim() + '%\')';
        }
        return filter;
    }
    getShipData(shipModalData: ShipReportModal): Observable<any> {
        this.setDataLoader(true);
        let filter = this.getFilter(shipModalData.view, shipModalData.orderType);
        filter += shipModalData.filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', shipModalData.order);
        params = params.set('offset', shipModalData.offset);
        params = params.set('filter', filter);
        params = params.set('group', 'FIN_ORDER_NO');
        shipModalData.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.SHIP_REPORT_TABLENAME), { params: params,
            headers: AppUtil.getHttpHeaders()});
    }
    setShipData(data: any) {
        this.shipData = data;
        this.shipData$.next(this.shipData);
    }
    onShipData() {
        return this.shipData$.asObservable();
    }
    async getRemainShipData() {
        let param: HttpParams = AppUtil.getHttpParams();
        param = param.set('fields', 'FIN_ORDER_NO,LINE_NO,LINE_STATUS,PART_NO,MPN,PART_TYPE,SUB_INV,QTY');
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.SHIPREMAININGDATA_TABLE), param);
        this.setRemainShipData(data.body.resource);
    }
    setRemainShipData(data: any) {
        this.remainShipData = data;
        this.remainShipData$.next(this.remainShipData);
    }
    onRemainShipData() {
        return this.remainShipData$.asObservable();
    }
    async getExcelShipData(shipModalData: ShipReportModal) {
        this.setDataLoader(true);
        let filter = this.getFilter(shipModalData.view, shipModalData.orderType);
        filter += shipModalData.filter;
        let param: HttpParams = AppUtil.getHttpParams();
        param = param.set('filter', filter);
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.SHIP_REPORT_TABLENAME), param);
        this.setExcelShipData(data.body.resource);
    }
    setExcelShipData(data: any) {
        this.shipExcelData = data;
        this.shipExcelData$.next(this.shipExcelData);
    }
    onExcelShipData() {
        return this.shipExcelData$.asObservable();
    }
    setOrderType(data: any) {
        this.orderType = data;
        this.orderType$.next(this.orderType);
    }
    onOrderType() {
        return this.orderType$.asObservable();
    }
    async getSystemsTypeCount(viewType: string) {
        const url = AppUtil.getUrl(AppConstant.SYSTEMSTYPES_VIEWNAME);
        const data: any = await this.appService.checkGetMethod(url);
        this.setSystemsTypeCount(data.body.resource);
        this.setDataLoader(false);
    }
    setSystemsTypeCount(data: any) {
        this.systemTypeCount = data;
        this.systemTypeCount$.next(this.systemTypeCount);
    }
    onSystemsTypeCount() {
        return this.systemTypeCount$.asObservable();
    }
    async getShipDashboardSales() {
        this.setDataLoader(true);
        const data = await this.appService.checkGetMethod(AppUtil.getUrl(AppConstant.DASHBOARDSALES_VIEWNAME));
        this.setShipDashboardSales(data.body.resource);
        this.setDataLoader(false);
    }
    setShipDashboardSales(data: any) {
        this.dashboardSales = data;
        this.dashboardSales$.next(this.dashboardSales);
    }
    onShipDashboardSales() {
        return this.dashboardSales$.asObservable();
    }
    updateNotes(note, FIN_ORDER_NO, userId): Observable<any> {
        const updateNotes = {
            resource: [
                {
                    USR_NOTES: note,
                    USR_ID: userId
                }
            ]
        };
        const url = AppUtil.getUrlFilter(
            AppConstant.SHIP_REPORT_TABLENAME,
            '(FIN_ORDER_NO LIKE %25' +
            FIN_ORDER_NO +
            '%25)'
        );

        return this.http.patch(url, updateNotes);
    }
    setView(partNumber: CompleteReportFilter) {
        this.partNumber = partNumber;
        this.partNumber$.next(this.partNumber);
    }
    onView() {
        return this.partNumber$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
