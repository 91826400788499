import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { ComponentConstants } from '../constants/component.constants';
import { SnShippedSMCModal } from '../modal/snShippedSMC.modal';
import { AppUtil } from '../util/app.util';
import { PartDetails } from './../modal/snShippedSMC.modal';
import { AppService } from './app.service';

@Injectable()
export class SnShippedSMCService {
    public snShippedData;
    public snShippedData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public snShippedRemData;
    public snShippedRemData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public snShippedExcelData;
    public snShippedExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);
    public partDetails$: BehaviorSubject<PartDetails> = new BehaviorSubject<PartDetails>(null);


    constructor(public appService: AppService, public http: HttpClient) { }

    getSnShippedData(snShippedModalData: SnShippedSMCModal): Observable<any> {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', snShippedModalData.order);
        params = params.set('offset', snShippedModalData.offset);
        params = params.set('filter', snShippedModalData.filter);
        if (snShippedModalData.selectedTabLabel === ComponentConstants.SUMMARY_VIEW
            || snShippedModalData.selectedTabLabel === '' ) {
            params = params.set('group', 'BLANKET_PO,RELEASE_NO');
            params = params.set('order', '');
        }
        snShippedModalData.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.SNSHIPPED_VIEWNAME),
        { params : params, headers: AppUtil.getHttpHeaders()});
    }
    setSnShippedData(data: any) {
        this.snShippedData = data;
        this.snShippedData$.next(this.snShippedData);
    }
    onSnShippedData() {
        return this.snShippedData$.asObservable();
    }

    setPartDetails(data: any) {
        this.partDetails$.next(data);
    }

    onPartDetails() {
        return this.partDetails$.asObservable();
    }

    async getSnShippedRemainData(snShippedModalData: SnShippedSMCModal) {
        if (AppUtil.isNullOrUndefined(this.snShippedRemData)) {
            const url = AppUtil.getUrlFieldFilter(
                AppConstant.SNSHIPPED_VIEWNAME,
                'BLANKET_PO, RELEASE_NO, RECEIPT_DATE, RECEIPT_TIME, TRANSACTION_ID, FILE_NAME, IFX_PART_NUMBER, QTY',
                '') + '&order=BLANKET_PO';
            const data: any = await this.appService.checkGetMethod(url);
            const partDetails: PartDetails[] = [];
            const partDataByBlanketAndRelease = _.groupBy(data.body.resource, part => [part.BLANKET_PO,
            part.RELEASE_NO, part.IFX_PART_NUMBER]);
            Object.keys(partDataByBlanketAndRelease).forEach(key => {
                const keyArray = key.split(',');
                let sumQty = 0;
                partDataByBlanketAndRelease[key].forEach(element => {
                    const qty = parseInt(element.QTY, 10);
                    if (!isNaN(qty)) {
                        sumQty += qty;
                    }
                });
                const partDetail: PartDetails = {
                    partNo: keyArray[2],
                    qty: sumQty,
                    blanketPo: keyArray[0],
                    releaseNo: keyArray[1]
                };
                partDetails.push(partDetail);
            });
            this.setPartDetails(partDetails);
            this.setSnShippedRemainData(data.body.resource);
            this.setDataLoader(false);
        }
    }
    setSnShippedRemainData(data: any) {
        this.snShippedRemData = data;
        this.snShippedRemData$.next(this.snShippedRemData);
    }
    onSnShippedRemainData() {
        return this.snShippedRemData$.asObservable();
    }
    async getSNShippedExcelData(filter) {
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('filter', filter);
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.SNSHIPPED_VIEWNAME), params);
        this.setSNShippedExcelData(data.body.resource);
    }
    setSNShippedExcelData(data: any) {
        this.snShippedExcelData = data;
        this.snShippedExcelData$.next(this.snShippedExcelData);
    }
    onSNShippedExcelData() {
        return this.snShippedExcelData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
