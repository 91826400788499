import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from 'src/app/@core/constants/app.constants';
import { RmaNowReportModal } from '../modal/rmaNowReport.modal';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class NewRmaNowReportService {
    public rmaNowData;
    public rmaNowData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public rmaNowExcelData;
    public rmaNowExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);
    public maxDate: any;
    public maxDate$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    currentDate;
    constructor(public appService: AppService, public http: HttpClient) {
    }
    getRmaNowReportData(rmaNowModalData: RmaNowReportModal): Observable<any> {
        this.setDataLoader(true);
        const filter = rmaNowModalData.filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('offset', rmaNowModalData.offset);
        params = params.set('filter', filter);
        params = params.set('order', rmaNowModalData.order);
        rmaNowModalData.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.NEW_RMA_NOW_REPORT_TABLENAME),
        { params : params, headers: AppUtil.getHttpHeaders()});
    }
    setRmaNowReportData(data: any) {
        this.rmaNowData = data;
        this.rmaNowData$.next(this.rmaNowData);
    }
    onRmaNowReportData() {
        return this.rmaNowData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
    async getExcelRmaNowReportData(rmaNowModalData: RmaNowReportModal) {
        this.setDataLoader(true);
        const filter = rmaNowModalData.filter;
        let param: HttpParams = AppUtil.getHttpParams();
        param = param.set('filter', filter);
        // param = param.set('fields', AppConstant.NEW_RMA_NOW_EXCELCOLUMNS);
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.NEW_RMA_NOW_REPORT_TABLENAME), param);
        this.setExcelRmaNowReportData(data.body.resource);
    }
    setExcelRmaNowReportData(data: any) {
        this.rmaNowExcelData = data;
        this.rmaNowExcelData$.next(this.rmaNowExcelData);
    }
    onExcelRmaNowReportData() {
        return this.rmaNowExcelData$.asObservable();
    }
    async getMaxDate() {
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('fields', 'CREATED_ON');
        params = params.set('order', 'CREATED_ON DESC');
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.NEW_RMA_NOW_REPORT_TABLENAME), params);
        this.setMaxDate(data.body.resource[0]);
    }
    setMaxDate(data: any) {
        this.maxDate = data;
        this.maxDate$.next(this.maxDate);
    }
    onMaxDate() {
        return this.maxDate$.asObservable();
    }
}
