import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDialogModule, MatExpansionModule, MatGridListModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule } from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { OktaAuthModule } from '@okta/okta-angular';
import { FusionChartsModule } from 'angular-fusioncharts';
import { UserIdleModule } from 'angular-user-idle';
// Load FusionCharts
import * as FusionCharts from 'fusioncharts/fusioncharts';
// Load charts module
import * as Charts from 'fusioncharts/fusioncharts.charts';
import * as ExcelExport from 'fusioncharts/fusioncharts.excelexport';
// import * as Widgets from 'fusioncharts/fusioncharts.widgets';
// Load Maps module
import * as Maps from 'fusioncharts/fusioncharts.maps';
// import * as Charts from 'fusioncharts/fusioncharts.charts';
// Load PowerCharts module
import * as PowerCharts from 'fusioncharts/fusioncharts.powercharts';
// Load fusion-time module
import * as TimeSeries from 'fusioncharts/fusioncharts.timeseries';
// import * as PowerCharts from 'fusioncharts/fusioncharts.powercharts';
// Load Widgets module
import * as Widgets from 'fusioncharts/fusioncharts.widgets';
// import * as Maps from 'fusioncharts/fusioncharts.maps';
// Load WorldMap module
import * as WorldMap from 'fusioncharts/maps/fusioncharts.world';
import * as Candy from 'fusioncharts/themes/fusioncharts.theme.candy';
import * as Carbon from 'fusioncharts/themes/fusioncharts.theme.carbon';
import * as Fint from 'fusioncharts/themes/fusioncharts.theme.fint';
import * as Fusion from 'fusioncharts/themes/fusioncharts.theme.fusion';
import * as Gammel from 'fusioncharts/themes/fusioncharts.theme.gammel';
// import * as WorldMap from 'fusioncharts/maps/fusioncharts.world';
// Load Ocean theme
import * as Ocean from 'fusioncharts/themes/fusioncharts.theme.ocean';
import * as Zune from 'fusioncharts/themes/fusioncharts.theme.zune';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { CookieService } from 'ngx-cookie-service';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { OrderModule } from 'ngx-order-pipe';
import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { NgxUploaderModule } from 'ngx-uploader';
import { CompleteShipReportService } from 'src/app/@core/data/completeShipReport.service';
import { SalesService } from 'src/app/@core/data/sales.service';
import { AddNewUserService } from './@core/data/addNewUser.service';
import { AdminService } from './@core/data/admin.service';
import { AeronetReportService } from './@core/data/aeronet.service';
import { AeronetExceptionsReportService } from './@core/data/aeronetExceptions.service';
import { AppService } from './@core/data/app.service';
import { ArrowEngagementReportService } from './@core/data/arrowEngagementReport.service';
import { ArrowInvReportService } from './@core/data/arrowInvReport.service';
import { ArrowStatusReportService } from './@core/data/arrowStatusReport.service';
import { AudiReportService } from './@core/data/audiReport.service';
import { B2bAsnErrorReportService } from './@core/data/b2bAsnErrorReport.service';
import { BacklogArchiveService } from './@core/data/backlogArchive.service';
import { BaxterService } from './@core/data/baxterReport.service';
import { BmwReportService } from './@core/data/bmwReport.service';
import { DashboardService } from './@core/data/dashboard.service';
import { ExceptionService } from './@core/data/exceptionReport.service';
import { FlashANErrorReportService } from './@core/data/flashASNErrorReport.service';
import { FlashInvExceptionsReportService } from './@core/data/flashInvExceptions.service';
import { FlashOrderHistoryService } from './@core/data/flashOrderHistory.service';
import { FlashReportService } from './@core/data/flashReport.service';
import { FlashRmaReportService } from './@core/data/flashRmaReport.service';
import { IbRegionsReportService } from './@core/data/ibregionsReport.service';
import { IMDBS3Service } from './@core/data/imdbS3.service';
import { InstallBaseService } from './@core/data/installBase.service';
import { InventoryManagementService } from './@core/data/inventoryManagement.service';
import { LegacyService } from './@core/data/legacyReport.service';
import { LoginService } from './@core/data/login.service';
import { MainDashboardService } from './@core/data/mainDashboard.service';
import { MercedesReportService } from './@core/data/mercedesReport.service';
import { MonitoringDashboardService } from './@core/data/monitoringDashboard.service';
import { NavbarService } from './@core/data/navbar.service';
import { NewRmaNowReportService } from './@core/data/newRmaRnow.service';
import { OpportunitiesDashboardService } from './@core/data/opportunitiesDashboard.service';
import { OpportunitiesReportService } from './@core/data/opportunitiesReport.service';
import { BacklogService } from './@core/data/orderBacklog.service';
import { OrderBacklogDashboardService } from './@core/data/orderBacklogDashboard.service';
import { OrderManagementService } from './@core/data/orderManagement.service';
import { PSTReportService } from './@core/data/pstReport.service';
import { RegisterService } from './@core/data/register.service';
import { ResetPasswordService } from './@core/data/resetPassword.service';
import { RMABacklogService } from './@core/data/rmaBacklog.service';
import { RMADashboardService } from './@core/data/rmaDashboard.service';
import { RmaInventoryReportService } from './@core/data/rmaInventoryReport.service';
import { RmaNowReportService } from './@core/data/rmaNowReport.service';
import { RmaSummaryReportService } from './@core/data/rmaSummaryReport.service';
import { ShippingDashboardService } from './@core/data/shippingDashboard.service';
import { ShipReportService } from './@core/data/shipReport.service';
import { ShowLogFilesService } from './@core/data/showLogFiles.service';
import { SkuPartMapService } from './@core/data/skuPartMap.service';
import { SnShippedSMCService } from './@core/data/snShippedSMC.service';
import { SSUIReportService } from './@core/data/ssuiReport.service';
import { TB3LogFileReportService } from './@core/data/tb3LogFileReport.service';
import { TB5DataViewService } from './@core/data/tb5DataView.service';
import { TeslaReportService } from './@core/data/teslaReport.service';
import { TestbloxService } from './@core/data/testblox.service';
import { TestbloxExceptionReportService } from './@core/data/testbloxExceptionReport.service';
import { TestBloxSerialNumberService } from './@core/data/testbloxSerialNumber.service';
import { AddNewUserComponent } from './add-new-user/add-new-user.component';
import { AdminComponent } from './admin/admin.component';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { FixedpluginModule } from './shared/fixedplugin/fixedplugin.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { SidebarModule } from './sidebar/sidebar.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
FusionChartsModule.fcRoot(
  FusionCharts,
  Charts,
  TimeSeries,
  PowerCharts,
  Widgets,
  Maps,
  WorldMap,
  Ocean,
  Fint,
  Gammel,
  Zune,
  Fusion,
  Candy,
  Carbon,
  ExcelExport
);
@NgModule({
    exports: [
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        InfiniteScrollModule
    ],
    declarations: []
})
export class MaterialModule { }

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes, { useHash: true }),
        HttpModule,
        MaterialModule,
        MatNativeDateModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        FixedpluginModule,
        HttpClientModule,
        OrderModule,
        NgMultiSelectDropDownModule.forRoot(),
        LoggerModule.forRoot({ serverLoggingUrl: '/api/logs', level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR }),
        NgxUploaderModule,
        Ng4LoadingSpinnerModule.forRoot(),
        TourMatMenuModule.forRoot(),
        OktaAuthModule,
        UserIdleModule.forRoot({idle: 600, timeout: 1800, ping: 120}),
        FusionChartsModule,
        FilterPipeModule,
        NgxDaterangepickerMd.forRoot()
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        AdminComponent,
        UserProfileComponent,
        AddNewUserComponent,
        NotFoundComponent
    ],
    providers: [
        CookieService,
        AppService,
        ShipReportService,
        AudiReportService,
        BmwReportService,
        MercedesReportService,
        TeslaReportService,
        LoginService,
        RegisterService,
        AdminService,
        ResetPasswordService,
        InstallBaseService,
        InventoryManagementService,
        TestbloxService,
        DashboardService,
        BacklogService,
        ExceptionService,
        LegacyService,
        NavbarService,
        BaxterService,
        OpportunitiesReportService,
        CompleteShipReportService,
        OrderManagementService,
        SalesService,
        SnShippedSMCService,
        MainDashboardService,
        AddNewUserService,
        FlashReportService,
        MonitoringDashboardService,
        TestBloxSerialNumberService,
        BacklogArchiveService,
        RMABacklogService,
        AeronetReportService,
        FlashRmaReportService,
        RMADashboardService,
        RmaNowReportService,
        FlashOrderHistoryService,
        SkuPartMapService,
        FlashInvExceptionsReportService,
        AeronetExceptionsReportService,
        ArrowInvReportService,
        OpportunitiesDashboardService,
        OrderBacklogDashboardService,
        ArrowEngagementReportService,
        ArrowStatusReportService,
        ShowLogFilesService,
        IMDBS3Service,
        TestbloxExceptionReportService,
        PSTReportService,
        SSUIReportService,
        ShippingDashboardService,
        NewRmaNowReportService,
        TB3LogFileReportService,
        B2bAsnErrorReportService,
        TB5DataViewService,
        FlashANErrorReportService,
        IbRegionsReportService,
        RmaSummaryReportService,
        RmaInventoryReportService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
