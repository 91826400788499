import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { AppConstant } from '../constants/app.constants';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class AddNewUserService {
  public RegisterData;
  public RegisterData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public emailId;
  public emailId$: Subject<any> = new Subject<any>();
  constructor(public appService: AppService, public http: HttpClient) {}

  registerNewUser(registrationDetails) {
    return this.appService.postRegisterData(registrationDetails);
  }
  getRegistartionDetails(): Observable<any> {
    return this.http.get<{ data: Object }>(AppUtil.getUrl(AppConstant.REGISTRATION_TABLENAME));
  }
  setRegistartionDetails(data: any) {
    this.RegisterData = data;
    this.RegisterData$.next(this.RegisterData);
  }
  onRegistrationDetails() {
    return this.RegisterData$.asObservable();
  }
  sendEmailToAdmin(adminEmailId) {
    return this.http.post(AppUtil.getEmailUrl(), adminEmailId);
  }
  getEmailId(): Observable<any> {
    let param: HttpParams = AppUtil.getHttpParams();
    param = param.set('fields', 'OL_EMAIL');
    return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.REGISTRATION_TABLENAME), { params: param });
  }
  setEmailId(data: any) {
    this.emailId = data;
    this.emailId$.next(this.emailId);
  }
  onEmailId() {
    return this.emailId$.asObservable();
  }
}
