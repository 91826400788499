import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { SessionProperties } from '../session/session.properties';
import { AppUtil } from '../util/app.util';
import { AudiReportModal } from './../modal/audiReport.modal';
import { AppService } from './app.service';

@Injectable()
export class AudiReportService {
    public audiData;
    public audiData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public excelAudiData;
    public excelAudiData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public serialNumber;
    public serialNumber$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

    currentDate;
    constructor(public appService: AppService, public http: HttpClient,
        public ngxXml2jsonService: NgxXml2jsonService) {
    }
    getFilter(view) {
        let filter;
        if (view === 'Qtr To Date' || view == null) {
            filter = '(TEST_DATE>='
                + SessionProperties.quaterStartDate
                + ')AND(TEST_DATE<='
                + SessionProperties.quaterEndDate
                + ')';
        } else if (view === 'Previous Qtr') {
            filter = '(TEST_DATE>='
                + SessionProperties.prvQtrStartDate
                + ')AND(TEST_DATE<='
                + SessionProperties.prvQtrEndDate
                + ')';
        } else if (view === 'Complete View') {
            filter = '';
        }
        return filter;
    }
    getAudiData(audiModalData: AudiReportModal): Observable<any> {
        this.setDataLoader(true);
        let filter = audiModalData.dateRangeBool ? '' : this.getFilter(audiModalData.view);
        filter += audiModalData.filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', audiModalData.order);
        params = params.set('offset', audiModalData.offset);
        params = params.set('filter', filter);
        audiModalData.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.AUDI_REPORT_VIEWNAME),
            { params: params, headers: AppUtil.getHttpHeaders() });
    }
    setAudiData(data: any) {
        this.audiData = data;
        this.audiData$.next(this.audiData);
    }
    onAudiData() {
        return this.audiData$.asObservable();
    }
    async getAudiExcelData(audiModalData: AudiReportModal) {
        let filter = this.getFilter(audiModalData.view);
        filter += audiModalData.filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = audiModalData.dateRangeBool ? params.set('filter', audiModalData.filter) :
            params.set('filter', filter);
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.AUDI_REPORT_VIEWNAME), params);
        this.setAudiExcelData(data.body.resource);
    }
    setAudiExcelData(data: any) {
        this.excelAudiData = data;
        this.excelAudiData$.next(this.excelAudiData);
    }
    onAudiExcelData() {
        return this.excelAudiData$.asObservable();
    }
    setSerialNumber(serialNumber, tableName) {
        this.serialNumber = serialNumber + ',' + tableName;
        this.serialNumber$.next(this.serialNumber);
    }
    onSerialNumber() {
        return this.serialNumber$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
