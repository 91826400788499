import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { AppConstant } from '../constants/app.constants';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class RegisterService {
    public RegisterData;
    public RegisterData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public emailId;
    public emailId$: Subject<any> = new Subject<any>();
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);
    constructor(public appService: AppService, public http: HttpClient) { }

    registerNewUser(registrationDetails) {
        return this.appService.postRegisterData(registrationDetails);
    }
    getRegistartionDetails(): Observable<any> {
        this.setDataLoader(true);
        return this.http.get<{ data: Object }>(AppUtil.getUrl(AppConstant.REGISTRATION_TABLENAME),
            { observe: 'response', headers: AppUtil.getHttpHeaders() });
    }
    setRegistartionDetails(data: any) {
        this.RegisterData = data;
        this.RegisterData$.next(this.RegisterData);
    }
    onRegistrationDetails() {
        return this.RegisterData$.asObservable();
    }
    sendEmailToAdmin(adminEmailId) {
        return this.http.post(AppUtil.getEmailUrl(), adminEmailId);
    }
    getEmailId(): Observable<any> {
        let param: HttpParams = AppUtil.getHttpParams();
        param = param.set('fields', 'OL_EMAIL');
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.REGISTRATION_TABLENAME), {
            params: param,
            headers: AppUtil.getHttpHeaders()
        });
    }
    setEmailId(data: any) {
        this.emailId = data;
        this.emailId$.next(this.emailId);
    }
    onEmailId() {
        return this.emailId$.asObservable();
    }
    updateStatus(userEmailId, imgUrl): Observable<any> {
        const updateAdminStatusBody = {
            resource: [
                {
                    OL_PIC: imgUrl
                }
            ]
        };
        let param: HttpParams = AppUtil.getHttpParams();
        param = param.set('filter', 'OL_EMAIL=' + userEmailId);
        return this.http.patch(AppUtil.getAppUrl(AppConstant.REGISTRATION_TABLENAME), updateAdminStatusBody,
            { params: param, headers: AppUtil.getHttpHeaders() });
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
