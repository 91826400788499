import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { FlashRmaReportModal } from '../modal/flashRmaReport.modal';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class FlashRmaReportService {
    public flashRmaData;
    public flashRmaData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public flashRmaExcelData;
    public flashRmaExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public maxDate: any;
    public maxDate$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);
    currentDate;
    constructor(public appService: AppService, public http: HttpClient) {
    }
    getFlashRmaData(flashRmaModalData: FlashRmaReportModal): Observable<any> {
        this.setDataLoader(true);
        const filterReceipt = '(TAG=\'RECEIPT\')';
        const filterDepot = '(TAG=\'DEPOT\')';
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('offset', flashRmaModalData.offset);
        params = params.set('order', flashRmaModalData.order);
        if (flashRmaModalData.selectedTabLabel === 'RMA Receipts'
            || flashRmaModalData.selectedTabLabel === '' ) {
            params = params.set('filter', filterReceipt + flashRmaModalData.filter);
        } else {
            params = params.set('filter', filterDepot + flashRmaModalData.filter);
        }
        flashRmaModalData.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.FLASHRMA_REPORT_TABLENAME),
        { params : params, headers: AppUtil.getHttpHeaders()});
    }
    setFlashRmaData(data: any) {
        this.flashRmaData = data;
        this.flashRmaData$.next(this.flashRmaData);
    }
    onFlashRmaData() {
        return this.flashRmaData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
    async getExcelFlashRmaData(flashRmaModalData: FlashRmaReportModal) {
        this.setDataLoader(true);
        const filter = flashRmaModalData.filter;
        let param: HttpParams = AppUtil.getHttpParams();
        if (flashRmaModalData.selectedTabLabel === 'Receipt'
            || flashRmaModalData.selectedTabLabel === '' ) {
            param = param.set('filter', 'TAG=\'RECEIPT\'');
        } else {
            param = param.set('filter', 'TAG=\'DEPOT\'');
        }
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.FLASHRMA_REPORT_TABLENAME), param);
        this.setExcelFlashRmaData(data.body.resource);
    }
    setExcelFlashRmaData(data: any) {
        this.flashRmaExcelData = data;
        this.flashRmaExcelData$.next(this.flashRmaExcelData);
    }
    onExcelFlashRmaData() {
        return this.flashRmaExcelData$.asObservable();
    }
    async getMaxDate() {
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('fields', 'CREATED_ON');
        params = params.set('order', 'CREATED_ON DESC');
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.FLASHRMA_REPORT_TABLENAME), params);
        this.setMaxDate(data.body.resource[0]);
    }
    setMaxDate(data: any) {
        this.maxDate = data;
        this.maxDate$.next(this.maxDate);
    }
    onMaxDate() {
        return this.maxDate$.asObservable();
    }
}
