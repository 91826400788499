import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { FlashInvExceptionsReportModal } from '../modal/flashInvExceptions.modal';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class FlashInvExceptionsReportService {
    public flashExceptionsData;
    public flashExceptionsData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public flashExceptionsExcelData;
    public flashExceptionsExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

    constructor(public appService: AppService, public http: HttpClient) { }

    getFlashExceptionsData(flashExceptionsModalData: FlashInvExceptionsReportModal): Observable<any> {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', flashExceptionsModalData.order);
        params = params.set('offset', flashExceptionsModalData.offset);
        params = params.set('filter', flashExceptionsModalData.filter);
        flashExceptionsModalData.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.FLASHINV_EXCEPTIONS_REPORT_TABLENAME),
        { params : params, headers: AppUtil.getHttpHeaders()});
    }
    setFlashExceptionsData(data: any) {
        this.flashExceptionsData = data;
        this.flashExceptionsData$.next(this.flashExceptionsData);
    }
    onFlashExceptionsData() {
        return this.flashExceptionsData$.asObservable();
    }
    async getFlashExceptionsDataExcel(filter) {
        let params: HttpParams = AppUtil.getHttpParams();
        if (AppUtil.isNullOrUndefined(this.flashExceptionsExcelData)) {
            params = params.set('filter', filter);
            const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.FLASHINV_EXCEPTIONS_REPORT_TABLENAME)
            , params);
            this.setFlashExceptionsDataExcel(data.body.resource);
        }
    }
    setFlashExceptionsDataExcel(data: any) {
        this.flashExceptionsExcelData = data;
        this.flashExceptionsExcelData$.next(this.flashExceptionsExcelData);
    }
    onFlashExceptionsDataExcel() {
        return this.flashExceptionsExcelData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
