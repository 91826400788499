import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from 'src/app/@core/constants/app.constants';
import { RmaInventoryReportModal } from '../modal/rmaInventoryReport.modal';
import { RmaNowReportModal } from '../modal/rmaNowReport.modal';
import { RmaSummaryReportModal } from '../modal/rmaSummaryReport.modal';
import { SessionProperties } from '../session/session.properties';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class RmaInventoryReportService {
    public rmaInventoryData;
    public rmaInventoryData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public rmaInventoryExcelData;
    public rmaInventoryExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);
    public maxDate: any;
    public maxDate$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    currentDate;
    constructor(public appService: AppService, public http: HttpClient) {
    }
    getRmaInventoryReportData(rmaInvReportModal: RmaInventoryReportModal): Observable<any> {
        this.setDataLoader(true);
        const filter = rmaInvReportModal.filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('offset', rmaInvReportModal.offset);
        params = params.set('filter', filter);
        params = params.set('order', rmaInvReportModal.order);
        rmaInvReportModal.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(rmaInvReportModal.tableName),
        { params : params, headers: AppUtil.getHttpHeaders()});
    }
    setRmaInventoryReportData(data: any) {
        this.rmaInventoryData = data;
        this.rmaInventoryData$.next(this.rmaInventoryData);
    }
    onRmaInventoryReportData() {
        return this.rmaInventoryData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
    async getMaxDate(rmaInvReportModal: RmaInventoryReportModal) {
        let params: HttpParams = AppUtil.getHttpParams();
        const currentMonth = SessionProperties.currentDate.split('-')[1];
        params = params.set('fields', 'CREATED_ON');
        params = params.set('order', 'CREATED_ON DESC');
        params = params.set('limit', '1');
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(rmaInvReportModal.tableName), params);
        this.setMaxDate(data.body.resource[0]);
    }
    setMaxDate(data: any) {
        this.maxDate = data;
        this.maxDate$.next(this.maxDate);
    }
    onMaxDate() {
        return this.maxDate$.asObservable();
    }
    async getExcelRmaInventoryReportData(rmaInvReportModal: RmaInventoryReportModal) {
        this.setDataLoader(true);
        const filter = rmaInvReportModal.filter;
        let param: HttpParams = AppUtil.getHttpParams();
        param = param.set('filter', filter);
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(rmaInvReportModal.tableName), param);
        this.setExcelRmaInventoryReportData(data.body.resource);
    }
    setExcelRmaInventoryReportData(data: any) {
        this.rmaInventoryExcelData = data;
        this.rmaInventoryExcelData$.next(this.rmaInventoryExcelData);
    }
    onExcelRmaInventoryReportData() {
        return this.rmaInventoryExcelData$.asObservable();
    }
}
