import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { AppUtil } from '../util/app.util';
import { AeronetReportModal } from './../modal/aeronet.modal';
import { AppService } from './app.service';

@Injectable()
export class AeronetReportService {
    public aeronetReportData;
    public aeronetReportData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public aeronetExcelData;
    public aeronetExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

    constructor(public appService: AppService, public http: HttpClient) { }

    getAeronetReportData(aeronetReportModalData: AeronetReportModal): Observable<any> {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', aeronetReportModalData.order);
        params = params.set('offset', aeronetReportModalData.offset);
        params = params.set('filter', aeronetReportModalData.filter);
        aeronetReportModalData.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.AERONET_REPORT_TABLENAME),
        { params : params, headers: AppUtil.getHttpHeaders()});
    }
    setAeronetReportData(data: any) {
        this.aeronetReportData = data;
        this.aeronetReportData$.next(this.aeronetReportData);
    }
    onAeronetReportData() {
        return this.aeronetReportData$.asObservable();
    }
    async getAeronetDataExcel(filter) {
        let params: HttpParams = AppUtil.getHttpParams();
        if (AppUtil.isNullOrUndefined(this.aeronetExcelData)) {
            params = params.set('filter', filter);
            const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.AERONET_REPORT_TABLENAME)
            , params);
            this.setAeronetDataExcel(data.body.resource);
        }
    }
    setAeronetDataExcel(data: any) {
        this.aeronetExcelData = data;
        this.aeronetExcelData$.next(this.aeronetExcelData);
    }
    onAeronetDataExcel() {
        return this.aeronetExcelData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
