import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { AppConstant } from '../constants/app.constants';
import { SSUIReportModal } from '../modal/ssuiReport.modal';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class SSUIReportService {
    public ssuiData;
    public ssuiData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public excelSSUIData;
    public excelSSUIData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: Subject<any> = new Subject<any>();
    constructor(public appService: AppService, public http: HttpClient) {
    }
    getSSUIData (ssuiReportModalData: SSUIReportModal): Observable<any> {
        this.setDataLoader(true);
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('order', ssuiReportModalData.order);
        params = params.set('offset', ssuiReportModalData.offset);
        params = params.set('filter', ssuiReportModalData.filter);
        ssuiReportModalData.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.SSUI_REPORT_TABLENAME),
        { params : params, headers: AppUtil.getHttpHeaders()});
    }
    setSSUIData(data: any) {
        this.ssuiData = data;
        this.ssuiData$.next(this.ssuiData);
    }
    onSSUIData() {
        return this.ssuiData$.asObservable();
    }
    async getSSUIExcelData(ssuiReportModalData: SSUIReportModal) {
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('filter', ssuiReportModalData.filter);
        const data: any = await this.appService.checkGetMethodWithParams(AppUtil
            .getAppUrl(AppConstant.SSUI_REPORT_TABLENAME), params);
        this.setSSUIExcelData(data.body.resource);
    }
    setSSUIExcelData(data: any) {
        this.excelSSUIData = data;
        this.excelSSUIData$.next(this.excelSSUIData);
    }
    onSSUIExcelData() {
        return this.excelSSUIData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
