import { Routes } from '@angular/router';
import { AddNewUserComponent } from './add-new-user/add-new-user.component';
import { AdminComponent } from './admin/admin.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

export const AppRoutes: Routes = [
    {
        path: 'dashboard',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: '',
        redirectTo: 'oktaLogin',
        pathMatch: 'full'
    },
    {
        path: '404notFound',
        component: NotFoundComponent
    },
    {
        path: 'admin',
        component: AdminComponent
    },
    {
        path: 'userProfile',
        component: UserProfileComponent
    },
    {
        path: 'addNewUser',
        component: AddNewUserComponent
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './Dashboards/dashboard.module#DashboardModule'
            },
            {
                path: 'sales',
                loadChildren: './Sales/sales.module#SalesModule'
            },
            {
                path: 'order_management',
                loadChildren: './Reports/Reports.module#ReportsModule'
            },
            {
                path: 'RMA',
                loadChildren: './RMA/RMA.module#RMAModule'
            },
            {
                path: 'shipping',
                loadChildren: './Shipping/shipping.module#ShippingModule'
            },
            {
                path: 'inventory_management',
                loadChildren: './Inventory_Management/inventoryManagement.module#InventoryManagementModule'
            },
            {
                path: 'install_base',
                loadChildren: './Install_Base/InstallBase.module#InstallBaseModule'
            },
            {
                path: 'testblox',
                loadChildren: './Testblox/testblox.module#TestbloxModule'
            },
            {
                path: 'components',
                loadChildren: './components/components.module#ComponentsModule'
            },
            {
                path: 'forms',
                loadChildren: './forms/forms.module#Forms'
            },
            {
                path: 'tables',
                loadChildren: './tables/tables.module#TablesModule'
            },
            {
                path: 'maps',
                loadChildren: './maps/maps.module#MapsModule'
            },
            {
                path: 'widgets',
                loadChildren: './widgets/widgets.module#WidgetsModule'
            },
            {
                path: 'charts',
                loadChildren: './charts/charts.module#ChartsModule'
            },
            {
                path: 'calendar',
                loadChildren: './calendar/calendar.module#CalendarModule'
            },
            {
                path: '',
                loadChildren: './userpage/user.module#UserModule'
            },
            {
                path: '',
                loadChildren: './timeline/timeline.module#TimelineModule'
            }
        ]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: 'pages',
                loadChildren: './pages/pages.module#PagesModule'
            }
        ]
    }
];
