
import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TourService } from 'ngx-tour-md-menu';
import { Subscription } from 'rxjs/Subscription';
import { NavbarService } from 'src/app/@core/data/navbar.service';
import { OrderManagementService } from 'src/app/@core/data/orderManagement.service';
import { SalesService } from 'src/app/@core/data/sales.service';
import { NavbarModal } from 'src/app/@core/modal/navBar.modal';
import { ROUTES } from '../.././sidebar/sidebar.component';
import { LegacyService } from '../../@core/data/legacyReport.service';
import { OpportunitiesReportService } from '../../@core/data/opportunitiesReport.service';
import { ShipReportService } from '../../@core/data/shipReport.service';
import { AudiReportModal } from '../../@core/modal/audiReport.modal';
import { BmwReportModal } from '../../@core/modal/bmwReport.modal';
import { MercedesReportModal } from '../../@core/modal/mercedesReport.modal';
import { OrderBacklogReportModal } from '../../@core/modal/orderBacklog.modal';
// import { TestBloxReportModal } from '../../@core/modal/testBlox.modal';
import { ShipReportModal } from '../../@core/modal/shipReport.modal';
import { TeslaReportModal } from '../../@core/modal/teslaReport.modal';

const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0
};

declare var $: any;
@Component({
    selector: 'app-navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    shipModalData: ShipReportModal = new ShipReportModal();
    audiModalData: AudiReportModal = new AudiReportModal();
    bmwModalData: BmwReportModal = new BmwReportModal();
    mercedesModalData: MercedesReportModal = new MercedesReportModal();
    teslaModalData: TeslaReportModal = new TeslaReportModal();
    // testBloxModalData: TestBloxReportModal = new TestBloxReportModal();
    orderBacklogModalData: OrderBacklogReportModal = new OrderBacklogReportModal();
    navBarModalData: NavbarModal = new NavbarModal();
    @Input() isShow = false;
    @Output() updateValue = new EventEmitter();
    public listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    public nativeElement: Node;
    public toggleButton: any;
    public sidebarVisible: boolean;
    public _router: Subscription;
    public current_url: string;
    dropdownSettings = {};
    dropdownList = [];
    selectedItems = [];
    orderTypeArray = [];
    orderType = ['Dist', 'Fed', 'Reg', 'Int'];
    testTypeArray = [];
    testType = ['LICCONF', 'SWDL', 'CTO'];
    @ViewChild('app-navbar-cmp') button: any;
    viewExecDashList = ['Qtr To Date', 'Previous Qtr'];
    viewList = ['Qtr To Date', 'Previous Qtr', 'Complete View'];
    oppsViewList = ['Qtr To Date', 'Next Qtr', 'Complete View'];
    viewOrderList = ['All', ' Distributor', 'Federal', 'Regular', 'Internal'];
    viewTestList = ['All', ' LICCONF', 'SWDL', 'CTO'];
    yearList = ['FY19', 'FY18', 'FY17', 'FY16', 'FY15', 'FY14', 'FY13', 'FY12', 'Complete View'];
    viewMainDash = 'View';
    viewOrder = 'OrderType';
    viewTest = 'TestType';
    yearView = 'Year View';
    innerWidth: number;
    constructor(
        location: Location,
        public element: ElementRef,
        public router: Router,
        public shipReportService: ShipReportService,
        public navbarService: NavbarService,
        public legacyService: LegacyService,
        public opportunityService: OpportunitiesReportService,
        public orderManagementService: OrderManagementService,
        public salesService: SalesService,
        public tourService: TourService
    ) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
        this.changeOrderType('All');
        // this.changeViewExecDash('Qtr To Date');
        this.changeViewQuarter('Qtr To Date');
        this.changeViewYear('FY19');
        this.getNotificationData();
    }
    onItemSelect(item: any) {
        this.selectedItems.push(item);
    }
    onSelectAll(items: any) {
        console.log(items);
    }
    minimizeSidebar() {
        const body = document.getElementsByTagName('body')[0];

        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;
        } else {
            setTimeout(function() {
                body.classList.add('sidebar-mini');

                misc.sidebar_mini_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function() {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function() {
            clearInterval(simulateWindowResize);
        }, 1000);
    }
    hideSidebar() {
        const body = document.getElementsByTagName('body')[0];
        const sidebar = document.getElementsByClassName('sidebar')[0];

        if (misc.hide_sidebar_active === true) {
            setTimeout(function() {
                body.classList.remove('hide-sidebar');
                misc.hide_sidebar_active = false;
            }, 300);
            setTimeout(function() {
                sidebar.classList.remove('animation');
            }, 600);
            sidebar.classList.add('animation');
        } else {
            setTimeout(function() {
                body.classList.add('hide-sidebar');
                // $('.sidebar').addClass('animation');
                misc.hide_sidebar_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function() {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function() {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    ngOnInit() {
        this.listTitles = ROUTES.filter(listTitle => listTitle);

        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        if (body.classList.contains('hide-sidebar')) {
            misc.hide_sidebar_active = true;
        }
        this._router = this.router.events
            .filter(event => event instanceof NavigationEnd)
            .subscribe((event: NavigationEnd) => {
                this.sidebarClose();

                const $layer = document.getElementsByClassName('close-layer')[0];
                if ($layer) {
                    $layer.remove();
                }
            });
    }
    onResize(event) {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }
    sidebarOpen() {
        const $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function() {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');
        setTimeout(function() {
            $toggle.classList.add('toggled');
        }, 430);

        const $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');

        if (body.querySelectorAll('.main-panel')) {
            document.getElementsByClassName('main-panel')[0].appendChild($layer);
        } else if (body.classList.contains('off-canvas-sidebar')) {
            document
                .getElementsByClassName('wrapper-full-page')[0]
                .appendChild($layer);
        }

        setTimeout(function() {
            $layer.classList.add('visible');
        }, 100);

        $layer.onclick = function() {
            // asign a function
            body.classList.remove('nav-open');
            this.mobile_menu_visible = 0;
            this.sidebarVisible = false;

            $layer.classList.remove('visible');
            setTimeout(function() {
                $layer.remove();
                $toggle.classList.remove('toggled');
            }, 400);
        }.bind(this);

        body.classList.add('nav-open');
        this.mobile_menu_visible = 1;
        this.sidebarVisible = true;
    }
    sidebarClose() {
        const $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        const $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');

        this.sidebarVisible = false;
        body.classList.remove('nav-open');
        // $('html').removeClass('nav-open');
        body.classList.remove('nav-open');
        if ($layer) {
            $layer.remove();
        }

        setTimeout(function() {
            $toggle.classList.remove('toggled');
        }, 400);

        this.mobile_menu_visible = 0;
    }
    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }
    getNotificationData() {
        this.navbarService.getNotificationData(this.navBarModalData);
    }
    getTitle() {
        let titlee: string = this.location.prepareExternalUrl(this.location.path());
        titlee = titlee.includes('#') ? titlee.split('#')[1] : titlee;
        if (titlee === '/sales') {
            return 'Sales Dashboard';
        } else if (titlee === '/order_management') {
            return 'Order Management Dashboard';
        } else if (titlee === '/RMA') {
            return 'RMA Dashboard';
        } else if (titlee === '/shipping') {
            return 'Shipping Dashboard';
        } else if (titlee === '/testblox') {
            return 'Testblox Dashboard';
        } else if (titlee === '/imdb') {
            return 'TB5';
        } else if (titlee === '/inventory_management') {
            return 'Inventory Management Dashboard';
        } else if (titlee === '/install_base') {
            return 'Install Base Dashboard';
        } else if (titlee.includes('/testblox/serialNumberDetails')) {
            return 'Serial Number Details';
        } else if (titlee.includes('/completeShipReport')) {
            return 'Complete Ship Report';
        } else if (titlee.includes('/testblox/showLogFileData')) {
            return 'Log File Details';
        }
        if (this.listTitles != null) {
            for (let i = 0; i < this.listTitles.length; i++) {
                if (
                    this.listTitles[i].type === 'link' &&
                    this.listTitles[i].path === titlee
                ) {
                    return this.listTitles[i].title;
                } else if (this.listTitles[i].type === 'sub') {
                    for (let j = 0; j < this.listTitles[i].children.length; j++) {
                        const subtitle =
                            this.listTitles[i].path +
                            '/' +
                            this.listTitles[i].children[j].path;
                        if (subtitle === titlee) {
                            return this.listTitles[i].children[j].title;
                        }
                    }
                }
            }
        }
        return 'Dashboard';
    }
    getPath() {
        return this.location.prepareExternalUrl(this.location.path());
    }
    // to display dropdown in reports page and search in dashboard
    getExceDashView() {
        this.current_url = window.location.href;
        return this.current_url.includes('mainDashboard');
    }
    getQtrByFilterArray() {
        this.current_url = window.location.href;
        let orderManagementBool: boolean;
        this.orderManagementService.onTabLabel().subscribe(data =>
            orderManagementBool = data);
        if (this.current_url.includes('mainDashboard')) {
            return this.viewExecDashList;
        }
        if (this.current_url.includes('order_management') &&
            this.current_url.includes('opportunitiesReport')) {
            return this.oppsViewList;
        }
        if (this.current_url.includes('order_management') &&
            !this.current_url.includes('opportunitiesReport') &&
            !orderManagementBool) {
            return this.oppsViewList;
        }
        if (this.current_url.includes('order_management') ||
            !this.current_url.includes('opportunitiesReport') ||
            this.current_url.includes('testblox')) {
            return this.viewList;
        }
    }
    getQuarterView() {
        this.current_url = window.location.href;
        return (
            ((this.current_url.includes('sales') &&
                !this.current_url.includes('legacyReport') &&
                !this.current_url.includes('completeShipReport')) ||
                this.current_url.includes('sales/shipReport') ||
                (this.current_url.includes('order_management') &&
                    !this.current_url.includes('arrowStatusReport') &&
                    !this.current_url.includes('asnErrorReport') &&
                    !this.current_url.includes('arrowEngagementReport') &&
                    !this.current_url.includes('orderBacklogArchiveReport') &&
                    !this.current_url.includes('exceptionReport') &&
                    !this.current_url.includes('skuPartMapReport') &&
                    !this.current_url.includes('ibRegionsReport')) ||
                (this.current_url.includes('testblox') &&
                    !this.current_url.includes('testblox/testbloxExceptionReport') &&
                    !this.current_url.includes('testblox/showLogFileData') &&
                    !this.current_url.includes('testblox/pstReport') &&
                    !this.current_url.includes('testblox/tb3LogReport') &&
                    !this.current_url.includes('serialNumberDetails'))) &&
            !this.current_url.includes('inventory_management') ||
            this.current_url.includes('mainDashboard'));
    }
    getOrderTypeView() {
        this.current_url = window.location.href;
        if (this.current_url.includes('sales/shipReport') || this.current_url.includes('order_management/orderBacklogReport')
            || this.current_url.includes('completeShipReport')) {
            return true;
        }
        return false;
    }
    getYearView() {
        this.current_url = window.location.href;
        if (this.current_url.includes('legacyReport')) {
            return true;
        }
        return false;
    }
    changeOrderType(type) {
        this.current_url = window.location.href;
        this.viewOrder = type;
        this.shipReportService.setDataLoader(true);
        this.navbarService.setOrderType(type);
    }
    // changeViewExecDash(view) {
    //     this.viewMainDash = view;
    //     this.current_url = window.location.href;
    //     this.shipReportService.setDataLoader(true);
    //     this.navbarService.setView(view);
    // }
    changeViewQuarter(view) {
        this.current_url = window.location.href;
        this.shipReportService.setDataLoader(true);
        this.navbarService.setView(view);
        this.changeOrderType('All');
    }
    changeViewYear(year) {
        this.yearView = year;
        this.current_url = window.location.href;
        this.legacyService.setDataLoader(true);
        this.navbarService.setyear(year);
    }
    notificationCheck() {
        this.navbarService.updateNotification(1000).toPromise();
    }
}
