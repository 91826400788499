import { Header } from './header.modal';

export class UserProfileModal {
    readonly userProfileHeader: Header[] = [
        {
            columnName: 'OL_FIRSTNAME',
            displayName: 'FIRST NAME',
            columnType: '90px',
            notShow: false
        },
        {
            columnName: 'OL_LASTNAME',
            displayName: 'LAST NAME',
            columnType: '90px',
            notShow: false
        },
        {
            columnName: 'OL_EMAIL',
            displayName: 'EMAIL ID',
            columnType: '',
            notShow: false
        },
        {
            columnName: 'OL_GROUPNAME',
            displayName: 'GROUP',
            columnType: '90px',
            notShow: false
        },
        {
            columnName: 'OL_ACTION_FLAG',
            displayName: 'STATUS',
            columnType: '',
            notShow: false
        },
        {
            columnName: '',
            displayName: '',
            columnType: '155px',
            notShow: true
        },
        {
            columnName: '',
            displayName: '',
            columnType: 'string',
            notShow: true
        },
        {
            columnName: '',
            displayName: '',
            columnType: '100px',
            notShow: true
        }
    ];
    public userDetails: any;
    public roleData: any;
    public name: any;
    public view: any = 'Admin/View';
    public custom_dropdown: boolean;
    public emailData = {
        to: [
            {
                name: '',
                email: ''
            }
        ],
        subject: '',
        body_text: ''
    };
    public dropdownList = [];
    public viewList = [];
    public selectedItems = [];
    public dropdownSettings = {};
    constructor() { }
}
