import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { AppUtil } from '../util/app.util';
import { FlashOrderHistoryModal } from './../modal/flashOrderHistory.modal';
import { AppService } from './app.service';

@Injectable()
export class FlashOrderHistoryService {
    public flashOrderHistoryData: any;
    public flashOrderHistoryData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public flashOrderHistoryExcelData: any;
    public flashOrderHistoryExcelData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public maxDate: any;
    public maxDate$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);
    currentDate;
    constructor(public appService: AppService, public http: HttpClient) {
    }
    getFlashOrderHistoryData(flashOrderHistoryModal: FlashOrderHistoryModal): Observable<any> {
        this.setDataLoader(true);
        let filter = '';
        filter += AppUtil.isNullOrUndefined(flashOrderHistoryModal.filter) ? '' : flashOrderHistoryModal.filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('limit', '300');
        params = params.set('offset', flashOrderHistoryModal.offset);
        params = params.set('filter', filter);
        params = params.set('order', flashOrderHistoryModal.order);
        flashOrderHistoryModal.offset += 300;
        this.setDataLoader(false);
        return this.http.get<{ data: Object }>(AppUtil.getAppUrl(AppConstant.FLASH_ORDER_HISTORY_VIEWNAME),
        { params : params, headers: AppUtil.getHttpHeaders()});
    }
    setFlashOrderHistoryData(data: any) {
        this.flashOrderHistoryData = data;
        this.flashOrderHistoryData$.next(this.flashOrderHistoryData);
    }
    onFlashOrderHistoryData() {
        return this.flashOrderHistoryData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
    async getExcelFlashOrderHistoryData(flashOrderHistoryModal: FlashOrderHistoryModal) {
        this.setDataLoader(true);
        const filter = flashOrderHistoryModal.filter;
        let param: HttpParams = AppUtil.getHttpParams();
        param = param.set('filter', filter);
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl
            (AppConstant.FLASH_ORDER_HISTORY_VIEWNAME), param);
        this.setExcelFlashOrderHistoryData(data.body.resource);
    }
    setExcelFlashOrderHistoryData(data: any) {
        this.flashOrderHistoryExcelData = data;
        this.flashOrderHistoryExcelData$.next(this.flashOrderHistoryExcelData);
    }
    onExcelFlashOrderHistoryData() {
        return this.flashOrderHistoryExcelData$.asObservable();
    }
}
