import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { BacklogArchiveModal } from '../modal/backlogArchive.modal';
import { SessionProperties } from '../session/session.properties';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class BacklogArchiveService {
    constructor(public appService: AppService, public http: HttpClient) { }
    public backlogArchiveData;
    public backlogArchiveData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public dataLoader: boolean;
    public dataLoader$: BehaviorSubject<any> = new BehaviorSubject<any>(true);
    getFilter (view, type) {
        let filter;
        if (view === 'Qtr To Date' || view == null) {
            filter = '(((ORDER_DATE>='
                    + SessionProperties.quaterStartDate
                    + ')AND(ORDER_DATE<='
                    + SessionProperties.quaterEndDate
                    + '))OR(ORDER_DATE IS NULL))AND(ORDER_CATEGORY=\'DOF\')AND(ORDER_TYPE LIKE \'%' + type.trim() + '%\')';
        } else if (view === 'Month To Date') {
            filter =  '((ORDER_DATE>='
                    + SessionProperties.monthStartDate
                    + ')AND(ORDER_DATE<='
                    + SessionProperties.monthEndDate
                    + '))AND(ORDER_CATEGORY=\'DOF\')AND(ORDER_TYPE LIKE \'%' + type.trim() + '%\')';
        } else if (view === 'Previous Qtr') {
            filter = '((ORDER_DATE>='
                    + SessionProperties.prvQtrStartDate
                    + ')AND(ORDER_DATE<='
                    + SessionProperties.prvQtrEndDate
                    + '))AND(ORDER_CATEGORY=\'DOF\')AND(ORDER_TYPE LIKE \'%' + type.trim() + '%\')';
        } else if (view === 'Complete View') {
            filter = '(ORDER_CATEGORY=\'DOF\')AND(ORDER_TYPE LIKE \'%' + type.trim() + '%\')';
        }
        return filter;
    }
    async getBacklogArchiveData(backlogArchiveModal: BacklogArchiveModal) {
        this.setDataLoader(true);
        // let filter = this.getFilter(backlogArchiveModal.view, backlogArchiveModal.orderType);
        const filter = backlogArchiveModal.filter;
        let params: HttpParams = AppUtil.getHttpParams();
        params = params.set('filter', filter);
        params = params.set('group', 'ORDER_NO');
        params = params.set('order', backlogArchiveModal.order);
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.BACKLOG_ARCHIVE_VIEWNAME),
        params);
        this.setBacklogArchiveData(data.body.resource);
        this.setDataLoader(false);
    }
    setBacklogArchiveData(data: any) {
        this.backlogArchiveData = data;
        this.backlogArchiveData$.next(this.backlogArchiveData);
    }
    onBacklogArchiveData() {
        return this.backlogArchiveData$.asObservable();
    }
    setDataLoader(data: boolean) {
        this.dataLoader = data;
        this.dataLoader$.next(this.dataLoader);
    }
    onDataLoader() {
        return this.dataLoader$.asObservable();
    }
}
