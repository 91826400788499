import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConstant } from '../constants/app.constants';
import { DashboardModal } from '../modal/dashboard.modal';
import { AppUtil } from '../util/app.util';
import { AppService } from './app.service';

@Injectable()
export class DashboardService {
  public DashboardDataCount;
  public DashboardDataCount$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public installModalData;
  public installModalData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public dashboardBacklog;
  public dashboardBacklog$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public dashboardSales;
  public dashboardSales$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public dashboardInstall;
  public dashboardInstall$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public dataLoader: boolean;
  public dataLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(public appService: AppService) {}
  async getDashboardDataCount() {
    if (AppUtil.isNullOrUndefined(this.DashboardDataCount)) {
      const param: HttpParams = AppUtil.getHttpParams();
      const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.DASHBOARD_COUNTRY), param);
      this.setDashboardDataCount(data.body.resource);
    }
  }
  setDashboardDataCount(data: any) {
    this.DashboardDataCount = data;
    this.DashboardDataCount$.next(this.DashboardDataCount);
  }
  onDashboardDataCount() {
    return this.DashboardDataCount$.asObservable();
  }
  async getInstallModalData(dashboardModalData: DashboardModal) {
    let param: HttpParams = AppUtil.getHttpParams();
        let filter: string;
        if (AppUtil.isNullOrUndefined(dashboardModalData.partNo) || dashboardModalData.partNo === '') {
            filter = '(INSTALLED_CC=' + dashboardModalData.code + ')';
        } else if (/^([0-9]+)$/.test(dashboardModalData.partNo) || dashboardModalData.partNo === 'netMRI') {
            filter = '(INSTALLED_CC=' + dashboardModalData.code + ')and(FAMILY LIKE %' + dashboardModalData.partNo + '%)';
        } else {
            filter = '(INSTALLED_CC=' + dashboardModalData.code + ')and(PART_NO LIKE %' + dashboardModalData.partNo + '%)';
        }
        param = param.set('offset', String(dashboardModalData.offset));
        param = param.set('order', String(dashboardModalData.orderModal));
        param = param.set('filter', filter);
        param = param.set('fields', 'COUNTRY_CODE, PART_NO, SERIAL_NO, INSTALL_DATE, SALES_REP, ACCOUNT_NAME');
        const data = await this.appService.checkGetMethodWithParams(AppUtil.getAppUrl(AppConstant.INSTALLBASE_TABLENAME), param);
        dashboardModalData.offset += 300;
        this.setInstallModalData(data.body.resource);
  }
  setInstallModalData(data: any) {
    this.installModalData = data;
    this.installModalData$.next(this.installModalData);
  }
  onInstallModalData() {
    return this.installModalData$.asObservable();
  }
  async getDashboardSales() {
    if (AppUtil.isNullOrUndefined(this.dashboardSales)) {
      this.setDataLoader(true);
      const data = await this.appService.checkGetMethod(AppUtil.getUrl(AppConstant.DASHBOARDSALES_VIEWNAME));
      this.setDashboardSales(data.body.resource);
      this.setDataLoader(false);
    }
  }
  setDashboardSales(data: any) {
    this.dashboardSales = data;
    this.dashboardSales$.next(this.dashboardSales);
  }
  onDashboardSales() {
    return this.dashboardSales$.asObservable();
  }
  setDataLoader(data: boolean) {
    this.dataLoader = data;
    this.dataLoader$.next(this.dataLoader);
  }
  onDataLoader() {
    return this.dataLoader$.asObservable();
  }
}
